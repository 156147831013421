import React, {Component} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {PersistGate} from 'redux-persist/integration/react'
import 'stream-chat-react/dist/css/index.css';
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

// Utils
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

// Redux
import {Provider} from "react-redux";
import {persistor, store} from "./store";

import {StreamChat} from 'stream-chat';

import PrivateRoute from "./components/private-route/PrivateRoute";
import {Chat} from "stream-chat-react";
import {logoutUser} from "./actions/authActions";
import {GETSTREAM_KEY} from "./constants";
// import Layout from "./components/dashboard/Layout";
// import NotFound from "./components/404/404";

// Check for token to keep user logged in
if (localStorage.jwtTokenTeams) {
  // Set auth token header auth
  const token = JSON.parse(localStorage.jwtTokenTeams);
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  /*
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));*/

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./";
  }
}
const client = StreamChat.getInstance(GETSTREAM_KEY);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Chat client={client}>
              <div className="App">
                <Switch>
                  {/* <Route exact path="/" component={Login} />
              <Route exact path="/register" component={Register} />
              <PrivateRoute exact path="/dashboard" component={Layout} /> */}

                  <Route path="/auth" render={props => <AuthLayout {...props} />}/>
                  <PrivateRoute path="/admin" component={props => <AdminLayout {...props} />}/>
                  <Redirect from="/" to="/auth/login"/>
                  <Redirect from="/auth" to="/auth/login"/>
                  <Redirect from="/admin" to="/admin/index"/>
                  {/* <Route component={localStorage.jwtTokenTeams ? AdminLayout : NotFound} /> */}

                  {/* <Route path="/admin" render={props => <AdminLayout {...props} />} />
              <Route path="/auth" render={props => <AuthLayout {...props} />} />
              <Redirect from="/" to="/auth/login" /> */}

                </Switch>
              </div>
            </Chat>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
