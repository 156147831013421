import React from "react";
import {
  // Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import {Table, Divider, Tag, Modal, Button, Layout, Menu, Popconfirm, Input, Icon} from 'antd';
import {confirmAlert} from 'react-confirm-alert';
import Highlighter from 'react-highlight-words';
import AddUserItem from "./AddUserItem.js"
import AssignCoach from "./AssignCoach.js"
import AddDuration from "./AddDuration.js"
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);

const { Search } = Input;

class allUsers extends React.Component {

  constructor() {
    super();
    this.userTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: text => <a>{text}</a>,
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Duration',
        key: 'planLength',
        render: (record) =>
          <a>{record.profile && record.profile.planLength ? record.profile.planLength + " weeks" : "0 Weeks"}</a>,
      },
      {
        title: 'WeeksRemaining',
        key: 'weeksRemaining',
        sorter: (a, b) => {
          if (typeof b.weeksRemaining !== 'undefined') {
            if (a.weeksRemaining > b.weeksRemaining) return 1;
            if (b.weeksRemaining > a.weeksRemaining) return -1;
            return 0;
          } else {
            return -1;
          }
          // a.weeksRemaining - b.weeksRemaining
        },
        render: (record) =>
          <a>{typeof record.weeksRemaining === 'undefined' ? "Nil" : record.weeksRemaining + " week(s)"}</a>,
      },
      {
        title: 'Starting Date',
        key: 'startDate',
        render: (record) =>
          <a>{record.profile && record.profile.startDate ? moment.utc(record.profile.startDate).format("MMMM DD, YYYY") : "Nil"}</a>,
      },
      {
        title: 'Coach',
        key: 'coach',
        filters: [
          {
            text: 'Not Assigned',
            value: "Not Assigned",
          }
        ],
        onFilter: (value, record) => !record.profile || !record.profile.coach,
        render: (record) => <a>{record.profile && record.profile.coach ? record.profile.coach : "Not Assigned"}</a>,
      },
      {
        title: 'Active Status',
        key: 'isActive',
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'Inactive',
            value: false,
          }
        ],
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.isActive == value,
        sorter: (a, b) => a.isActive,
        // sortDirections: ['descend'],
        render: (record) =>
          <span>
            {record.isActive ?
              <Tag color={'green'}>
                Active</Tag>
              :
              <Tag color={'volcano'}>
                {record.invited ? 'Invited' : 'Inactive'}</Tag>
            }
          </span>
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <div>
            {!record.invited && <>
              <Popconfirm title="Are you sure to change user status" onConfirm={() => this.changeItemStatus(record)}>
              <span>
                <div style={{marginBottom: 5}}>
                  <Button type="primary" size="small">
                    Change Status</Button>
                </div>
              </span>
              </Popconfirm>
              <div style={{marginBottom: 5}}>
                <Button type="primary" size="small" onClick={() => this.showAddCoachModal(record)}>
                  Assign Coach</Button>
              </div>
              <div style={{marginBottom: 5}}>
                <Button type="primary" size="small" onClick={() => this.showDurationModal(record, true)}>
                  Add Plan</Button>
              </div>
              <div>
                <Button type="primary" size="small" onClick={() => this.showDurationModal(record, false)}>
                  Add Duration</Button>
              </div>
            </>}
          </div>
        ),
      },
    ];
    this.trainerTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: text => <a>{text}</a>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Access',
        key: 'role',
        dataIndex: 'role',
        render: role => {
          let color = 'gold';
          if (role === 'user') {
            color = 'geekblue';
          } else if (role === 'admin') {
            color = 'green';
          }
          return (
            <span>
              <Tag color={color} key={role}>
                {role.toUpperCase()}
              </Tag>
            </span>
          )
        },
      },
      {
        title: 'Users Assigned',
        key: 'assignedUsers',
        render: record => <span>{record.assignedUser ? record.assignedUser : 0}</span>,
      },
      {
        title: 'Active Status',
        key: 'isActive',
        render: (record) =>
          <span>
            {record.isActive ?
              <Tag color={'green'}>
                Active</Tag>
              :
              <Tag color={'volcano'}>
                Inactive</Tag>
            }
          </span>
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Popconfirm title="Are you sure to change user status" onConfirm={() => this.changeItemStatus(record)}>
            <span>
              <Button type="primary" size="small">
                Change Status</Button>
            </span>
          </Popconfirm>
        ),
      },
    ];
    this.adminTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: text => <a>{text}</a>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Access',
        key: 'role',
        dataIndex: 'role',
        render: role => {
          let color = 'gold';
          if (role === 'user') {
            color = 'geekblue';
          } else if (role === 'admin') {
            color = 'green';
          }
          return (
            <span>
              <Tag color={color} key={role}>
                {role.toUpperCase()}
              </Tag>
            </span>
          )
        },
      },
      {
        title: 'Active Status',
        key: 'isActive',
        render: (record) =>
          <span>
            {record.isActive ?
              <Tag color={'green'}>
                Active</Tag>
              :
              <Tag color={'volcano'}>
                Inactive</Tag>
            }
          </span>
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Popconfirm title="Are you sure to change user status" onConfirm={() => this.changeItemStatus(record)}>
            <span>
              <Button type="primary" size="small">
                Change Status</Button>
            </span>
          </Popconfirm>
        ),
      },
    ];

    this.state = {
      defaultMessages:[],
      allUsers: [],
      currentUsers: [],
      loadingUsers: false,
      allTrainers: [],
      allAdmins: [],
      toggleAddModal: false,
      toggleAddCoachModal: false,
      addUserRole: 'user',
      selectedUser: {},
      toggleAddDurationModal: false,
      searchText: '',
      searchedColumn: '',
      canChange: false,
      total: 0,
      limit: 10,
      offset: 0,
    };
    this.timeout = null;
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({searchText: ''});
  };
  componentDidMount() {
    this.getDefaultMessages()
  }

  componentWillMount() {
    this.getAllUsers(this.state.limit, this.state.offset);
    this.getAllTrainers(this.state.limit, this.state.offset);
  }
  
  onChange = (pagination, filters, sorter, extra) => {
    this.setState({
      offset: pagination.current * pagination.pageSize
    })
    this.getAllUsers(pagination.pageSize,(pagination.current-1) * pagination.pageSize )
  };

  async getAllUsers(limit, offset, name='',email='') {
    this.setState({
      loadingUsers: true,
      toggleAddModal: false,
      toggleAddCoachModal: false,
      toggleAddDurationModal: false
    })
    await axios.get(`${BASE_URL}/api/admin-users?limit=${limit}&offset=${offset}&name=${name}&email=${email}`)
      .then(res => {
        let allUsers = [];
        let allAdmins = [];
        if (res.data) {
          if (res.data.users) {
            let dataArray = res.data.users;
            if (dataArray && dataArray.length) {
              dataArray.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
            }
            dataArray.forEach(element => {
              if (element.role === 'user') {
                let proIndex = res.data.userProfiles.findIndex(e => e.userId === element._id);
                if (proIndex !== -1) {
                  element.profile = res.data.userProfiles[proIndex];
                }
                allUsers.push(element);
              }
            });
           
          }
        }
        this.setState({
          loadingUsers: false,
          allUsers,
          allAdmins,
          total: res.data.total,
          offset: this.state.offset
        });
        this.findCurrentWeek();
      })
      .catch(err => {
        this.setState({loadingUsers: false,});
      });
  };
  
  async getAllTrainers(limit=10, offset=0, name='',email='') {
    this.setState({
      toggleAddModal: false,
      toggleAddCoachModal: false,
      toggleAddDurationModal: false
    })
    await axios.get(`${BASE_URL}/api/admin-users/trainers?limit=${limit}&offset=${offset}&name=${name}&email=${email}`)
      .then(res => {
        let allTrainers = [];
        if (res.data) {
          if (res.data.users) {
            let dataArray = res.data.users;
            if (dataArray && dataArray.length) {
              dataArray.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
            }
            dataArray.forEach(element => {
              if (element.role === 'trainer') {
                allTrainers.push(element);
              }
            });
            // this.setState({
            //   loadingUsers: false,
            //   allUsers, allTrainers, allAdmins
            // });
          }
        }
        this.setState({
          loadingUsers: false,
          allTrainers,
        });
        //this.findCurrentWeek();
      })
      .catch(err => {
        this.setState({loadingUsers: false,});
      });
  };

  getDefaultMessages = ()=>{
    axios.get(`${BASE_URL}/api/custom-messages/`)
      .then(async res => {
        this.setState({defaultMessages: res.data})
      })
      .catch(err => {
      })
  }

  findCurrentWeek() {
    let {allUsers} = this.state;
    let currentDate = moment.utc(new Date()).format('YYYY-MM-DD');

    allUsers.forEach(selectedUser => {

      if (selectedUser.profile && selectedUser.profile.planLength && selectedUser.profile.startDate) {

        for (let i = 1; i <= selectedUser.profile.planLength; i++) {
          let weekStart = moment.utc(selectedUser.profile.startDate).add((i - 1) * 7, 'days');
          let weekEnd = moment.utc(selectedUser.profile.startDate).add((i * 7) - 1, 'days');

          if (currentDate >= moment.utc(weekStart).format("YYYY-MM-DD") && currentDate <= moment.utc(weekEnd).format("YYYY-MM-DD")) {
            selectedUser.weeksRemaining = (selectedUser.profile.planLength - i);
            break;
          }
        }

      }
    });
    const currentUsers = [...allUsers]
    this.setState({allUsers, currentUsers})
  }

  searchUsersByName = (value) => {
    if(this.timeout){
      clearTimeout(this.timeout)
    }
    const _value = value
   this.timeout = setTimeout(()=>{
     this.getAllUsers(this.state.limit, 0, _value)
   }, 1000);
    /*const currentUsers = []
    const {allUsers} = this.state;
    allUsers.forEach(selectedUser => {
      if(selectedUser.name.toLowerCase().includes(value.toLowerCase())){
        currentUsers.push(selectedUser)
      }
    })
    this.setState({currentUsers})*/
  }
  
  searchUsersByEmail = (value) => {
    if(this.timeout){
      clearTimeout(this.timeout)
    }
    const _value = value
    this.timeout = setTimeout(()=>{
      this.getAllUsers(this.state.limit, 0, '',_value)
    }, 1000);
  }

  showAddModal(role) {
    this.setState({toggleAddModal: true, addUserRole: role});
  };

  hideAddModal = () => {
    this.setState({toggleAddModal: false});
  };

  showAddCoachModal(userData) {
    this.setState({toggleAddCoachModal: true, selectedUser: userData});
  };

  hideAddCoachModal = () => {
    this.setState({toggleAddCoachModal: false});
  };

  showDurationModal(userData, canChange) {
    this.setState({toggleAddDurationModal: true, selectedUser: userData, canChange});
  };

  hideDurationModal = () => {
    this.setState({toggleAddDurationModal: false});
  };

  updateUserPlan = async (userData, startDate, selectedPlan, isDeleteData) => {
    this.setState({loadingUsers: true})
    let userProfile = {};
    userProfile = {
      planLength: selectedPlan,
      actualPlanLength: selectedPlan,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      userId: userData._id
    };
    if (!userData.profile) {
      axios.post(`${BASE_URL}/api/user-profile/`, userProfile)
        .then(res => {
          this.getAllUsers(this.state.limit, this.state.offset)
        })
        .catch(err => {
          this.setState({loadingUsers: false, errorResponse: "Error assigning program duration. Try again"});
        });
    } else {
      if(isDeleteData){
        await axios.get(`${BASE_URL}/api/fitness-data/remove-data/${userData._id}`)
        await axios.get(`${BASE_URL}/api/weekly-estimate/remove-data/${userData._id}`)
      }
      axios.put(`${BASE_URL}/api/user-profile/${userData.profile._id}`, userProfile)
        .then(res => {
          this.getAllUsers(this.state.limit, this.state.offset)
        })
        .catch(err => {
          // dispatch({ type: GET_ERRORS, payload: err.response.data })
          this.setState({loadingUsers: false, errorResponse: "Error assigning program duration. Try again"});
        });
    }
  }

  onDurationChange = (userData, startDate, selectedPlan, canChange) => {
    this.setState({toggleAddDurationModal: false})
    if (userData.profile && userData.profile.startDate) {
      const oldDate = moment(userData.profile.startDate).format('YYYY-MM-DD')
      const newDate = moment(startDate).format('YYYY-MM-DD')
      if (canChange && ((oldDate !== newDate) || selectedPlan < userData.profile.actualPlanLength)) {
        confirmAlert({
          title: 'Data Lost',
          message: 'Changing the plan date/duration will lose the any assigned/submitted data. Are you sure to continue?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.updateUserPlan(userData, startDate, selectedPlan, true)
              }
            },
            {
              label: 'No',
              onClick: () => {
              }
            }
          ],
        });
      }
      else if(!canChange){
        const plan = Number(selectedPlan) + Number(userData.profile.planLength);
        this.updateUserPlan(userData,startDate, plan)
      }
      else {
        this.updateUserPlan(userData,startDate, selectedPlan)
      }
    }
    else {
      this.updateUserPlan(userData,startDate, selectedPlan)
    }
  }

  changeItemStatus = async record => {
    record.isActive = !record.isActive;
    const filter = {id: {$eq: `${record._id}`}};
    await axios.put(`${BASE_URL}/api/admin-users/${record._id}`, record)
      .then(async res => {
        const channels = await client.queryChannels(filter);
        this.getAllUsers(this.state.limit, this.state.offset);
        if (channels && channels.length > 0) {
          const response = await channels[0].update({frozen: !record.isActive, name: channels[0].data.name});
        }
      })
      .catch(err => {
        console.log(err)
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        // this.setState({ errors: err.response.data });
      });
  }
  
  getActiveSortedTrainers = ()=>{
    const {allTrainers} = this.state;
    let trainers = [...allTrainers];
    trainers = trainers.filter((trainer)=>trainer.isActive).sort((a,b) =>{
      if ( a.name.toLowerCase() < b.name.toLowerCase() ){
        return -1;
      }
      if ( a.name.toLowerCase() > b.name.toLowerCase() ){
        return 1;
      }
      return 0;
    });
    return trainers;
  }

  render() {
    const {
      allUsers, loadingUsers, allTrainers, allAdmins, currentUsers,
      toggleAddModal, addUserRole, toggleAddCoachModal, selectedUser,
      toggleAddDurationModal, canChange,defaultMessages
    } = this.state;

    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row>
              <Col xs={4}>
                <Search
                  placeholder="Search By Name"
                  allowClear
                  size="large"
                  onChange={(e)=>{this.searchUsersByName(e.target.value)}}
                  onSearch={this.searchUsersByName}
                />
              </Col>
              <Col xs={4}>
                <Search
                  placeholder="Search By Email"
                  allowClear
                  size="large"
                  onChange={(e)=>{this.searchUsersByEmail(e.target.value)}}
                  onSearch={this.searchUsersByEmail}
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All Users</h3>
                    </div>
                    <div className="col text-right">
                      <Button type="primary" size="small" onClick={() => this.showAddModal('user')}>
                        Add User</Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={loadingUsers}
                  style={{background: '#fff'}}
                  columns={this.userTableColumns}
                  dataSource={currentUsers}
                  rowKey={record => record._id}
                  pagination={{total: this.state.total, pageSize: 10}}
                  onChange={this.onChange}
                />
              </Card>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 20 }}>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All Trainers</h3>
                    </div>
                    <div className="col text-right">
                      <Button type="primary" size="small" onClick={() => this.showAddModal('trainer')} >
                        Add Trainer</Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={loadingUsers}
                  style={{ background: '#fff' }}
                  columns={this.trainerTableColumns}
                  dataSource={allTrainers}
                  rowKey={record => record._id}
                />
              </Card>
            </Col>
          </Row> */}
          {/* <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All Admins</h3>
                    </div>
                    <div className="col text-right">
                      <Button type="primary" size="small" onClick={() => this.showAddModal('admin')} >
                        Add Admin</Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={loadingUsers}
                  style={{ background: '#fff' }}
                  columns={this.adminTableColumns}
                  dataSource={allAdmins}
                  rowKey={record => record._id}
                />
              </Card>
            </Col>
          </Row> */}
          {toggleAddModal ?
            <Modal
              title={`Add ${addUserRole}`}
              visible={toggleAddModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideAddModal}
            >
              <AddUserItem userRole={addUserRole} closeModal={() => {
                this.getAllUsers(this.state.limit, this.state.offset)
              }}/>
            </Modal>
            : null}

          {toggleAddCoachModal ?
            <Modal
              title="Assign Coach"
              visible={toggleAddCoachModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideAddCoachModal}
            >
              <AssignCoach
                defaultMessages={defaultMessages}
                userData={selectedUser}
                trainers={this.getActiveSortedTrainers()}
                closeModal={() => {
                  this.getAllUsers(this.state.limit, this.state.offset)
                }
              }/>
            </Modal>
            : null}

          {toggleAddDurationModal ?
            <Modal
              title="Assign Program Duration"
              visible={toggleAddDurationModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideDurationModal}
            >
              <AddDuration
                userData={selectedUser}
                trainers={allTrainers}
                canChange={canChange}
                onSubmit={(startDate, selectedPlan) => {
                  this.onDurationChange(selectedUser, startDate, selectedPlan, canChange)
                }}
                closeModal={() => {
                  this.getAllUsers(this.state.limit, this.state.offset)
                }
                }/>
            </Modal>
            : null}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(allUsers));
