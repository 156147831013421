import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./styles.css";
import axios from "axios";
import moment from "moment"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input, Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
// import { DatePicker } from "antd"
import { DatePicker } from 'react-rainbow-components';
import {BASE_URL} from "../../constants";

class AddUserMeasurements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waist : "",
      hips : "",
      chest : "",
      rightBicep : "",
      leftBicep : "",
      rightThigh : "",
      leftThigh : "",
      errors: {},
      currentInputDate: new Date(),
    };
  }

  componentDidMount(){
    this.showPrefilledValues();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  showPrefilledValues(){
    let { currentInputDate, waist, hips, chest, rightBicep, leftBicep, rightThigh, leftThigh } = this.state
    const { measurements } = this.props;

    if (measurements && measurements.length) {
      let index = measurements.findIndex(e => {
        let findDate = moment.utc(e.date).format('YYYY-MM-DD');
        let currentDate = moment(currentInputDate).format('YYYY-MM-DD');
        return findDate === currentDate;
      })

      if (index !== -1) {
        waist = measurements[index].waist ? measurements[index].waist : "";
        hips = measurements[index].hips ? measurements[index].hips : "";
        chest = measurements[index].chest ? measurements[index].chest : "";
        rightBicep = measurements[index].rightBicep ? measurements[index].rightBicep : "";
        leftBicep = measurements[index].leftBicep ? measurements[index].leftBicep : "";
        rightThigh = measurements[index].rightThigh ? measurements[index].rightThigh : "";
        leftThigh = measurements[index].leftThigh ? measurements[index].leftThigh : "";
      } else {
        waist = ""; hips = ""; chest = ""; rightBicep = ""; leftBicep = ""; rightThigh = ""; leftThigh = "";
      }
      this.setState({ waist, hips, chest, rightBicep, leftBicep, rightThigh, leftThigh })
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const { waist, hips, chest, rightBicep, leftBicep, rightThigh, leftThigh, currentInputDate } = this.state
    const { userProfile, measurements } = this.props;

    let index = measurements.findIndex(e => {
      let findDate = moment(e.date.split('T')[0]).format('YYYY-MM-DD');
      let currentDate = moment(currentInputDate).format('YYYY-MM-DD');
      return findDate === currentDate;
    })

    const newData = {
      userId: userProfile.userId,
      date: moment(currentInputDate).format('YYYY-MM-DD'),
      waist : waist,
      hips : hips,
      chest : chest,
      rightBicep : rightBicep,
      leftBicep : leftBicep,
      rightThigh : rightThigh,
      leftThigh : leftThigh,
    };

    if(newData.userId){
      if(index !== -1){
        axios.put(`${BASE_URL}/api/user-measurements/${measurements[index]._id}`, newData)
        .then(res => {
          if(res.data && res.data._id){
            this.props.closeModal();
          }
        })
        .catch(err => {
          // dispatch({ type: GET_ERRORS, payload: err.response.data })
          this.setState({ errors: err.response.data });
        });
      } else {
        axios
        .post(`${BASE_URL}/api/user-measurements/`, newData)
        .then(res => {
          if(res.data && res.data._id){
            this.props.closeModal();
          }
        })
        .catch(err => {
          // dispatch({ type: GET_ERRORS, payload: err.response.data })
          this.setState({ errors: err.response.data });
        });
      }
    }
  };

  render() {
    const { errors, waist, hips, chest, rightBicep, leftBicep, rightThigh, leftThigh, currentInputDate } = this.state
    return (
      <>
        <Col lg="12" md="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody>
              <Label sm={12}>All Values are in Inches</Label>
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup row style={{ marginBottom: "3px" }}>
                  <Label for="exampleEmail" sm={3}>Date</Label>
                  <Col sm={9}>
                    <DatePicker
                      style={{ border: 0, borderColor: '#fff' }}
                      className="form-control form-control-alternative"
                      size="large"
                      format={'MMMM DD, YYYY'}
                      value={currentInputDate}
                      onChange={(date) =>
                        this.setState({ currentInputDate: date }, () => {
                          this.showPrefilledValues();
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "3px" }}>
                  <Label for="waist" sm={3}>Waist</Label>
                  <Col sm={9}>
                    <Input
                      onWheel={ event => event.currentTarget.blur() }
                      type="number" name="waist" id="waist" value={waist} error={errors.waist} onChange={this.onChange}/>
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "3px" }}>
                  <Label for="exampleEmail" sm={3}>Chest</Label>
                  <Col sm={9}>
                    <Input
                      onWheel={ event => event.currentTarget.blur() }
                      type="number" name="chest" id="chest" value={chest} error={errors.chest} onChange={this.onChange}/>
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "3px" }}>
                  <Label for="exampleEmail" sm={3}>Hips</Label>
                  <Col sm={9}>
                    <Input
                      onWheel={ event => event.currentTarget.blur() }
                      type="number" name="hips" id="hips" value={hips} error={errors.hips} onChange={this.onChange}/>
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "3px" }}>
                  <Label for="exampleEmail" sm={3}>Right Bicep</Label>
                  <Col sm={9}>
                    <Input
                      onWheel={ event => event.currentTarget.blur() }
                      type="number" name="rightBicep" id="rightBicep" value={rightBicep} error={errors.rightBicep} onChange={this.onChange}/>
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "3px" }}>
                  <Label for="exampleEmail" sm={3}>Left Bicep</Label>
                  <Col sm={9}>
                    <Input
                      onWheel={ event => event.currentTarget.blur() }
                      type="number" name="leftBicep" id="leftBicep" value={leftBicep} error={errors.leftBicep} onChange={this.onChange}/>
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "3px" }}>
                  <Label for="exampleEmail" sm={3}>Right Thigh</Label>
                  <Col sm={9}>
                    <Input
                      onWheel={ event => event.currentTarget.blur() }
                      bsSize="small" type="number" name="rightThigh" id="rightThigh" value={rightThigh} error={errors.rightThigh} onChange={this.onChange}/>
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "3px" }}>
                  <Label for="exampleEmail" sm={3}>Left Thigh</Label>
                  <Col sm={9}>
                    <Input
                      onWheel={ event => event.currentTarget.blur() }
                      type="number" name="leftThigh" id="leftThigh" value={leftThigh} error={errors.leftThigh} onChange={this.onChange}/>
                  </Col>
                </FormGroup>
                <div className="text-center">
                  <Button type="submit" className="mt-4" color="primary">
                    Add new Measurements</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(AddUserMeasurements));
