import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import {Line, Bar} from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import UserDashBoardHeader from "../../components/Headers/UserDashBoardHeader";
import moment from "moment";
import axios from "axios";
import {Column} from '@ant-design/charts'
import {Table, Modal, Button} from 'antd';
import AddUserMeasurements from "./AddUserMeasurements";
import {BASE_URL} from "../../constants";

const measureTableColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 150,
    render: (d) => d ? <span>{moment.utc(d).format('ddd')}, {moment.utc(d).format('MMMM DD, YYYY')}</span> : "Diff",
  },
  {
    title: 'Waist',
    dataIndex: 'waist',
    key: 'waist',
  },
  {
    title: 'Chest',
    dataIndex: 'chest',
    key: 'chest',
  },
  {
    title: 'Hips',
    dataIndex: 'hips',
    key: 'hips',
  },
  {
    title: 'Bicep L',
    dataIndex: 'leftBicep',
    key: 'leftBicep',
  },
  {
    title: 'Bicep R',
    dataIndex: 'rightBicep',
    key: 'rightBicep',
  },
  {
    title: 'Thigh L',
    dataIndex: 'leftThigh',
    key: 'leftThigh',
  },
  {
    title: 'Thigh R',
    dataIndex: 'rightThigh',
    key: 'rightThigh',
  },

];
const measureTableColumnsDiff = [
  {
    title: 'Total Diff',
    dataIndex: 'diff',
    key: 'diff',
    width: 150,
  },
  {
    title: 'Waist',
    dataIndex: 'waist',
    key: 'waist',
  },
  {
    title: 'Chest',
    dataIndex: 'chest',
    key: 'chest',
  },
  {
    title: 'Hips',
    dataIndex: 'hips',
    key: 'hips',
  },
  {
    title: 'Bicep L',
    dataIndex: 'leftBicep',
    key: 'leftBicep',
  },
  {
    title: 'Bicep R',
    dataIndex: 'rightBicep',
    key: 'rightBicep',
  },
  {
    title: 'Thigh L',
    dataIndex: 'leftThigh',
    key: 'leftThigh',
  },
  {
    title: 'Thigh R',
    dataIndex: 'rightThigh',
    key: 'rightThigh',
  },

];

class UserDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      userData: {},
      userProfile: {},
      dailyFitnessData: {},
      currentWeek: 0,
      currentWeight: 0,
      weightChange: 0,
      currentWaist: 0,
      lastEntryDate: null,
      activeNav: 1,
      lineChartDataIndex: "carbos",
      lineChartData: {
        carbos: {},
        fats: {},
        protein: {},
        calories: {}
      },
      barChartData: {
        labels: [],
        datasets: [{data: []}]
      },
      weightValues: {
        labels: [],
        datasets: [{data: []}]
      },
      weightChartOptions: {},
      antdConfig: {
        data: [],
        xField: 'date',
        yField: 'value',
        xAxis: {label: {autoRotate: false}},
        color: '#d8b66d',
        slider: {
          start: 0,
          end: 1.00,
        },
      },
      weightConfig: {
        data: [],
        xField: 'date',
        yField: 'value',
        xAxis: {label: {autoRotate: false}},
        yAxis: {min: 150, max: 200, step: 5},
        color: '#d8b66d',
        slider: {
          start: 0,
          end: 1.00,
        },
      },
      loadingMeasures: false,
      toggleAddMeasureModal: false,
      measurements: [],
      diffMeasures: [],
      totalDiff: 0
    }
  }

  async componentWillMount() {

    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }


    let {userData} = this.state;
    const {location} = this.props
    if (location.state && location.state.selectedUser) {
      userData = location.state.selectedUser
      this.setState({userData: location.state.selectedUser});
    } else if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
      this.setState({userData})
    }

    await this.getUserProfile(userData._id);
    await this.getUserDailyData(userData._id);
    await this.getUserMeasurements(userData._id);
  }

  async getUserProfile(userId) {
    this.setState({loadingUsers: true, toggleAddModal: false})
    await axios.get(`${BASE_URL}/api/user-profile/${userId}`)
      .then(res => {
        if (res.data.userId === userId) {
          localStorage.setItem("userProfile", JSON.stringify(res.data));
          this.setState({userProfile: res.data})
        }
      })
      .catch(err => {
        this.setState({loadingUsers: false,});
      });
  };

  async getUserDailyData(userId) {
    this.setState({dailyDataLoading: true})

    await axios.get(`${BASE_URL}/api/fitness-data/${userId}`)
      .then(res => {
        if (res.data && res.data) {
          this.filterDailyFitnessData(res.data);
          this.makeLineChartsData(res.data);
          this.makeBarChartData(res.data);
          this.makeWeightChartData(res.data);
          this.setState({dailyFitnessData: res.data});
        }
        this.setState({dailyDataLoading: false});
      })
      .catch(err => {
        this.setState({dailyDataLoading: false,});
      });

  };

  async getUserMeasurements(userId) {
    this.setState({loadingMeasures: true})
    await axios.get(`${BASE_URL}/api/user-measurements/${userId}`)
      .then(async res => {
        if (res.data && res.data.length) {
          await res.data.sort((a, b) => new Date(a.date) - new Date(b.date))

          let diffData = {waist: 0, hips: 0, chest: 0, rightBicep: 0, leftBicep: 0, rightThigh: 0, leftThigh: 0}

          if (res.data.length > 1) {

            for (let i = (res.data.length - 1); i > 0; i--) {
              diffData.waist = !diffData.waist && res.data[i].waist ? res.data[i].waist : diffData.waist;
              diffData.hips = !diffData.hips && res.data[i].hips ? res.data[i].hips : diffData.hips;
              diffData.chest = !diffData.chest && res.data[i].chest ? res.data[i].chest : diffData.chest;
              diffData.rightBicep = !diffData.rightBicep && res.data[i].rightBicep ? res.data[i].rightBicep : diffData.rightBicep;
              diffData.leftBicep = !diffData.leftBicep && res.data[i].leftBicep ? res.data[i].leftBicep : diffData.leftBicep;
              diffData.rightThigh = !diffData.rightThigh && res.data[i].rightThigh ? res.data[i].rightThigh : diffData.rightThigh;
              diffData.leftThigh = !diffData.leftThigh && res.data[i].leftThigh ? res.data[i].leftThigh : diffData.leftThigh;
            }

            diffData.waist = (diffData.waist - res.data[0].waist).toFixed(1);
            diffData.hips = (diffData.hips - res.data[0].hips).toFixed(1);
            diffData.chest = (diffData.chest - res.data[0].chest).toFixed(1);
            diffData.rightBicep = (diffData.rightBicep - res.data[0].rightBicep).toFixed(1);
            diffData.leftBicep = (diffData.leftBicep - res.data[0].leftBicep).toFixed(1);
            diffData.rightThigh = (diffData.rightThigh - res.data[0].rightThigh).toFixed(1);
            diffData.leftThigh = (diffData.leftThigh - res.data[0].leftThigh).toFixed(1);
          }

          let totalDiff = Number(diffData.waist) + Number(diffData.hips) + Number(diffData.chest) + Number(diffData.rightBicep) + Number(diffData.leftBicep) + Number(diffData.rightThigh) + Number(diffData.leftThigh);
          diffData.diff = Number(totalDiff).toFixed(1);

          this.setState({measurements: res.data, diffMeasures: [diffData], totalDiff})
        }
        this.setState({loadingMeasures: false,});
      })
      .catch(err => {
        this.setState({loadingMeasures: false,});
      });
  }

  filterDailyFitnessData(fitnessData) {
    let {userProfile, currentWeek, currentWaist, currentWeight, weightChange, lastEntryDate} = this.state;
    let currentDate = moment.utc(new Date()).format('MMMM DD, YYYY');

    if (userProfile && userProfile.planLength) {
      for (let i = 1; i <= userProfile.planLength; i++) {
        let weekStart = moment.utc(userProfile.startDate).add((i - 1) * 7, 'days');
        let weekEnd = moment.utc(userProfile.startDate).add((i * 7) - 1, 'days');

        if (currentDate >= moment.utc(weekStart).format("MMMM DD, YYYY") && currentDate <= moment.utc(weekEnd).format("MMMM DD, YYYY")) {
          currentWeek = i;
          this.setState({currentWeek});
          break;
        }
        // console.log('week ====== : ', i, moment(weekStart).format("MMMM DD, YYYY"), moment(weekEnd).format("MMMM DD, YYYY"));
      }
    }

    fitnessData.dailyData.sort((a, b) => new Date(a.date) - new Date(b.date))

    if (fitnessData && fitnessData.dailyData.length) {
      for (let i = fitnessData.dailyData.length; i > 0; i--) {
        if (fitnessData.dailyData[(i - 1)].weight && !currentWeight) {
          currentWeight = fitnessData.dailyData[(i - 1)].weight;
        }
        if (fitnessData.dailyData[(i - 1)].waist && !currentWaist) {
          currentWaist = fitnessData.dailyData[(i - 1)].waist;
        }
        if (fitnessData.dailyData[(i - 1)].date && !lastEntryDate) {
          lastEntryDate = fitnessData.dailyData[(i - 1)].date;
        }
      }

      if (userProfile && userProfile.weight) {
        weightChange = currentWeight - userProfile.weight
      }

      this.setState({currentWaist, currentWeight, lastEntryDate, weightChange})
    }

  }

  makeLineChartsData(fitnessData) {
    let {userProfile, lineChartData} = this.state;

    /* let carbs = {
      labels: [],
      datasets: [{ data: [] }]
    };
    let fats = {
      labels: [],
      datasets: [{ data: [] }]
    };
    let protein = {
      labels: [],
      datasets: [{ data: [] }]
    };
    let calories = {
      labels: [],
      datasets: [{ data: [] }]
    }; */

    let carbs = [];
    let fats = [];
    let protein = [];
    let calories = [];

    if (userProfile && userProfile.planLength && fitnessData && fitnessData.dailyData.length) {
      fitnessData.dailyData.forEach(element => {
        /* carbs.labels.push(moment.utc(element.date).format("DD-MMM-YY"))
        carbs.datasets[0].data.push(element.carbohydrate);
        fats.labels.push(moment.utc(element.date).format("DD-MMM-YY"))
        fats.datasets[0].data.push(element.fat);
        protein.labels.push(moment.utc(element.date).format("DD-MMM-YY"))
        protein.datasets[0].data.push(element.protein);
        calories.labels.push(moment.utc(element.date).format("DD-MMM-YY"))
        calories.datasets[0].data.push((element.carbohydrate * 4) + (element.protein * 4) + (element.fat * 9)); */
        carbs.push({"date": moment.utc(element.date).format("DD-MMM-YY"), "value": element.carbohydrate})
        fats.push({"date": moment.utc(element.date).format("DD-MMM-YY"), "value": element.fat})
        protein.push({"date": moment.utc(element.date).format("DD-MMM-YY"), "value": element.protein})
        calories.push({
          "date": moment.utc(element.date).format("DD-MMM-YY"),
          "value": (element.carbohydrate * 4) + (element.protein * 4) + (element.fat * 9)
        })
      });
    }
    lineChartData.carbos = carbs;
    lineChartData.fats = fats;
    lineChartData.protein = protein;
    lineChartData.calories = calories;

    let config = this.state.antdConfig;
    config.data = lineChartData.carbos

    this.setState({
      lineChartData,
      antdConfig: config
    })
  }

  makeBarChartData(data) {
    let {userProfile, barChartData} = this.state;
    let dummyData = {
      labels: [],
      datasets: [{data: []}]
    }

    if (userProfile && userProfile.planLength) {
      for (let i = 1; i <= userProfile.planLength; i++) {
        if (data.dailyData && data.dailyData.length) {

          let weekStart = moment.utc(userProfile.startDate).add((i - 1) * 7, 'days');
          let weekEnd = moment.utc(userProfile.startDate).add((i * 7) - 1, 'days');
          let startD = moment.utc(weekStart).format('YYYY-MM-DD');
          let endD = moment.utc(weekEnd).format('YYYY-MM-DD');

          let totalKcal = 0;
          let itemscount = 0;
          data.dailyData.forEach(item => {
            let itemDate = moment.utc(item.date).format('YYYY-MM-DD');
            if (itemDate >= startD && itemDate <= endD) {
              totalKcal += item.totalKcal;
              itemscount++;
            }
          });
          let avgKcal = itemscount !== 0 ? totalKcal / itemscount : 0;
          dummyData.labels.push(`week ${i}`);
          dummyData.datasets[0].data.push(avgKcal);

        }
      }

      barChartData.labels = dummyData.labels;
      barChartData.datasets[0].data = dummyData.datasets[0].data;
      this.setState({barChartData})
    }
  }

  makeWeightChartData(fitnessData) {
    let {weightValues, weightChartOptions, userProfile} = this.state;
    /* let weightValuesStructure = {
      labels: [],
      datasets: [{ data: [] }]
    }; */
    let weightValuesStructure = [];

    if (fitnessData && fitnessData.dailyData.length && userProfile) {
      for (let i = 0; i < fitnessData.dailyData.length; i++) {

        if (fitnessData.dailyData[i].weight) {
          /* weightValuesStructure.labels.push(moment.utc(fitnessData.dailyData[i].date).format("DD-MMM-YY"))
          weightValuesStructure.datasets[0].data.push(fitnessData.dailyData[i].weight); */
          weightValuesStructure.push({
            "date": moment.utc(fitnessData.dailyData[i].date).format("DD-MMM-YY"),
            "value": fitnessData.dailyData[i].weight
          })
        }
      }
      weightValues = weightValuesStructure;

      this.setState({weightValues})

      let config = this.state.weightConfig;
      config.data = weightValues
      const minWeight = weightValues.reduce(function (prev, curr) {
        return prev.value < curr.value ? prev : curr;
      });
      const maxWeight = weightValues.reduce(function (prev, curr) {
        return prev.value > curr.value ? prev : curr;
      });
      config.yAxis = {
        min: userProfile.targetWeight ? parseInt(userProfile.targetWeight) : 0,
        max: userProfile.weight ? parseInt(userProfile.weight) : 200
      }
      config.yAxis = {
        min: config.yAxis.min > minWeight.value ? minWeight.value - 10 : config.yAxis.min,
        max: config.yAxis.max < maxWeight.value ? maxWeight.value : config.yAxis.max +10
      }
      this.setState({
        weightValues,
        weightConfig: config
      })
    }
  }

  toggleNavs = (e, index) => {
    let chartDataKey = "";
    let {antdConfig, lineChartData} = this.state;

    let config = antdConfig;

    if (index === 1) {
      config.data = lineChartData.carbos
    } else if (index === 2) {
      config.data = lineChartData.fats
    } else if (index === 3) {
      config.data = lineChartData.protein
    } else if (index === 4) {
      config.data = lineChartData.calories
    }
    e.preventDefault();
    this.setState({
      activeNav: index,
      lineChartDataIndex: chartDataKey,
      antdConfig: config
    });
    let wow = () => {
      // console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  showAddMeasureModal() {
    this.setState({toggleAddMeasureModal: true,});
  };

  hideAddMeasureModal = () => {
    this.setState({toggleAddMeasureModal: false});
  };

  render() {
    const {
      userProfile, currentWeek, currentWaist, currentWeight, lastEntryDate, weightChange,userData,
      toggleAddMeasureModal, loadingMeasures, measurements, diffMeasures, totalDiff, weightValues
    } = this.state;
    const loggedInUser = JSON.parse(localStorage.loggedInUser)
    let minWeight = null, maxWeight = null
    if (Array.isArray(this.state.weightValues) && weightValues.length > 0) {
      minWeight = weightValues.reduce(function (prev, curr) {
        return prev.value < curr.value ? prev : curr;
      });
      maxWeight = weightValues.reduce(function (prev, curr) {
        return prev.value > curr.value ? prev : curr;
      });
    }
    return (
      <>
        <UserDashBoardHeader
          userProfile={userProfile}
          currentWeek={currentWeek}
          currentWaist={currentWaist}
          currentWeight={currentWeight}
          maxWeight={maxWeight}
          minWeight={minWeight}
          lastActive={lastEntryDate}
          weightChange={weightChange}
          totalDiff={totalDiff}
        />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="my-3 mb-xl-0" xl="12">
              <Card className="bg-gradient-dark shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6> */}
                      <h2 className="text-white mb-0">Nutrition Values</h2>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Carbs</span>
                            <span className="d-md-none">C</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Fats</span>
                            <span className="d-md-none">F</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 3
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 3)}
                          >
                            <span className="d-none d-md-block">Protein</span>
                            <span className="d-md-none">P</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 4
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 4)}
                          >
                            <span className="d-none d-md-block">Calories</span>
                            <span className="d-md-none">Cal</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    {/* <Bar
                      // data={chartExample1[this.state.chartExample1Data]}
                      data={lineChartData[lineChartDataIndex]}
                      options={chartExample1.options}
                      getDatasetAtEvent={e => console.log(e)}
                    /> */}
                    <Column {...this.state.antdConfig} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row className="mt-5">
            <Col xl="12">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0">Average Total Kcals</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={barChartData}
                      options={chartExample2.options}
                      getDatasetAtEvent={e => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col className="my-3 mb-xl-0" xl="12">
              <Card className="bg-gradient-dark shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6> */}
                      <h2 className="text-white mb-0">Weight Chart</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    {/* <Line
                      // data={chartExample1[this.state.chartExample1Data]}
                      data={weightValues}
                      options={weightChartOptions}
                      getDatasetAtEvent={e => console.log(e)}
                    /> */}
                    <Column {...this.state.weightConfig} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="my-3 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Body Measurement</h3>
                    </div>
                    <div className="col text-right">
                      {loggedInUser && loggedInUser.role !== 'trainer' && <Button type="primary" size="small" onClick={() => this.showAddMeasureModal()}>
                        Add New Measurement</Button>}
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={loadingMeasures}
                  style={{background: '#fff'}}
                  columns={measureTableColumns}
                  dataSource={measurements}
                  rowKey={record => record._id}
                  bordered={true}
                />
                <Table
                  loading={loadingMeasures}
                  style={{background: '#fff'}}
                  columns={measureTableColumnsDiff}
                  dataSource={diffMeasures}
                  rowKey={record => record._id}
                  pagination={false}
                  showHeader={true}
                  bordered={true}
                />
              </Card>
            </Col>
          </Row>

          {toggleAddMeasureModal ?
            <Modal
              title={`Add Measurements`}
              visible={toggleAddMeasureModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideAddMeasureModal}
            >
              <AddUserMeasurements userProfile={userProfile} measurements={measurements} closeModal={() => {
                this.hideAddMeasureModal();
                this.getUserMeasurements(userProfile.userId);
              }}/>
            </Modal>
            : null}

        </Container>
      </>
    );
  }
}

export default UserDashboard;
