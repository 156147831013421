import React from "react";
import { Button, Form, Input, Modal } from "antd";

function ConnectModal (props) {
  const { getFieldDecorator } = props.form;
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        props.connectToMacros(values)
      }
    });
  };
  return(
    <div>
      <Modal
        title="Connect to MyMacros+"
        visible={props.macrosConnectModal}
        onCancel={props.onCancel}
        footer={null}
      >
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={(values)=>{console.log('values', values)}}
          onSubmit={handleSubmit}
          /*onSubmit={event =>{
            console.log('event',event)
            event.preventDefault();
          }}*/
        >
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your email!' }],
            })(
              <Input
                className={'border'}
                placeholder="Email"
              />
            )}
          </Form.Item>
  
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your password!' }],
            })(
              <Input.Password className={'border'} placeholder="MyMacros+ password" />
            )}
          </Form.Item>
      
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={props.loadingUsers}
              type="primary" htmlType="submit" style={{width: 200}}>
              Connect
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
export default Form.create({ name: 'normal_login' })(ConnectModal);
