import React from "react";
import {withRouter} from "react-router-dom";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import axios from "axios";
import {Badge, Button, Collapse, Input, message, Popover, Radio, Select, Table} from 'antd';
import moment from 'moment';
import "./TrainerStyles.css"
import Gallery from "../userViews/Gallery";
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const {Panel} = Collapse;
const {Option} = Select;

const dailyDataColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: text => <span>{moment.utc(text).format('ddd')}, {moment.utc(text).format('MMMM DD, YYYY')}</span>,
  },
  {
    title: 'Carbs',
    dataIndex: 'carbohydrate',
    key: 'carbohydrate',
  },
  {
    title: 'Fat',
    dataIndex: 'fat',
    key: 'fat',
  },
  {
    title: 'Protein',
    key: 'protein',
    dataIndex: 'protein',
  },
  {
    title: 'Fiber',
    key: 'fiber',
    dataIndex: 'fiber',
  },
  {
    title: 'Total Kcal',
    dataIndex: 'totalKcal',
    key: 'kcal',
  },
  {
    title: 'Water',
    dataIndex: 'water',
    key: 'water',
  },
  {
    title: 'Waist',
    dataIndex: 'waist',
    key: 'waist',
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: 'Exercise',
    dataIndex: 'exercise',
    key: 'exercise',
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments',
  },
];

const client = StreamChat.getInstance(GETSTREAM_KEY);

class UserDetails extends React.Component {

  constructor() {
    super();
    this.avegareColumns = [
      {
        title: 'Week No',
        key: 'weekNumber',
        render: (data) => (data.weekNumber),
      },
      {
        title: 'Avge Carbs',
        key: 'avgeCarbs',
        render: (data) => (data.carbohydrate),
      },
      {
        title: 'Avge Fats',
        key: 'avgeFats',
        render: (data) => (data.fat),
      },
      {
        title: 'Avge Protein',
        key: 'avgeProtein',
        render: (data) => (data.protein),
      },
      {
        title: 'Avge Kcal',
        key: 'avgeKcal',
        render: (data) => (data.totalKcal),
      },
      {
        title: 'Avge Fiber',
        key: 'avgeFiber',
        render: (data) => (data.fiber),
      },
      {
        title: 'Avge Water',
        key: 'avgeWater',
        render: (data) => (data.water),
      },
      {
        title: 'Avge Waist',
        key: 'avgeWaist',
        render: (data) => (data.waist),
      },
      {
        title: 'Avge Weight',
        key: 'avgeWeight',
        render: (data) => (data.weight),
      }
    ];

    this.weeklyAvegareColumns = [
      {
        title: 'Week No',
        key: 'weekNumber',
        render: (data) => (<span>{data.weekNumber}</span>),
      },
      {
        title: 'Avge Carbs',
        key: 'avgeCarbs',
        render: (data) => (this.renderAvgeColumnData(data, 'carbohydrate')),
      },
      {
        title: 'Avge Fats',
        key: 'avgeFats',
        render: (data) => (this.renderAvgeColumnData(data, 'fat')),
      },
      {
        title: 'Avge Protein',
        key: 'avgeProtein',
        render: (data) => (this.renderAvgeColumnData(data, 'protein')),
      },
      {
        title: 'Avge Kcal',
        key: 'avgeKcal',
        render: (data) => (this.renderAvgeColumnData(data, 'totalKcal')),
      },
      {
        title: 'Avge Fiber',
        key: 'avgeFiber',
        render: (data) => (this.renderAvgeColumnData(data, 'fiber')),
      },
      {
        title: 'Avge Water',
        key: 'avgeWater',
        render: (data) => (this.renderAvgeColumnData(data, 'water')),
      },
      {
        title: 'Avge Waist',
        key: 'avgeWaist',
        render: (data) => (this.renderAvgeColumnData(data, 'waist')),
      },
      {
        title: 'Avge Weight',
        key: 'avgeWeight',
        render: (data) => (this.renderAvgeColumnData(data, 'weight')),
      },
      {
        title: 'Weekly Comment',
        key: 'comment',
        // render: (data) => <span>{data.weeklyComment ? data.weeklyComment : ""}</span>
        render: (data) => <div>
          < Popover placement="topLeft" arrowPointAtCenter
                    content={data.weeklyComment ? data.weeklyComment : ""} trigger="click">
            <Button>View Comments</Button>
          </Popover>
        </div>
      }
    ];

    this.state = {
      dailyDataLoading: false,
      fixedDataLoading: false,
      saveCommentsLoading: false,
      selectedUser: {},
      userData: {},
      userFixedData: {},
      userDailyData: {},
      inputtedWeeklyUserData: [],
      weeklyDataType: [],
      formatDailyData: [],
      weeklyTablesData: []
    }
  }

  renderAvgeColumnData(data, columnKey,) {
    let calData = 0;
    let items = 0
    data.dailyData.forEach(element => {
      if (element[columnKey]) {
        calData += element[columnKey];
        items++;
      }
    });
    return (
      columnKey === "weight" || columnKey === "waist" ?
        <span>{items ? (calData / items).toFixed(1) : 0}</span>
        :
        <span>{items ? (calData / items).toFixed(0) : 0}</span>
    );
  }

  async componentWillMount() {
    let {userData, selectedUser, weeklyDataType} = this.state;
    selectedUser = this.props.location.state.selectedUser;

    let makeWeeklyData = [];
    weeklyDataType = [];
    for (let i = 0; i < selectedUser.profile.planLength; i++) {
      makeWeeklyData.push([
        {days: 0, carbs: undefined, Fat: undefined, protein: undefined},
        {days: undefined, carbs: undefined, Fat: undefined, protein: undefined}
      ])
      weeklyDataType.push("cycling");
    }
    this.setState({inputtedWeeklyUserData: makeWeeklyData, weeklyDataType});

    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
      this.setState({userData, selectedUser})
    }

    await this.getUserDailyData(selectedUser);
    await this.getUserFixedData(userData, selectedUser);
  }

  async getUserDailyData(user) {
    this.setState({dailyDataLoading: true})

    await axios.get(`${BASE_URL}/api/fitness-data/${user._id}`)
      .then(res => {
        this.formatDailyDataToWeekly(res.data)
        this.setState({
          dailyDataLoading: false,
        });
      })
      .catch(err => {
        this.formatDailyDataToWeekly({})
        this.setState({dailyDataLoading: false,});
      });

  };

  async getUserFixedData(loggedUser, user) {
    this.setState({fixedDataLoading: true})

    await axios.get(`${BASE_URL}/api/weekly-estimate/${user._id}`)
      .then(res => {
        this.saveInputtedData(res.data);
        this.setState({fixedDataLoading: false});
      })
      .catch(err => {
        this.setState({fixedDataLoading: false});
      });

  };

  async saveWeeklyEstimatedData(weekNumber) {
    this.setState({saveFixedDataLoading: true})
    const {userData, selectedUser, userFixedData, inputtedWeeklyUserData, weeklyDataType} = this.state;

    if (selectedUser.profile && selectedUser.profile.startDate) {

      let weekStart = moment.utc(selectedUser.profile.startDate).add((weekNumber - 1) * 7, 'days');
      let weekEnd = moment.utc(selectedUser.profile.startDate).add(weekNumber * 7, 'days');

      if (inputtedWeeklyUserData[(weekNumber - 1)] && inputtedWeeklyUserData[(weekNumber - 1)][0].days == 7) {
        inputtedWeeklyUserData[(weekNumber - 1)][1].Fat = 0;
        inputtedWeeklyUserData[(weekNumber - 1)][1].carbs = 0;
        inputtedWeeklyUserData[(weekNumber - 1)][1].protein = 0;
      }

      /* console.log('dateeee: ', selectedUser.profile.startDate, weekStart, moment.utc(weekStart).format('YYYY-MM-DD'),
       weekEnd, moment.utc(weekEnd).format('YYYY-MM-DD')) */
      const userEstimatedData = {
        userId: selectedUser._id,
        trainerId: userData._id,
        estimatedData: [{
          weekNo: weekNumber,
          startDate: moment.utc(weekStart).format('YYYY-MM-DD'),
          endDate: moment.utc(weekEnd).format('YYYY-MM-DD'),
          dataInputType: weeklyDataType[(weekNumber - 1)],
          weeklyData: inputtedWeeklyUserData[(weekNumber - 1)]
        }]
      };

      if (userFixedData.userId && userFixedData.userId === selectedUser._id) {

        let ind = userFixedData.estimatedData.findIndex(e => e.weekNo === weekNumber);
        if (ind !== -1) {
          userFixedData.estimatedData[weekNumber - 1].weeklyData = inputtedWeeklyUserData[(weekNumber - 1)];
          userFixedData.estimatedData[weekNumber - 1].dataInputType = weeklyDataType[(weekNumber - 1)];
        } else {
          userFixedData.estimatedData.push({
            weekNo: weekNumber,
            startDate: moment.utc(weekStart).format('YYYY-MM-DD'),
            endDate: moment.utc(weekEnd).format('YYYY-MM-DD'),
            dataInputType: weeklyDataType[(weekNumber - 1)],
            weeklyData: inputtedWeeklyUserData[(weekNumber - 1)]
          });
        }

        await axios.put(`${BASE_URL}/api/weekly-estimate/${userFixedData._id}`, userFixedData)
          .then(res => {
            this.saveInputtedData(res.data);
            this.setState({saveFixedDataLoading: false,});
          })
          .catch(err => {
            this.setState({saveFixedDataLoading: false,});
          });

      } else {

        await axios.post(`${BASE_URL}/api/weekly-estimate/`, userEstimatedData)
          .then(res => {
            this.saveInputtedData(res.data);
            this.setState({saveFixedDataLoading: false});
          })
          .catch(err => {
            this.setState({saveFixedDataLoading: false,});
          });
      }
      const filter = {id: {$eq: `${selectedUser._id}`}};
      const channels = await client.queryChannels(filter);
      if (channels && channels.length >0) {
       const reply = await  channels[0].sendMessage({
          text: `Your plan for week ${weekNumber} is ready for you`
        });
      }
    }
  }

  async saveWeeklyComments(index) {

    const {userFixedData, formatDailyData} = this.state;

    if (formatDailyData[index].writingComment) {
      let weekIndex = userFixedData.estimatedData.findIndex(e => e.weekNo === formatDailyData[index].weekNumber);

      if (userFixedData.estimatedData.length && weekIndex !== -1) {
        this.setState({saveCommentsLoading: true})
        userFixedData.estimatedData[weekIndex].weeklyComment = formatDailyData[index].writingComment;

        await axios.put(`${BASE_URL}/api/weekly-estimate/${userFixedData._id}`, userFixedData)
          .then(res => {
            this.saveInputtedData(res.data);
            this.setState({saveCommentsLoading: false,});
            message.info('comments saved successfully');
          })
          .catch(err => {
            this.setState({saveCommentsLoading: false,});
            message.error('Error occured! try again.');
          });

      } else {
        message.error('first save the weekly estimates');
      }
    } else {
      message.error('comment is empty');
    }
  }

  showWeekLabels(index) {
    const {formatDailyData, inputtedWeeklyUserData, userFixedData} = this.state;
    let status = "";
    let assignedValues = "";
    let weekDuration = "";
    if (formatDailyData[index] && formatDailyData[index].currentWeek) {
      status = "Current Week";
    }
    if (Object.keys(userFixedData).length) {
      let weekInd = userFixedData.estimatedData.findIndex(e => e.weekNo === (index + 1));
      if (weekInd !== -1) {
        assignedValues = "(Assigned Values)";
        weekDuration = `(${moment.utc(userFixedData.estimatedData[weekInd].startDate).format('MMM DD, YYYY')} - ${moment.utc(moment(userFixedData.estimatedData[weekInd].endDate).subtract(1, "days")).format('MMM DD, YYYY')})`
      }
    }
    return (
      <span>{"week " + (index + 1)} {status} {assignedValues} {weekDuration}</span>
    );
  }

  showWeekHeaderTheme(index) {
    const {formatDailyData} = this.state;
    let designClass = "";
    if (formatDailyData[index] && formatDailyData[index].currentWeek) {
      designClass = "panel-header-current";
    } else if (formatDailyData[index] && formatDailyData[index].previousWeek) {
      designClass = "panel-header-previous";
    }
    return designClass;
  }

  changeCommentField(index, value) {
    let {formatDailyData} = this.state;
    formatDailyData[index].writingComment = value;
    this.setState({formatDailyData});
  }

  renderCommentsValue(i) {
    const {formatDailyData} = this.state;

    if (formatDailyData.length && formatDailyData[i].weeklyComment) {
      return formatDailyData[i].weeklyComment;
    } else if (formatDailyData.length && formatDailyData[i].writingComment) {
      return formatDailyData[i].writingComment
    } else {
      return "";
    }
  }

  calculateCalories(carbs, protein, fats,) {
    return Number((carbs ? Number(carbs) * 4 : 0) + (protein ? Number(protein) * 4 : 0) + (fats ? Number(fats) * 9 : 0)).toFixed(0);
  }

  calculateAvgCalories(firstRow, secondRow) {
    let firstRowMultiplyDays = 0;
    let secondRowMultiplyDays = 0;
    if (Number(firstRow.days) !== 0) {

      let firstRowkcal = (firstRow.carbs ? Number(firstRow.carbs) * 4 : 0) +
        (firstRow.protein ? Number(firstRow.protein) * 4 : 0) +
        (firstRow.Fat ? Number(firstRow.Fat) * 9 : 0);

      firstRowMultiplyDays = firstRowkcal * Number(firstRow.days);

      if (Number(secondRow.days) > 0) {
        let secondRowkcal = (secondRow.carbs ? Number(secondRow.carbs) * 4 : 0) +
          (secondRow.protein ? Number(secondRow.protein) * 4 : 0) +
          (secondRow.Fat ? Number(secondRow.Fat) * 9 : 0);

        secondRowMultiplyDays = secondRowkcal * Number(secondRow.days);
      }
    }
    return Number((firstRowMultiplyDays + secondRowMultiplyDays) / 7).toFixed(2)
  }

  selectRadioType(index, val) {
    const {weeklyDataType} = this.state;
    weeklyDataType[index] = val;
    if (val === "ranges") {
      this.changeFieldValue(index, 0, 'days', 0, "ranges");
    } else {
      this.changeFieldValue(index, 0, 'days', 6);
    }
    this.setState({weeklyDataType})
  }

  renderWeeklyPanels = (planLength) => {
    let {saveFixedDataLoading, inputtedWeeklyUserData, formatDailyData, saveCommentsLoading, weeklyDataType} = this.state;

    let panels = [];
    for (let i = 0; i < planLength; i++) {
      panels.push(
        <Panel key={i}
               className={this.showWeekHeaderTheme(i)}
               header={this.showWeekLabels(i)}>
          <Row>
            <h6 className="heading-small text-muted align-items-center mx-3">
              Weekly Estimated Values
            </h6>
            <Radio.Group style={{margin: "auto"}} value={weeklyDataType[i]} buttonStyle="solid"
                         onChange={(e) => this.selectRadioType(i, e.target.value)}>
              <Radio.Button value="cycling">Kcal Cycling</Radio.Button>
              <Radio.Button value="ranges">Ranges</Radio.Button>
            </Radio.Group>
          </Row>
          <div className="pl-lg-4">
            {weeklyDataType[i] === "cycling" ?
              <Row>
                <Col lg="2">
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Number of days</label>
                </Col>
                <Col>
                  <Select
                    className="my-2"
                    style={{height: 30, width: "-webkit-fill-available"}}
                    placeholder="Select number of days"
                    onChange={val => this.changeFieldValue(i, 0, 'days', val)}
                    value={inputtedWeeklyUserData[i][0].days.toString()}
                  >
                    <Option value="1">1 day</Option>
                    <Option value="2">2 days</Option>
                    <Option value="3">3 days</Option>
                    <Option value="4">4 days</Option>
                    <Option value="5">5 days</Option>
                    <Option value="6">6 days</Option>
                    <Option value="7">7 days</Option>
                  </Select>
                </Col>
                <Col>
                  <Input
                    style={{height: 35}}
                    className="form-control-alternative"
                    value={inputtedWeeklyUserData[i][1].days + " days"}
                    disabled
                  />
                </Col>
              </Row>
              :
              <Row className="my-2">
                <Col className="text-center">
                  <Badge size="default" count={"Low"} style={{backgroundColor: '#d8b66d'}}/>
                </Col>
                <Col className="text-center">
                  <Badge size="default" count={"High"} style={{backgroundColor: '#d8b66d'}}/>
                </Col>
              </Row>
            }
            <Row>
              <Col lg="2">
                <label className="form-control-label">
                  Carbs</label>
              </Col>
              <Col>
                <Input
                  style={{height: 30}}
                  className="form-control-alternative"
                  type="number"
                  onWheel={ event => event.currentTarget.blur() }
                  value={inputtedWeeklyUserData[i][0].carbs}
                  onChange={e => this.changeFieldValue(i, 0, 'carbs', e.target.value)}
                />
              </Col>
              <Col>
                <Input
                  disabled={inputtedWeeklyUserData[i][0].days.toString() === '7'}
                  style={{height: 30}}
                  className="form-control-alternative"
                  type="number"
                  onWheel={ event => event.currentTarget.blur() }
                  value={inputtedWeeklyUserData[i][1].carbs}
                  onChange={e => this.changeFieldValue(i, 1, 'carbs', e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="2">
                <label className="form-control-label">
                  Fat</label>
              </Col>
              <Col>
                <Input
                  style={{height: 30}}
                  className="form-control-alternative"
                  type="number"
                  onWheel={ event => event.currentTarget.blur() }
                  value={inputtedWeeklyUserData[i][0].Fat}
                  onChange={e => this.changeFieldValue(i, 0, 'Fat', e.target.value)}
                />
              </Col>
              <Col>
                <Input
                  disabled={inputtedWeeklyUserData[i][0].days.toString() === '7'}
                  style={{height: 30}}
                  className="form-control-alternative"
                  type="number"
                  onWheel={ event => event.currentTarget.blur() }
                  value={inputtedWeeklyUserData[i][1].Fat}
                  onChange={e => this.changeFieldValue(i, 1, 'Fat', e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="2">
                <label className="form-control-label">
                  Protein</label>
              </Col>
              <Col>
                <Input
                  style={{height: 30}}
                  className="form-control-alternative"
                  type="number"
                  onWheel={ event => event.currentTarget.blur() }
                  value={inputtedWeeklyUserData[i][0].protein}
                  onChange={e => this.changeFieldValue(i, 0, 'protein', e.target.value)}
                />
              </Col>
              <Col>
                <Input
                  disabled={inputtedWeeklyUserData[i][0].days.toString() === '7'}
                  style={{height: 30}}
                  className="form-control-alternative"
                  type="number"
                  onWheel={ event => event.currentTarget.blur() }
                  value={inputtedWeeklyUserData[i][1].protein}
                  onChange={e => this.changeFieldValue(i, 1, 'protein', e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="2">
                <label className="form-control-label">
                  Total Kcal</label>
              </Col>
              <Col>
                <Input
                  style={{height: 30}}
                  className="form-control-alternative"
                  type="number"
                  onWheel={ event => event.currentTarget.blur() }
                  value={this.calculateCalories(
                    inputtedWeeklyUserData[i][0].carbs,
                    inputtedWeeklyUserData[i][0].protein,
                    inputtedWeeklyUserData[i][0].Fat)
                  }
                  disabled
                />
              </Col>
              <Col>
                <Input
                  style={{height: 30}}
                  className="form-control-alternative"
                  type="number"
                  onWheel={ event => event.currentTarget.blur() }
                  value={this.calculateCalories(
                    inputtedWeeklyUserData[i][1].carbs,
                    inputtedWeeklyUserData[i][1].protein,
                    inputtedWeeklyUserData[i][1].Fat)
                  }
                  disabled
                />
              </Col>
            </Row>
            {weeklyDataType[i] === "cycling" ?
              <Row>
                <Col lg="2">
                  <label className="form-control-label">
                    Average Total Kcal (7 days)</label>
                </Col>
                <Col>
                  <Input
                    style={{height: 30}}
                    className="form-control-alternative"
                    type="number"
                    onWheel={ event => event.currentTarget.blur() }
                    value={this.calculateAvgCalories(
                      inputtedWeeklyUserData[i][0],
                      inputtedWeeklyUserData[i][1]
                    )}
                    disabled
                  />
                </Col>
              </Row>
              : null}
          </div>
          <Row className="align-items-center" style={{textAlign: 'center'}}>
            <Col className="align-items-center">
              <Button
                disabled={!(inputtedWeeklyUserData[i][0].days >= 0 && inputtedWeeklyUserData[i][1].days >= 0)}
                type="primary" size="large"
                style={{alignItems: 'center', margin: 10}}
                loading={saveFixedDataLoading}
                onClick={() => this.saveWeeklyEstimatedData(i + 1)}
              >
                Save Data</Button>
              {/* {this.state.missingVal ?
                <span style={{ color: 'red', position: 'absolute', bottom: -20, }}>some fields are missing</span>
                : null} */}
            </Col>
          </Row>
          <h6 className="heading-small text-muted mb-2">
            User Values
          </h6>
          <Table
            columns={dailyDataColumns}
            dataSource={formatDailyData.length ? formatDailyData[i].dailyData : []}
          />

          <h6 className="heading-small text-muted mb-2">
            Average Values
          </h6>
          <Table
            pagination={false}
            columns={this.avegareColumns}
            dataSource={formatDailyData.length ? this.makeAvegareData(formatDailyData[i].dailyData, i) : []}
          />

          <h6 className="heading-small text-muted mt-4">Weekly Comments</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="9">
                <Input
                  className="form-control-alternative"
                  id="input-username"
                  type="text"
                  size="large"
                  disabled={formatDailyData.length && formatDailyData[i].weeklyComment ? true : false}
                  value={this.renderCommentsValue(i)}
                  onChange={e => this.changeCommentField(i, e.target.value)}
                />
              </Col>
              <Col lg="3" style={{alignItems: 'center', alignSelf: 'center'}}>
                <Button
                  type="primary"
                  style={{alignItems: 'center'}}
                  loading={saveCommentsLoading}
                  disabled={formatDailyData.length && formatDailyData[i].weeklyComment ? true : false}
                  onClick={() => this.saveWeeklyComments(i)}
                >Send Comments</Button>
              </Col>
            </Row>
          </div>

        </Panel>
      )
    }
    return panels
  }

  makeAvegareData(data, weekNo) {
    let returnArray = [];
    let makeData = {};
    let dataLengths = {}
    data.forEach(element => {
      Object.keys(element).forEach(key => {
        if(element[key]) {
          if (makeData[key]) {
            makeData[key] += element[key];
            dataLengths[key]++;
          } else {
            makeData[key] = element[key];
            dataLengths[key] = 1;
          }
        }
      });
    });
    Object.keys(makeData).forEach(key => {
      if (key === "weight" || key === "waist") {
        makeData[key] = (makeData[key] / dataLengths[key]).toFixed(2);
      } else {
        makeData[key] = (makeData[key] / dataLengths[key]).toFixed(0);
      }
    });
    makeData['weekNumber'] = weekNo + 1;
    returnArray.push(makeData);
    return returnArray;
  }

  changeFieldValue(index, row, key, value, weekType = "") {
    let {inputtedWeeklyUserData} = this.state;
    inputtedWeeklyUserData[index][row][key] = Number(value).toFixed(0);
    if (key === 'days') {
      if (weekType === "ranges")
        inputtedWeeklyUserData[index][(row + 1)][key] = 0;
      else
        inputtedWeeklyUserData[index][(row + 1)][key] = (7 - value);
    }
    this.setState({inputtedWeeklyUserData})
  }
  
  saveInputtedData(data) {
    let {inputtedWeeklyUserData, formatDailyData, weeklyDataType} = this.state;
    if (data && data.estimatedData && data.estimatedData.length) {
      data.estimatedData.forEach(element => {
        delete element.weeklyData[0]["_id"];
        delete element.weeklyData[1]["_id"];
        if(inputtedWeeklyUserData.length > (element.weekNo - 1)) {
          inputtedWeeklyUserData[(element.weekNo - 1)] = element.weeklyData;
          formatDailyData[(element.weekNo - 1)].weeklyComment = element.weeklyComment;
          weeklyDataType[(element.weekNo - 1)] = element.dataInputType ? element.dataInputType : "cycling";
        }
      });
    }
    this.setState({inputtedWeeklyUserData, userFixedData: data, formatDailyData, weeklyDataType});
  }

  changeCollapse(key) {
  }

  formatDailyDataToWeekly(data) {
    let {selectedUser, formatDailyData} = this.state;
    let foundCurrWeek = false;

    if (selectedUser.profile && selectedUser.profile.planLength) {

      for (let i = 0; i < selectedUser.profile.planLength; i++) {
        formatDailyData.push({weekNumber: i + 1, dailyData: []});
      }
      if (data.dailyData) {
        formatDailyData.forEach(element => {
          let weekStart = moment.utc(selectedUser.profile.startDate).add((element.weekNumber - 1) * 7, 'days');
          let weekEnd = moment.utc(selectedUser.profile.startDate).add((element.weekNumber * 7) - 1, 'days');
          let startD = moment.utc(weekStart).format('YYYY-MM-DD');
          let endD = moment.utc(weekEnd).format('YYYY-MM-DD');

          let currentDate = moment.utc().format('YYYY-MM-DD');
          if (currentDate >= startD && currentDate <= endD) {
            element.currentWeek = true;
            foundCurrWeek = true;
          } else if (!foundCurrWeek) {
            element.previousWeek = true;
          }

          data.dailyData.forEach(item => {
            let itemsDate = moment.utc(item.date).format('YYYY-MM-DD');
            if (itemsDate >= startD && itemsDate <= endD) {
              element.dailyData.push(item);
            }
          });
        });
      }
      this.setState({formatDailyData})

      let removeEmpty = [];
      formatDailyData.forEach(element => {
        if (element.dailyData.length) {
          removeEmpty.push(element);
        }
      });
      this.setState({formatDailyData, weeklyTablesData: removeEmpty})
    }
  }

  showWeight(value) {
    return value + ' pounds || ' + (value / 2.2046).toFixed(2) + ' kg';
  }

  showHeight(value) {
    return value + ' inch || ' + (value * 2.54).toFixed(2) + ' cm';
  }

  render() {
    const {selectedUser, formatDailyData, weeklyTablesData} = this.state;
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"/>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>

          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="profile-card">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">{selectedUser.name}'s Profile</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col><h6 className="heading-small text-muted mb-4">Name: </h6></Col>
                    <Col><p>{selectedUser.name}</p></Col>
                  </Row>
                  <Row>
                    <Col><h6 className="heading-small text-muted mb-4">Email: </h6></Col>
                    <Col><p>{selectedUser.email}</p></Col>
                  </Row>
                  <Row>
                    <Col><h6 className="heading-small text-muted mb-4">Active Status: </h6></Col>
                    <Col><p>{selectedUser.isActive ? 'Active' : 'Inactive'}</p></Col>
                  </Row>
                  <Row>
                    <Col><h6 className="heading-small text-muted mb-4">Date of Birth: </h6></Col>
                    <Col>
                      <p>{selectedUser.profile && selectedUser.profile.dob ? moment.utc(selectedUser.profile.dob).format('MMMM DD, YYYY') : 'Profile Not Complete'}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col><h6 className="heading-small text-muted mb-4">Height: </h6></Col>
                    <Col>
                      <p>{selectedUser.profile && selectedUser.profile.height ? this.showHeight(selectedUser.profile.height) : 'Profile Not Complete'}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col><h6 className="heading-small text-muted mb-4">Weight: </h6></Col>
                    <Col>
                      <p>{selectedUser.profile && selectedUser.profile.weight ? this.showWeight(selectedUser.profile.weight) : 'Profile Not Complete'}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col><h6 className="heading-small text-muted mb-4">Plan Length: </h6></Col>
                    <Col>
                      <p>{selectedUser.profile && selectedUser.profile.planLength ? selectedUser.profile.planLength + ' Weeks' : 'Profile Not Complete'}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col><h6 className="heading-small text-muted mb-4">startDate: </h6></Col>
                    <Col>
                      <p>{selectedUser.profile && selectedUser.profile.startDate ? moment.utc(selectedUser.profile.startDate).format('MMM DD, YYYY') : 'Profile Not Complete'}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <Button
                        type="primary" size="large"
                        style={{alignItems: 'center', margin: 10}}
                        onClick={() => this.props.history.push({
                          pathname: '/admin/user-dashboard',
                          state: {selectedUser}
                        })}
                      >View User Dashboard</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">{selectedUser.name}'s Data</h3>
                    </div>
                  </Row>
                </CardHeader>
                {selectedUser.profile && selectedUser.profile.planLength ?
                  <>
                    <Collapse onChange={this.changeCollapse}>
                      {this.renderWeeklyPanels(selectedUser.profile.planLength)}
                    </Collapse>
                    <Row style={{marginTop: 20}}>
                      <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                          <CardHeader className="border-0">
                            <Row className="align-items-center">
                              <div className="col">
                                <h3 className="mb-0">Average Data</h3>
                              </div>
                            </Row>
                          </CardHeader>
                          <Table
                            columns={this.weeklyAvegareColumns}
                            dataSource={weeklyTablesData}
                            rowKey={record => record.weekNumber}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </>
                  :
                  <p>user's plan duration is not defined yet</p>
                }
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Gallery gallery={selectedUser.profile.gallery}/>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(UserDetails);
