import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "../auth/styles.css";
import axios from "axios";
import {Button, Select} from 'antd';
// reactstrap components
import {Card, CardBody, Col, Form, FormGroup} from "reactstrap";
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const {Option} = Select;
const client = StreamChat.getInstance(GETSTREAM_KEY);

class AssignCoach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCoach: "",
      errorResponse: ""
    };
  }

  componentDidMount() {
    client.connectUser(
      {
        id: this.props.user.token.id,
        name: this.props.user.token.name,
      },
      this.props.user.user.chatToken,
    );
  }

  createChannel = async (userId, coachId, name) => {
    const filter = {id: {$eq: `${userId}`}};
    const channels = await client.queryChannels(filter);
    if (!channels || channels.length === 0) {
      const channel = client.channel('messaging', userId, {
        members: [userId, coachId],
        name: name,
      });
      const response = await channel.create();
      if (channel && this.props.defaultMessages) {
        for(const message of this.props.defaultMessages) {
          const reply = await channel.sendMessage({text: message.message});
        }
      }
    } else {
      let oldCoach = Object.keys(channels[0].state.members).find(id => id !== userId)
      if(oldCoach) {
        await channels[0].removeMembers([oldCoach]);
      }
      const response = await channels[0].addMembers([coachId]);
      await channels[0].update({name: name})
    }
  }
  addCoach = () => {
    const {selectedCoach} = this.state
    const {userData} = this.props;
    axios.put(`${BASE_URL}/api/admin-users/${userData._id}/update-coach`, {coach: selectedCoach})
      .then(async res => {
      })
      .catch(err => {
        console.log(err)
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        // this.setState({ errors: err.response.data });
      });
  }
  onSubmit() {
    const {selectedCoach} = this.state
    const {userData, trainers} = this.props;
    let coachData = trainers.find(o => o.email === selectedCoach);
    if (selectedCoach) {
      const userProfile = {
        coach: selectedCoach,
        userId: userData._id
      };
      this.addCoach()
      if (!userData.profile) {
        axios.post(`${BASE_URL}/api/user-profile/`, userProfile)
          .then(async res => {
            await this.createChannel(userData._id, coachData._id, `${userData.name} --- ${coachData.name}`)
            this.props.closeModal();
          })
          .catch(err => {
            this.setState({errorResponse: "Error assigning coach. Try again"});
          });
      } else {
        axios.put(`${BASE_URL}/api/user-profile/${userData.profile._id}`, userProfile)
          .then(async res => {
            await this.createChannel(userData._id, coachData._id, `${userData.name} --- ${coachData.name}`)
            this.props.closeModal();
          })
          .catch(err => {
            // dispatch({ type: GET_ERRORS, payload: err.response.data })
            this.setState({errorResponse: "Error assigning coach. Try again"});
          });
      }
    } else {
      this.setState({errorResponse: "Select a Coache from dropdown list"});
    }

  };

  onSelectDropdown = (value) => {
    this.setState({selectedCoach: value});
  }

  render() {
    const {selectedCoach} = this.state
    const {userData, trainers} = this.props;

    return (
      <>
        <Col lg="12" md="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-2 py-lg-2">
              <Form role="form">
                <FormGroup>
                  <Select
                    size="large"
                    placeholder="Select a Coach"
                    onChange={this.onSelectDropdown}

                  >
                    {trainers.map((item, index) => {
                      return (
                        <Option key={item._id} value={item.email}>{item.name} {"<"}{item.email}{">"}</Option>
                      );
                    })}
                  </Select>

                </FormGroup>
                <div className="text-center">
                  <Button className="mt-4" color="primary" onClick={() => {
                    this.onSubmit()
                  }}>
                    Update</Button>
                </div>
                <div className="text-center">
                  <span style={{color: 'red'}}>{this.state.errorResponse}</span>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(AssignCoach));
