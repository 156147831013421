import React, {Component} from "react";
import Chart from "chart.js";
import {HorizontalBar, Line} from "react-chartjs-2";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";

import {chartExample1, chartOptions, parseOptions} from "variables/charts.jsx";

import AdminDashBoardHeader from "../components/Headers/AdminDashBoardHeader";
import CoachDashBoardHeader from "../components/Headers/CoachDashBoardHeader";
import moment from "moment";
import axios from "axios";
import {BASE_URL} from "../constants";


class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      userProfile: {},
      dailyFitnessData: {},
      currentWeek: 0,
      currentWeight: 0,
      currentWaist: 0,
      lastEntryDate: null,
      activeNav: 1,
      lineChartDataIndex: "carbos",
      lineChartData: {
        carbos: {},
        fats: {},
        protein: {}
      },
      barChartData: {
        labels: [],
        datasets: [{ data: [] }]
      },
      signupStatsData: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [{ data: [] }]
      },
      AdminPieData: {
        labels: ['4 Weeks', '13 Weeks', '16 Weeks', '1 year'],
        datasets: [{
          data: [0, 0, 0],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
        }]
      },
      totalClients: 0,
      inActiveClients: 0,
      totalCoaches: 0,
      signedUpCurrentMonth: 0,
      coachGraphWeightData: {
        labels: ["100 - 120(Pounds)", "121 - 140(Pounds)", "141 - 160(Pounds)", "161 - 180(Pounds)", "181 - 200(Pounds)", "above from range"],
        datasets: [{ data: [0, 0, 0, 0, 0, 0] }]
      },
      CoachClients: 0,
      coachActiveClients: 0,
      CoachPieData: {
        labels: ['4 Weeks', '13 Weeks', '26 Weeks', '1 year'],
        datasets: [{
          data: [0, 0, 0, 0],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8898aa'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8898aa']
        }]
      },
      coachGraphRemainingWeeks: {
        labels: [],
        datasets: [{ data: [] }]
      },
      allUsers: {
        users: [],
        userProfiles: []
      }
    }
  }

  async componentWillMount() {

    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }


    let { userData } = this.state;
    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
      this.setState({ userData })
    }
    await this.getAllUsers();
    await this.getUserDailyData(userData._id);
  }

  async getAllUsers() {
    this.setState({ loadingUsers: true })
    await axios.get(`${BASE_URL}/api/admin-users/all`)
      .then(res => {
        if (res.data.users && res.data.users.length) {
          this.makeLineChartsData(res.data.users, res.data.userProfiles);
          this.makeBarChartData(res.data.users, res.data.userProfiles);
          this.makeFourBoxesStats(res.data.users, res.data.userProfiles);
          this.setState({ allUsers: res.data });
        }
      })
      .catch(err => {
        this.setState({ loadingUsers: false, });
      });
  };

  async getUserDailyData() {
    this.setState({ dailyDataLoading: true })

    await axios.get(`${BASE_URL}/api/fitness-data/`)
      .then(res => {
        if (res.data && res.data) {
          this.filterFitnessData(res.data);
          this.setState({ dailyFitnessData: res.data });
        }
        this.setState({ dailyDataLoading: false });
      })
      .catch(err => {
        this.setState({ dailyDataLoading: false, });
      });

  };

  filterFitnessData(fitnessData) {
    let { userData, allUsers, coachGraphWeightData } = this.state;
    let coachUsers = allUsers.userProfiles.filter(e => e.coach === userData.email);
    let filteredUsersData = [];
    fitnessData.forEach(element => {
      let ind = coachUsers.findIndex(e => e.userId === element.userId);
      if (ind !== -1) {
        filteredUsersData.push(element);
      }
    });

    let dummyData = {
      labels: [],
      datasets: [{ data: [0, 0, 0, 0, 0, 0] }]
    }

    if (filteredUsersData.length) {
      filteredUsersData.forEach(element => {
        let weight = 0;

        if (element.dailyData.length) {
          for (let i = (element.dailyData.length - 1); i >= 0; i--) {

            if (element.dailyData[i].weight) {
              weight = element.dailyData[i].weight;
              if (weight >= 100 && weight <= 120) { dummyData.datasets[0].data[0] += 1 }
              if (weight >= 121 && weight <= 140) { dummyData.datasets[0].data[1] += 1 }
              if (weight >= 141 && weight <= 160) { dummyData.datasets[0].data[2] += 1 }
              if (weight >= 161 && weight <= 180) { dummyData.datasets[0].data[3] += 1 }
              if (weight >= 181 && weight <= 200) { dummyData.datasets[0].data[4] += 1 }
              else { dummyData.datasets[0].data[5] += 1 }
              break;
            }
          }
        }

      });
    }
    coachGraphWeightData.datasets[0].data = dummyData.datasets[0].data;
    this.setState({ coachGraphWeightData })

  }

  async makeFourBoxesStats(users, profiles) {
    let { totalClients, inActiveClients, signedUpCurrentMonth, totalCoaches, AdminPieData, userData,
      CoachClients, coachActiveClients, CoachPieData } = this.state;

    let dummyData = {
      labels: [],
      datasets: [{ data: [0, 0, 0, 0] }]
    }

    if (userData.role === 'admin') {
      users.forEach(element => {
        if (element.role === "user" && element.isActive) {
          totalClients++;
        } else if (element.role === "user" && !element.isActive) {
          inActiveClients++;
        }
        if (element.role === "trainer") {
          totalCoaches++;
        }

        let today = new Date();
        if (moment.utc(today).format('M-YYYY') === moment.utc(element.createdAt).format('M-YYYY') && element.isActive) {
          signedUpCurrentMonth++;
        }
      });

      profiles.forEach(element => {
        let usrInd = users.findIndex(e => e._id === element.userId);
        if (usrInd >= 0 && users[usrInd].isActive) {
          if (element.actualPlanLength === 4) { dummyData.datasets[0].data[0] += 1 }
          else if (element.actualPlanLength === 13) { dummyData.datasets[0].data[1] += 1 }
          else if (element.actualPlanLength === 26) { dummyData.datasets[0].data[2] += 1 }
          else if (element.actualPlanLength === 52) { dummyData.datasets[0].data[3] += 1 }
        }
      });

      AdminPieData.datasets[0].data = dummyData.datasets[0].data;
      this.setState({ totalClients, inActiveClients, totalCoaches, signedUpCurrentMonth, AdminPieData });
    }

    if (userData.role === 'trainer') {
      let coachCounts = profiles.filter(e => e.coach === userData.email);
      let activeClients = await this.checkExpiredUser(coachCounts);

      activeClients.forEach(element => {
        // let userIndex = users.findIndex(user => user._id === element.userId);
        // if (userIndex !== -1 && users[userIndex].isActive) {
        //   coachActiveClients++;
        // }
        if ("expired" in element && !element.expired) {
          if (element.actualPlanLength === 4) { dummyData.datasets[0].data[0] += 1 }
          else if (element.actualPlanLength === 13) { dummyData.datasets[0].data[1] += 1 }
          else if (element.actualPlanLength === 26) { dummyData.datasets[0].data[2] += 1 }
          else if (element.actualPlanLength === 52) { dummyData.datasets[0].data[3] += 1 }
        }
      });

      CoachPieData.datasets[0].data = dummyData.datasets[0].data;
      this.setState({ CoachPieData });
    }

  }

  checkExpiredUser(users) {
    let { CoachClients, coachActiveClients } = this.state;
    let currentDate = moment.utc(new Date()).format('YYYY-MM-DD');

    users.forEach(selectedUser => {

      if (selectedUser.planLength && selectedUser.startDate) {

        for (let i = 1; i <= selectedUser.planLength; i++) {
          let weekStart = moment.utc(selectedUser.startDate).add((i - 1) * 7, 'days');
          let weekEnd = moment.utc(selectedUser.startDate).add((i * 7) - 1, 'days');

          if (currentDate >= moment.utc(weekStart).format("YYYY-MM-DD") && currentDate <= moment.utc(weekEnd).format("YYYY-MM-DD")) {
            selectedUser.expired = 0;
            CoachClients += 1;
            break;
          } else if (currentDate < moment.utc(weekStart).format("YYYY-MM-DD")) {
            selectedUser.expired = 0;
            CoachClients += 1;
            break
          }
        }

      }
    });
    this.setState({ CoachClients, })
    return users;
  }

  makeLineChartsData(users, profiles) {
    let { signupStatsData } = this.state;

    let dummyData = {
      labels: [],
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }]
    }

    users.forEach(element => {
      let today = new Date();

      if (moment.utc(today).format('YYYY') === moment.utc(element.createdAt).format('YYYY')) {
        let month = moment.utc(element.createdAt).format('M');
        dummyData.datasets[0].data[(month - 1)] += 1;
      }

    });
    signupStatsData.datasets[0].data = dummyData.datasets[0].data;
    this.setState({ signupStatsData })
  }

  async makeBarChartData(users, profiles) {
    let { barChartData, userData, coachGraphRemainingWeeks } = this.state;
    let dummyData = {
      labels: [],
      datasets: [{ data: [] }]
    }

    if (userData.role === 'admin') {
      profiles.forEach(element => {
        let index = dummyData.labels.findIndex(e => e === element.coach);
        if (index === -1 && element.coach) {
          dummyData.labels.push(element.coach);
        }
      });

      dummyData.labels.forEach(element => {
        let countedArray = profiles.filter(e => {
          if (e.coach === element) {
            let ind = users.findIndex(elm => elm._id === e.userId && elm.isActive)
            return ind < 0 ? false : true
          } else {
            return false
          }
        });

        dummyData.datasets[0].data.push(countedArray.length);
      });

      barChartData.labels = dummyData.labels;
      barChartData.datasets[0].data = dummyData.datasets[0].data;
      this.setState({ barChartData })
    }

    if (userData.role === 'trainer') {

      let currentDate = moment.utc(new Date()).format('YYYY-MM-DD');
      let CoachClients = profiles.filter(e => e.coach === userData.email);

      CoachClients.forEach(selectedUser => {
        if (selectedUser && selectedUser.planLength && selectedUser.startDate) {

          for (let i = 1; i <= selectedUser.planLength; i++) {
            let weekStart = moment.utc(selectedUser.startDate).add((i - 1) * 7, 'days');
            let weekEnd = moment.utc(selectedUser.startDate).add((i * 7) - 1, 'days');

            if (currentDate >= moment.utc(weekStart).format("YYYY-MM-DD") && currentDate <= moment.utc(weekEnd).format("YYYY-MM-DD")) {
              selectedUser.weeksRemaining = (selectedUser.planLength - i);
              let ind = users.findIndex(elm => elm._id === selectedUser.userId)
              if (ind !== -1) {
                dummyData.labels.push(users[ind].email);
                dummyData.datasets[0].data.push((selectedUser.planLength - i));
              }
              break;
            } else if (currentDate < moment.utc(weekStart).format("YYYY-MM-DD")) {
              selectedUser.weeksRemaining = selectedUser.planLength;
              let ind = users.findIndex(elm => elm._id === selectedUser.userId)
              if (ind !== -1) {
                dummyData.labels.push(users[ind].email);
                dummyData.datasets[0].data.push(selectedUser.planLength);
              }
              break;
            }
          }

        }
      });

      coachGraphRemainingWeeks.datasets[0].data = dummyData.datasets[0].data;
      coachGraphRemainingWeeks.labels = dummyData.labels;
      this.setState({ coachGraphRemainingWeeks });
    }

  }

  toggleNavs = (e, index) => {
    let chartDataKey = "carbos";
    if (index === 1) { chartDataKey = "carbos" }
    else if (index === 2) { chartDataKey = "fats"; }
    else if (index === 3) { chartDataKey = "protein"; }
    e.preventDefault();
    this.setState({
      activeNav: index,
      lineChartDataIndex: chartDataKey
    });
    let wow = () => {
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  render() {
    const { totalClients, inActiveClients, totalCoaches, signedUpCurrentMonth, signupStatsData, barChartData, AdminPieData, userData,
      coachGraphWeightData, CoachClients, coachActiveClients, CoachPieData, coachGraphRemainingWeeks } = this.state;
    if (userData.role === 'admin') {
      return (
        <>
          <AdminDashBoardHeader
            totalClients={totalClients}
            inActiveClients={inActiveClients}
            totalCoaches={totalCoaches}
            signedUpCurrentMonth={signedUpCurrentMonth}
          />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="bg-gradient-default shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="text-white mb-0">Signup Stats</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Line
                        data={signupStatsData}
                        options={chartExample1.options}
                        getDatasetAtEvent={e => {
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0">Number of Users per Coach</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div className="chart">
                      <HorizontalBar
                        data={barChartData}
                        // options={chartExample2.options}
                        getDatasetAtEvent={e =>{}}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0">Active Clients as Plan Lengths</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <HorizontalBar
                        data={AdminPieData}
                        // options={chartExample2.options}
                        getDatasetAtEvent={e => {}}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return (<>
        <CoachDashBoardHeader
          totalClients={CoachClients}
          coachActiveClients={coachActiveClients}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col xl="12">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0">Remaining Weeks of Clients</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <HorizontalBar
                      data={coachGraphRemainingWeeks}
                      // options={chartExample2.options}
                      getDatasetAtEvent={e =>{}}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xl="12">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0">Clients as Plan Lengths</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <HorizontalBar
                      data={CoachPieData}
                      // options={chartExample2.options}
                      getDatasetAtEvent={e => {}}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>);
    }
  }
}

export default AdminDashboard;
