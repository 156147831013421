import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "../auth/styles.css";
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);

class AddUserItem extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      errors: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({[e.target.id]: e.target.value});
  };

  onSubmit = e => {
    e.preventDefault();
    const {name, email, password} = this.state

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      role: this.props.userRole,
      isActive: true
    };

    // this.props.register(newUser, this.props.history);
    axios
      .post(`${BASE_URL}/api/admin-users/`, newUser)
      .then(res => {
        toast.info(`Invitation email sent`)
        this.props.closeModal();
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        toast.error(err.response.data)
        this.setState({errors: err.response.data});
      });
  };

  render() {
    const {errors} = this.state;
    return (
      <>
        <Col lg="12" md="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-2 py-lg-2">
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.name}
                      error={errors.name}
                      id="name"
                      type="text"
                      placeholder="Name"/>
                  </InputGroup>
                  <div className="auth-error">{errors.name}</div>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.email}
                      error={errors.email}
                      id="email"
                      type="email"
                      placeholder="Email"/>
                  </InputGroup>
                  <div className="auth-error">{errors.email}</div>
                </FormGroup>
                {/*<FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      type="password"
                      placeholder="Password" />
                  </InputGroup>
                  <div className="auth-error">{errors.password}</div>
                </FormGroup>*/}
                <div className="text-center">
                  <Button type="submit" className="mt-4" color="primary"
                  >
                    Invite user</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <ToastContainer position="top-center"/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(AddUserItem));
