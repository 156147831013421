import React from "react";
import {NavLink as NavLinkRRD, Link, withRouter} from "react-router-dom";
// nodejs library to set properties for components
import {PropTypes} from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import {logoutUser} from "../../actions/authActions.js";
import {connect} from "react-redux";
import {StreamChat} from "stream-chat";
import {Badge} from "antd";
import {GETSTREAM_KEY} from "../../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    unreadCount: 0
  };

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  connectUserChat = async () => {
    const user = await client.connectUser(
      {
        id: this.props.user.token.id,
        name: this.props.user.token.name,
      },
      this.props.user.user.chatToken,
    );
    this.setState({unreadCount: user.me.total_unread_count})
  }

  componentDidMount() {
    this.connectUserChat()
  }

  onLogoutClick = e => {
    this.props.logoutUser(this.props.history);
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon}/>
            {(prop.name !== 'Messages' || this.state.unreadCount === 0) && <>{prop.name}</>}
            {prop.name === 'Messages' && this.state.unreadCount > 0 &&
            <Badge count={this.state.unreadCount} offset={[20, 2]}>
              {prop.name}
            </Badge>}
          </NavLink>
        </NavItem>
      );
    });
  };

  render() {
    const {bgColor, routes, logo} = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon"/>
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02"/>
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem onClick={this.onLogoutClick}>
                  <i className="ni ni-user-run"/>
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc}/>
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc}/>
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span/>
                    <span/>
                  </button>
                </Col>
              </Row>
            </div>

            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}
              <li className="nav-item">
                <a className="nav-link" href="javascript: void(0);">
                  <img
                    alt={"Client Hub"}
                    className="navbar-brand-img ds-none"
                    src={require('../../assets/img/brand/client-hub.png')}
                  />
                </a>
              </li>
            </Nav>
          </Collapse>

          <img
            alt={"Client Hub"}
            className="navbar-brand-img mb-none"
            src={require('../../assets/img/brand/client-hub.png')}
          />
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user
});
export default connect(
  mapStateToProps,
  {logoutUser},
)(withRouter(Sidebar));
