import React from "react";
import { Alert } from 'antd';

class TopAlert extends React.Component {
  render() {
    const { alertType, alertMessage, alertDesc } = this.props;
    return (
      <>
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          <Alert
            message={alertMessage}
            description={alertDesc}
            type={alertType}
            closable
            showIcon
          />
        </div>
      </>
    );
  }
}

export default TopAlert;
