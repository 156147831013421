import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../actions/authActions";
import "./styles.css";
import axios from "axios";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import TopAlert from "../../components/Common/Alert.js"
import {Button} from 'antd';
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      unActivated: false,
      loadingButton: false
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.userOTP && Object.keys(this.props.auth.userOTP).length > 0) {
      this.props.history.push("/auth/verify");
    } else if (this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({[e.target.id]: e.target.value});
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({loadingButton: true})

    const newUser = {
      email: this.state.email,
      password: this.state.password
    };

    axios.post(`${BASE_URL}/api/users/login`, newUser)
      .then(async res => {
        if (res.data.user.isActive) {
          const user = res.data.user
          await client.connectUser(
            {
              id: user._id,
              name: user.name,
            },
            res.data.chatToken,
          );
          this.props.loginUser(res.data)
          if (res.data.user.role === 'user') {
            this.props.history.push("/admin/user-profile");
          } else if (res.data.user.role === 'admin') {
            this.props.history.push("/admin/users");
          } else if (res.data.user.role === "trainer") {
            this.props.history.push("/admin/index");
          }
        } else {
          this.setState({unActivated: true})
        }
        this.setState({loadingButton: false})
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        this.setState({errors: err.response ? err.response.data : err, loadingButton: false});
      });

    // this.props.loginUser(newUser);

  };

  render() {
    const {state} = this.props.location;
    const {errors, loadingButton} = this.state;
    return (
      <>
        {state && state.fromRegister ?
          <TopAlert
            alertType="info"
            alertMessage="Successful Signup"
            alertDesc="your details are send to the admin for activation"
          />
          : null}
        {this.state.unActivated ?
          <TopAlert
            alertType="info"
            alertMessage="UnActivated"
            alertDesc="your account is not activated yet."
          />
          : null}
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-2">
                {/* <small>Sign in with</small> */}
                <img style={{width: '50%'}} alt="..." src={require("../../assets/img/brand/client-hub.png")}/>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>sign in with credentials</small>
              </div>
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.email}
                      error={errors.email}
                      id="email"
                      placeholder="Email" type="email"/>
                  </InputGroup>
                  <div className="auth-error">{errors.email}</div>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      placeholder="Password"
                      type={!this.state.showPassword ? "password" : 'text'}
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i
                          className={this.state.showPassword ? "fa fa-eye-slash" : 'fa fa-eye'}
                          onClick={() => this.setState({showPassword: !this.state.showPassword})}/>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="auth-error">{errors.password}</div>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button loading={loadingButton} type="submit" className="my-4" type="primary" size="large"
                          onClick={this.onSubmit}>
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/forgot-password"
                style={{fontSize: 18}}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
                style={{fontSize: 18}}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (values, history) => dispatch(actions.loginUser(values, history))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Login));
