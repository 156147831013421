import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../actions/authActions";
import "./styles.css";
import axios from "axios";
import queryString from 'query-string'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import {message, Button} from 'antd';
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);

class Register extends React.Component {
  constructor(props) {
    super(props);
    const params = queryString.parse(props.location.search);
    this.state = {
      name: "",
      email: params && params.email ? params.email : "",
      password: "",
      showPassword: false,
      errors: {},
      loadingButton: false,
      agreed: false
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({[e.target.id]: e.target.value});
  };

  onSubmit = e => {
    if (!this.state.agreed) {
      this.setState({errors: {agreed: 'Please agree to Terms & Conditions'}})
      return false
    }
    e.preventDefault();
    this.setState({loadingButton: true})

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password
    };

    // this.props.register(newUser, this.props.history);
    axios
      .post(`${BASE_URL}/api/users/register`, newUser)
      .then(async res => {
        this.props.setOTP({token: res.data.confirmationToken, id: res.data._id})
        await client.connectUser(
          {
            id: res.data._id,
            name: res.data.name,
          },
          res.data.chatToken,
        );
        this.props.history.push("/auth/verify", {fromRegister: true})
        this.setState({loadingButton: false})
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        this.setState({errors: err.response.data, loadingButton: false});
      });

  };

  render() {
    const {errors, loadingButton} = this.state;
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-2">
                <img style={{width: '50%'}} alt="..." src={require("../../assets/img/brand/client-hub.png")}/>
              </div>
              {/* <div className="text-center">
                <Button
                  className="btn-neutral btn-icon mr-4"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
             */}
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>sign up with credentials</small>
              </div>
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.name}
                      error={errors.name}
                      id="name"
                      type="text"
                      placeholder="Name"/>
                  </InputGroup>
                  <div className="auth-error">{errors.name}</div>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.email}
                      error={errors.email}
                      id="email"
                      type="email"
                      placeholder="Email"/>
                  </InputGroup>
                  <div className="auth-error">{errors.email}</div>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      type={!this.state.showPassword ? "password" : 'text'}
                      placeholder="Password"/>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i
                          className={this.state.showPassword ? "fa fa-eye-slash" : 'fa fa-eye'}
                          onClick={() => this.setState({showPassword: !this.state.showPassword})}/>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="auth-error">{errors.password}</div>
                </FormGroup>
                {/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                        checked={this.state.agreed}
                        onChange={(e) => this.setState({agreed: e.target.checked, errors: {}})}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree to the {" "}
                          <a
                            target={'_blank'}
                            href="https://www.pltnutrition.com/terms.html">
                            Terms & Conditions
                          </a>
                          {' '}&{' '}
                          <a
                            target={'_blank'}
                            href="https://www.pltnutrition.com/privacy-policy.html">
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                    <div className="auth-error">{errors.agreed}</div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button loading={loadingButton} type="submit" className="my-4" type="primary" size="large"
                          onClick={this.onSubmit}>
                    Create Account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/forgot-password"
                style={{fontSize: 18}}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/login"
                style={{fontSize: 18}}
              >
                <small>Already have an account?</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

const mapDispatchToProps = dispatch => {
  return {
    register: (values, history) => dispatch(actions.registerUser(values, history)),
    setOTP: (otp) => dispatch(actions.setOTP(otp))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Register));
