import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../actions/authActions";
import "./styles.css";
import axios from "axios";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import TopAlert from "../../components/Common/Alert.js"
import {Button, message} from 'antd';
import {StreamChat} from "stream-chat";
import ReactCodeInput from "react-code-input";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";
import {updateProfile} from "../../actions/authActions";

const client = StreamChat.getInstance(GETSTREAM_KEY);

class Verify extends React.Component {
  constructor() {
    super();
    this.state = {
      code: '',
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({[e.target.id]: e.target.value});
  };

  onResend = e => {
    e.preventDefault();
    this.setState({loadingButton: true})
    const otp = this.props.auth.userOTP
    // this.props.register(newUser, this.props.history);
    axios
      .post(`${BASE_URL}/api/users/resendOTP`, {id: otp.id})
      .then(async res => {
        this.props.setOTP({token: res.data.confirmationToken, id: res.data._id})
        this.setState({loadingButton: false})
        message.success('Verification code resent on email ');
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        this.setState({errors: err.response.data, loadingButton: false});
      });

  };

  onSubmit = e => {
    e.preventDefault();
    //
    const otp = this.props.auth.userOTP
    if (this.props.auth.userOTP.token !== this.state.code) {
      this.setState({error: true})
      return
    } else {
      this.setState({loadingButton: true})
      axios.put(`${BASE_URL}/api/admin-users/${otp.id}`, {
        confirmedAt: Date.now(),
        isActive: true
      })
        .then(async res => {
          this.props.setOTP({})
          this.props.history.push("/auth/login")
        })
        .catch(err => {
          this.setState({loadingButton: false})
        })
    }

  };

  render() {
    // console.log("this.props.location.state", this.props.location);
    const {state} = this.props.location;
    const {errors, loadingButton} = this.state;
    return (
      <>
        {state && state.fromRegister ?
          <TopAlert
            alertType="info"
            alertMessage="Successful Signup"
            alertDesc="your details are send to the admin for activation"
          />
          : null}
        {this.state.unActivated ?
          <TopAlert
            alertType="info"
            alertMessage="UnActivated"
            alertDesc="your account is not activated yet."
          />
          : null}
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-2">
                {/* <small>Sign in with</small> */}
                <img style={{width: '50%'}} alt="..." src={require("../../assets/img/brand/nutrition-fitness.png")}/>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>We have emailed you a verification code, please enter the code to proceed</small>
              </div>
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup className="mb-3">
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <ReactCodeInput
                      value={this.state.code}
                      style={{textAlign: 'center', display: 'inherit'}}
                      onChange={(code) => {
                        this.setState({code})
                      }}
                      type='number'
                      isValid={!this.state.error}
                      fields={6}/>
                  </div>
                </FormGroup>
                <div className="text-center">
                  <Button
                    loading={loadingButton}
                    className="my-4"
                    type="primary"
                    size="large"
                    style={{width: 150}}
                    onClick={this.onSubmit}>
                    Verify
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Button
                loading={loadingButton}
                onClick={this.onResend}
                style={{color: '#fff'}} type={'link'}>{'Resend code'}</Button>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
                style={{fontSize: 18}}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (userId) => dispatch(updateProfile(userId)),
    setOTP: (otp) => dispatch(actions.setOTP(otp))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Verify));
