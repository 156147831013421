import React from "react";
import {Link, withRouter} from "react-router-dom";
// reactstrap components
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown
} from "reactstrap";
import {logoutUser} from "../../actions/authActions.js";
import {connect} from "react-redux";

class AdminNavbar extends React.Component {

  onLogoutClick = e => {
    this.props.logoutUser(this.props.history);
  };

  render() {
    const brand = this.props.brandText;
    let userData = {};
    let userProfile = this.props.userProfile ? this.props.userProfile : {};
    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
    }
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            {brand.layout ?
              <Link
                className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                to={brand.layout + brand.path}
              >
                {brand.name}
              </Link>
              :
              <span className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"/>
            }
            {/* <img src={require('../../assets/img/brand/continous-effort-black.png')} alt="Logo"
              height="70" width="200"
            /> */}
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        style={{width: 36, height: 36}}
                        src={userProfile.profilePicture ? userProfile.profilePicture : require("assets/img/theme/team-4-800x800.jpg")}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {userData ? userData.name : 'user Name'}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  {userData.role === 'user' ?
                    <DropdownItem to="/admin/user-profile" tag={Link}>
                      <i className="ni ni-single-02"/>
                      <span>My profile</span>
                    </DropdownItem>
                    : null}
                  {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem> */}
                  <DropdownItem divider/>
                  <DropdownItem onClick={this.onLogoutClick}>
                    <i className="ni ni-user-run"/>
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  userProfile: state.auth.userProfile
});
export default connect(
  mapStateToProps,
  {logoutUser},
)(withRouter(AdminNavbar));
