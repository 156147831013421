import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "../auth/styles.css";
import axios from "axios";
import {Button, Select} from 'antd';
// reactstrap components
import {Card, CardBody, Col, Form, FormGroup} from "reactstrap";
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const {Option} = Select;
const client = StreamChat.getInstance(GETSTREAM_KEY);

class AssignCoach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCoach: "",
      errorResponse: "",
      channels: []
    };
  }

  componentDidMount() {
    this.getChannels()
  }

  getChannels = async () => {
    await client.connectUser(
      {
        id: this.props.user.token.id,
        name: this.props.user.token.name,
      },
      this.props.user.user.chatToken,
    );
    const filter = {type: 'messaging'};
    const channels = await client.queryChannels(filter);
    const channelList = []
    channels.forEach((channel) => {
      const members = channel.state.members
      const memberNames = Object.keys(channel.state.members).map((key) => members[key].user.name)
      channelList.push({
        id: channel.id,
        members: members,
        name: memberNames.join(' --- ')
      })
    })
    this.setState({channels: channelList})
  }

  createChannel = async (userId, coachId, name) => {
    const filter = {id: {$eq: `${userId}`}};
    const channels = await client.queryChannels(filter);
    if (!channels || channels.length === 0) {
      const channel = client.channel('messaging', userId, {
        members: [userId, coachId],
        name: name,
      });
      const response = await channel.create();
    } else {
      let oldCoach = Object.keys(channels[0].state.members).find(id => id !== userId)
      await channels[0].removeMembers([oldCoach]);
      const response = await channels[0].addMembers([coachId]);
    }
  }

  downloadChat = async (taskId)=>{
    const response = await client.getExportChannelStatus(taskId);
  }

  onSubmit() {
    const {selectedChannel} = this.state
    const {userData, trainers} = this.props;
    if (selectedChannel) {
      axios.post(`${BASE_URL}/api/users/exportdata`, {channelId: selectedChannel})
        .then(async res => {
          //await this.createChannel(userData._id, coachData._id, `${userData.name} --- ${coachData.name}`)
          //this.downloadChat(res.data.task_id)
          this.props.closeModal(res.data.task_id);
        })
        .catch(err => {
          this.setState({errorResponse: "Error exporting channel. Try again"});
        });
    } else {
      this.setState({errorResponse: "Select a Coache from dropdown list"});
    }

  };

  onSelectDropdown = (value) => {
    this.setState({selectedChannel: value});
  }

  render() {
    const {selectedChannel, channels} = this.state
    return (
      <>
        <Col lg="12" md="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-2 py-lg-2">
              <Form role="form">
                <FormGroup>
                  <Select
                    size="large"
                    placeholder="Select channel"
                    onChange={this.onSelectDropdown}

                  >
                    {channels.map((item, index) => {
                      return (
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                      );
                    })}
                  </Select>

                </FormGroup>
                <div className="text-center">
                  <Button className="mt-4" color="primary" onClick={() => {
                    this.onSubmit()
                  }}>
                    Export</Button>
                </div>
                <div className="text-center">
                  <span style={{color: 'red'}}>{this.state.errorResponse}</span>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(AssignCoach));
