import React from "react";
import {
  // Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import axios from "axios";
import { Table, Divider, Tag, Modal, Button, Layout, Menu, Popconfirm, Input } from 'antd';
import AddUserItem from "./AddUserItem.js"
import AssignCoach from "./AssignCoach.js"
import AddDuration from "./AddDuration.js"
import {StreamChat} from "stream-chat";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);
const { Search } = Input;

class PreferedUsers extends React.Component {

  constructor() {
    super();
    this.userTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: text => <a>{text}</a>,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Access',
        key: 'role',
        dataIndex: 'role',
        render: role => {
          let color = 'gold';
          if (role === 'user') {
            color = 'geekblue';
          } else if (role === 'admin') {
            color = 'green';
          }
          return (
            <span>
              <Tag color={color} key={role}>
                {role.toUpperCase()}
              </Tag>
            </span>
          )
        },
      },
      {
        title: 'Coach',
        key: 'coach',
        render: (record) => <a>{record.profile && record.profile.coach ? record.profile.coach : "Not Assigned"}</a>,
      },
      {
        title: 'Active Status',
        key: 'isActive',
        render: (record) =>
          // record.isActive ? (
          //   <Popconfirm title="Are you sure to deactivate account?" onConfirm={() => { this.showPhotosModal(record) }}>
          //     <span>
          //       <Tag color={'green'} >
          //         <a style={{ color: 'green' }}>Active</a>
          //       </Tag>
          //     </span>
          //   </Popconfirm>
          // ) : (
          //     <Popconfirm title="Activate User Account" onConfirm={() => { this.showPhotosModal(record) }}>
          //       <span>
          //         <Tag color={'volcano'} >
          //           <a style={{ color: 'red' }}>Deactive</a>
          //         </Tag>
          //       </span>
          //     </Popconfirm>
          //   ),
          <span>
            {record.isActive ?
              <Tag color={'green'}>
                Active</Tag>
              :
              <Tag color={'volcano'}>
                Inactive</Tag>
            }
          </span>
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <div>
            <Popconfirm title="Are you sure to change user status" onConfirm={() => this.changeItemStatus(record)}>
              <span>
                <div style={{marginBottom: 5}}>
                  <Button type="primary" size="small">
                    Change Status</Button>
                </div>
              </span>
            </Popconfirm>
            <div style={{marginBottom: 5}}>
              <Button type="primary" size="small" onClick={() => this.showAddCoachModal(record)}>
                Assign Coach</Button>
            </div>
            <div>
              <Button type="primary" size="small" onClick={() => this.showDurationModal(record)}>
                Add Duration</Button>
            </div>
          </div>
        ),
      },
    ];
    this.trainerTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: text => <a>{text}</a>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Access',
        key: 'role',
        dataIndex: 'role',
        render: role => {
          let color = 'gold';
          if (role === 'user') {
            color = 'geekblue';
          } else if (role === 'admin') {
            color = 'green';
          }
          return (
            <span>
              <Tag color={color} key={role}>
                {role === "trainer" ? "COACH" : role.toUpperCase()}
              </Tag>
            </span>
          )
        },
      },
      {
        title: 'Users Assigned',
        key: 'assignedUsers',
        render: record => <span>{record.assignedUsers ? record.assignedUsers : 0}</span>,
      },
      {
        title: 'Active Status',
        key: 'isActive',
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'Inactive',
            value: false,
          }
        ],
        onFilter: (value, record) => {
          return record.isActive === value
        },
        render: (record) =>
          <span>
            {record.isActive ?
              <Tag color={'green'}>
                Active</Tag>
              :
              <Tag color={'volcano'}>{record.invited ? 'Invited' : 'Inactive'}</Tag>
            }
          </span>
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Popconfirm title="Are you sure to change user status" onConfirm={() => this.changeItemStatus(record)}>
            {!record.invited && <span>
              <Button type="primary" size="small">
                Change Status</Button>
            </span>}
          </Popconfirm>
        ),
      },
    ];
    this.adminTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: text => <a>{text}</a>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Access',
        key: 'role',
        dataIndex: 'role',
        render: role => {
          let color = 'gold';
          if (role === 'user') {
            color = 'geekblue';
          } else if (role === 'admin') {
            color = 'green';
          }
          return (
            <span>
              <Tag color={color} key={role}>
                {role.toUpperCase()}
              </Tag>
            </span>
          )
        },
      },
      {
        title: 'Active Status',
        key: 'isActive',
        render: (record) =>
          <span>
            {record.isActive ?
              <Tag color={'green'}>
                Active</Tag>
              :
              <Tag color={'volcano'}>{record.invited ? 'Invited' : 'Inactive'}</Tag>
            }
          </span>
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Popconfirm title="Are you sure to change user status" onConfirm={() => this.changeItemStatus(record)}>
            {!record.invited && <span>
              <Button type="primary" size="small">
                Change Status</Button>
            </span>}
          </Popconfirm>
        ),
      },
    ];

    this.state = {
      allUsers: [],
      loadingUsers: false,
      allTrainers: [],
      allAdmins: [],
      currentTrainers:[],
      toggleAddModal: false,
      toggleAddCoachModal: false,
      addUserRole: 'user',
      selectedUser: {},
      toggleAddDurationModal: false
    }
  }

  componentWillMount() {
    this.getAllUsers();
    this.getAllAdmins();
  }

  componentDidMount() {
    client.connectUser(
      {
        id: this.props.user.token.id,
        name: this.props.user.token.name,
      },
      this.props.user.user.chatToken,
    );
  }

  async getAllUsers() {
    this.setState({
      loadingUsers: true,
      toggleAddModal: false,
      toggleAddCoachModal: false,
      toggleAddDurationModal: false
    })
    await axios.get(`${BASE_URL}/api/admin-users/trainers`)
      .then(res => {
        let allTrainers = [];
        if (res.data) {
          if (res.data.users) {
            let dataArray = res.data.users;
            if (dataArray && dataArray.length) {
              dataArray.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
            }
            dataArray.forEach(element => {
              if (element.role === 'trainer') {
                allTrainers.push(element);
              }
            });
            // this.setState({
            //   loadingUsers: false,
            //   allUsers, allTrainers, allAdmins
            // });
          }
        }
        const currentTrainers = [...allTrainers];
        this.setState({
          loadingUsers: false,
          currentTrainers,
          allTrainers
        });
      })
      .catch(err => {
        this.setState({loadingUsers: false,});
      });

  };
  
  async getAllAdmins() {
    this.setState({
      toggleAddModal: false,
      toggleAddCoachModal: false,
      toggleAddDurationModal: false
    })
    await axios.get(`${BASE_URL}/api/admin-users/admins`)
      .then(res => {
        let allAdmins = [];
        if (res.data) {
          if (res.data.users) {
            let dataArray = res.data.users;
            if (dataArray && dataArray.length) {
              dataArray.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
            }
            dataArray.forEach(element => {
             if (element.role === 'admin') {
                allAdmins.push(element);
              }
            });
            // this.setState({
            //   loadingUsers: false,
            //   allUsers, allTrainers, allAdmins
            // });
          }
        }
        this.setState({
          loadingUsers: false,
          allAdmins
        });
      })
      .catch(err => {
        this.setState({loadingUsers: false,});
      });
    
  };

  showAddModal(role) {
    this.setState({toggleAddModal: true, addUserRole: role});
  };

  hideAddModal = () => {
    this.setState({toggleAddModal: false});
  };

  showAddCoachModal(userData) {
    this.setState({toggleAddCoachModal: true, selectedUser: userData});
  };

  hideAddCoachModal = () => {
    this.setState({toggleAddCoachModal: false});
  };

  showDurationModal(userData) {
    this.setState({toggleAddDurationModal: true, selectedUser: userData});
  };

  hideDurationModal = () => {
    this.setState({toggleAddDurationModal: false});
  };


  changeItemStatus = async record => {
    record.isActive = !record.isActive;
    const filter = {id: {$eq: `${record._id}`}};
    await axios.put(`${BASE_URL}/api/admin-users/${record._id}`, record)
      .then(async res => {
        const channels = await client.queryChannels(filter);
        if (channels && channels.length > 0) {
          const response = await channels[0].update({disabled: !record.isActive});
        }
        this.getAllUsers();
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        // this.setState({ errors: err.response.data });
      });
  }
  
  searchUsersByEmail = (value) => {
    const currentTrainers = []
    const {allTrainers} = this.state;
    allTrainers.forEach(selectedUser => {
      if(selectedUser.email.toLowerCase().includes(value.toLowerCase())){
        currentTrainers.push(selectedUser)
      }
    })
    this.setState({currentTrainers})
  }

  render() {
    const {
      allUsers, loadingUsers, allTrainers,currentTrainers, allAdmins,
      toggleAddModal, addUserRole, toggleAddCoachModal, selectedUser,
      toggleAddDurationModal
    } = this.state;

    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row>
              <Col xs={4}>
                <Search
                  placeholder="Search User By Email"
                  allowClear
                  size="large"
                  onChange={(e)=>{this.searchUsersByEmail(e.target.value)}}
                  onSearch={this.searchUsersByEmail}
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All Users</h3>
                    </div>
                    <div className="col text-right">
                      <Button type="primary" size="small" onClick={() => this.showAddModal('user')} >
                        Add User</Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={loadingUsers}
                  style={{ background: '#fff' }}
                  columns={this.userTableColumns}
                  dataSource={allUsers}
                  rowKey={record => record._id}
                />
              </Card>
            </Col>
          </Row> */}
          <Row style={{marginTop: 20}}>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All Coaches</h3>
                    </div>
                    <div className="col text-right">
                      <Button type="primary" size="small" onClick={() => this.showAddModal('trainer')}>
                        Add Coach</Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={loadingUsers}
                  style={{background: '#fff'}}
                  columns={this.trainerTableColumns}
                  dataSource={currentTrainers}
                  rowKey={record => record._id}
                />
              </Card>
            </Col>
          </Row>
          <Row style={{marginTop: 20, marginBottom: 20}}>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All Admins</h3>
                    </div>
                    <div className="col text-right">
                      <Button type="primary" size="small" onClick={() => this.showAddModal('admin')}>
                        Add Admin</Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={loadingUsers}
                  style={{background: '#fff'}}
                  columns={this.adminTableColumns}
                  dataSource={allAdmins}
                  rowKey={record => record._id}
                />
              </Card>
            </Col>
          </Row>
          {toggleAddModal ?
            <Modal
              title={`Add ${addUserRole}`}
              visible={toggleAddModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideAddModal}
            >
              <AddUserItem userRole={addUserRole} closeModal={() => {
                this.getAllUsers()
              }}/>
            </Modal>
            : null}

          {toggleAddCoachModal ?
            <Modal
              title="Assign Coach"
              visible={toggleAddCoachModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideAddCoachModal}
            >
              <AssignCoach userData={selectedUser} trainers={allTrainers} closeModal={() => {
                this.getAllUsers()
              }}/>
            </Modal>
            : null}

          {toggleAddDurationModal ?
            <Modal
              title="Assign Program Duration"
              visible={toggleAddDurationModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideDurationModal}
            >
              <AddDuration userData={selectedUser} trainers={allTrainers} closeModal={() => {
                this.getAllUsers()
              }}/>
            </Modal>
            : null}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
  user: state.auth.user
});
export default connect(
  mapStateToProps,
  null,
)(withRouter(PreferedUsers));
