import Profile from "./views/userViews/Profile.js";
import FitnessData from "./views/userViews/FitnessData.js";
import MacrosData from "./views/adminViews/MacrosData.js";
import UserDashboard from "./views/userViews/UserDashboard";
import Gallery from "./views/userViews/Gallery";

// import Maps from "views/examples/Maps.jsx";
// import Register from "views/examples/Register.jsx";
// import Login from "views/examples/Login.jsx";
// import Tables from "views/examples/Tables.jsx";
// import Icons from "views/examples/Icons.jsx";

import allUsers from "./views/adminViews/allUsers";
import PreferedUsers from "./views/adminViews/PreferedUsers";
import TrainerUsers from "./views/trainerViews/TrainerUsers";
import AdminDashboard from "./views/AdminDashboard";
import Messages from "./views/trainerViews/Messages";
import CustomMessages from "./views/adminViews/customMessages";

var userRoutes = [
  {
    path: "/index",
    name: "Progress",
    icon: "ni ni-tv-2 text-primary",
    component: UserDashboard,
    layout: "/admin"
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/fitness-data",
    name: "Nutrition Data",
    icon: "ni ni-bullet-list-67 text-red",
    component: FitnessData,
    layout: "/admin"
  },
  {
    path: "/user-gallery",
    name: "Gallery",
    icon: "ni ni-image text-yellow",
    component: Gallery,
    layout: "/admin"
  },
  {
    path: "/user-messages",
    name: "Messages",
    icon: "ni ni-single-copy-04 text-yellow",
    component: Messages,
    layout: "/admin"
  }
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }
];

var adminRoutes = [
  {
    path: "/index",
    name: "Progress",
    icon: "ni ni-tv-2 text-info",
    component: AdminDashboard,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users List",
    icon: "ni ni-single-copy-04 text-yellow",
    component: allUsers,
    layout: "/admin"
  },
  {
    path: "/prefered-user",
    name: "Coaches & Admins",
    icon: "ni ni-bullet-list-67 text-red",
    component: PreferedUsers,
    layout: "/admin"
  },
  {
    path: "/user-messages",
    name: "Messages",
    icon: "ni ni-single-copy-04 text-yellow",
    component: Messages,
    layout: "/admin"
  },
  {
    path: "/custom-messages",
    name: "Custom Messages",
    icon: "ni ni-single-copy-04 text-yellow",
    component: CustomMessages,
    layout: "/admin"
  },
  {
    path: "/macro-data",
    name: "MM Data",
    icon: "ni ni-bullet-list-67 text-red",
    component: MacrosData,
    layout: "/admin"
  },
];

var trainerRoutes = [
  {
    path: "/index",
    name: "Progress",
    icon: "ni ni-tv-2 text-info",
    component: AdminDashboard,
    layout: "/admin"
  },
  {
    path: "/trainer-users",
    name: "Users List",
    icon: "ni ni-single-copy-04 text-yellow",
    component: TrainerUsers,
    layout: "/admin"
  },
  {
    path: "/trainer-messages",
    name: "Messages",
    icon: "ni ni-single-copy-04 text-yellow",
    component: Messages,
    layout: "/admin"
  },
];
export { userRoutes, adminRoutes, trainerRoutes };
