import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {GET_ERRORS, SET_CURRENT_USER, SET_USER_OTP, SET_USER_PROFILE, USER_LOADING} from "./types";
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post(`${BASE_URL}/api/users/register`, userData)
    .then(res => {
      history.push("/")
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    });
};

// Login - get user token
export const loginUser = userData => dispatch => {
  // axios
  //   .post("/api/users/login", userData)
  //   .then(res => {
  //     // Save to localStorage

  //     // Set token to localStorage
  //     const { token } = res.data;
  //     localStorage.setItem("jwtTokenTeams", JSON.stringify(token));
  //     // Set token to Auth header
  //     setAuthToken(token);
  //     // Decode token to get user data
  //     const decoded = jwt_decode(token);
  //     // Set current user
  //     dispatch(setCurrentUser(decoded));
  //   })
  //   .catch(err =>
  //     dispatch({
  //       type: GET_ERRORS,
  //       payload: err.response.data
  //     })
  //   );
  const {token} = userData;
  localStorage.setItem("jwtTokenTeams", JSON.stringify(token));
  localStorage.setItem("loggedInUser", JSON.stringify(userData.user));
  // Set token to Auth header
  setAuthToken(token);
  // Decode token to get user data
  const decoded = jwt_decode(token);
  // Set current user
  dispatch(setCurrentUser({token: decoded, user: userData}));
};
export const updateProfile = () => dispatch => {
  const userProfile = JSON.parse(localStorage.userProfile)
  dispatch({
    type: SET_USER_PROFILE,
    payload: {userProfile}
  });
}
export const setOTP = (otp) => dispatch => {
  dispatch({
    type: SET_USER_OTP,
    payload: {userOtp: otp}
  });
}
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = history => dispatch => {
  // Remove token from local storage
  client.disconnectUser()
  localStorage.removeItem("jwtTokenTeams");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

  history.push("/");
};
