import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  FormGroup,
  Form,
  Col
} from "reactstrap";
import Header from "components/Headers/Header.jsx";
import {DatePicker, Input, Table, Button, Divider, message, Badge, Radio, Popover} from 'antd';
import "./styles.css";
import moment from "moment";
import axios from "axios";
import {StreamChat} from "stream-chat";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const initialState = {
  userData: {},
  userProfile: {},
  currentInputDate: moment(new Date(), 'YYYY-MM-DD'),
  dailyDataLoading: false,
  fixedDataLoading: false,
  saveDailyDataLoading: false,
  syncingWithMacro: false,
  dailyFitnessData: {},
  rawEstimatedData: {},
  fixedValues: [],
  trainerValues: [{carbs: 0, Fat: 0, days: 0, protein: 0}, {carbs: 0, Fat: 0, days: 0, protein: 0}],
  userInputValues: {
    carbs: "",
    Fat: "",
    protein: "",
    fiber: "",
    water: "",
    waist: "",
    weight: "",
    exercise: "",
    comments: ""
  },
  formatDailyData: [],
  currentDayOfWeek: 0,
  emailLoading: false,
  weightUnit: "pounds",
  heightUnit: "inch",
  weeklySubmission: []
};

const dailyDataColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render: text => <span>{moment.utc(text).format('ddd')}, {moment.utc(text).format('MMMM DD, YYYY')}</span>,
  },
  {
    title: 'Carbs',
    dataIndex: 'carbohydrate',
    key: 'carbohydrate',
  },
  {
    title: 'Fat',
    dataIndex: 'fat',
    key: 'fat',
  },
  {
    title: 'Protein',
    key: 'protein',
    dataIndex: 'protein',
  },
  {
    title: 'Total Kcal',
    dataIndex: 'totalKcal',
    key: 'kcal',
  },
  {
    title: 'Fiber',
    dataIndex: 'fiber',
    key: 'fiber',
  },
  {
    title: 'Water',
    dataIndex: 'water',
    key: 'water',
  },
  {
    title: 'Waist',
    dataIndex: 'waist',
    key: 'waist',
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: 'Exercise',
    dataIndex: 'exercise',
    key: 'exercise',
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments',
  },
];


const client = StreamChat.getInstance(GETSTREAM_KEY);

class FitnessData extends React.Component {

  constructor() {
    super();
    this.fixedDataColumns = [
      {
        title: 'Week Number',
        dataIndex: 'weekNo',
        key: 'weekNo',
        render: text => <span>{text}</span>,
      },
      {
        title: 'Week startDate',
        dataIndex: 'startDate',
        key: 'startDate',
        render: text => <span>{moment.utc(text).format('ddd')}, {moment.utc(text).format('MMMM DD, YYYY')}</span>,
      },
      {
        title: 'Week endDate',
        dataIndex: 'endDate',
        key: 'endDate',
        render: text =>
          <span>{moment.utc(moment(text).subtract(1, "days")).format('ddd')}, {moment.utc(moment(text).subtract(1, "days")).format('MMMM DD, YYYY')}</span>
      },
      {
        title: 'Days/Range',
        key: 'days',
        render: record => (this.renderFixedDateValues(record, "days")),
      },
      {
        title: 'Carbs',
        key: 'carbo',
        render: record => (this.renderFixedDateValues(record, "carbs")),
      },
      {
        title: 'Fat',
        key: 'fat',
        render: record => (this.renderFixedDateValues(record, "Fat")),
      },
      {
        title: 'Protein',
        key: 'protein',
        render: record => (this.renderFixedDateValues(record, "protein")),
      },
      {
        title: 'Total Kcal',
        key: 'kcal',
        render: record => (
          <div>
            <p>{this.calculateCalories(record.weeklyData[0].carbs, record.weeklyData[0].protein, record.weeklyData[0].Fat)}</p>
            <hr/>
            <p>{this.calculateCalories(record.weeklyData[1].carbs, record.weeklyData[1].protein, record.weeklyData[1].Fat)}</p>
          </div>
        ),
      }
    ];
    this.avegareColumns = [
      {
        title: 'Week No',
        key: 'weekNumber',
        render: (data) => (<span>{data.weekNumber}</span>),
      },
      {
        title: 'Avge Carbs',
        key: 'avgeCarbs',
        render: (data) => (this.renderAvgeColumnData(data, 'carbohydrate')),
      },
      {
        title: 'Avge Fats',
        key: 'avgeFats',
        render: (data) => (this.renderAvgeColumnData(data, 'fat')),
      },
      {
        title: 'Avge Protein',
        key: 'avgeProtein',
        render: (data) => (this.renderAvgeColumnData(data, 'protein')),
      },
      {
        title: 'Avge Kcal',
        key: 'avgeKcal',
        render: (data) => (this.renderAvgeColumnData(data, 'totalKcal')),
      },
      {
        title: 'Avge Fiber',
        key: 'avgeFiber',
        render: (data) => (this.renderAvgeColumnData(data, 'fiber')),
      },
      {
        title: 'Avge Water',
        key: 'avgeWater',
        render: (data) => (this.renderAvgeColumnData(data, 'water')),
      },
      {
        title: 'Avge Waist',
        key: 'avgeWaist',
        render: (data) => (this.renderAvgeColumnData(data, 'waist')),
      },
      {
        title: 'Avge Weight',
        key: 'avgeWeight',
        render: (data) => (this.renderAvgeColumnData(data, 'weight')),
      },
      {
        title: 'Weekly Comment',
        key: 'comment',
        // render: (data) => <span>{data.weeklyComment ? data.weeklyComment : ""}</span>
        render: (data) => <div>
          < Popover placement="topLeft" arrowPointAtCenter
                    content={data.weeklyComment ? data.weeklyComment : ""} trigger="click">
            <Button>View Comments</Button>
          </Popover>
        </div>
      }
    ];
    this.state = {...initialState};
  }

  renderFixedDateValues(record, key) {
    let {trainerValues} = this.state;
    if (key === "days" && (trainerValues[0]["days"] === 0 && trainerValues[1]["days"] === 0)) {
      return (
        <div>
          <p>Low</p>
          <hr/>
          <p>High</p>
        </div>
      )
    } else {
      return (
        <div>
          <p>{trainerValues[0][key] ? trainerValues[0][key] : 0}</p>
          <hr/>
          <p>{trainerValues[1][key] ? trainerValues[1][key] : 0}</p>
        </div>
      )
    }
  }

  renderAvgeColumnData(data, columnKey,) {
    let calData = 0;
    let items = 0
    data.dailyData.forEach(element => {
      if (element[columnKey]) {
        calData += element[columnKey];
        items++;
      }
    });
    return (
      columnKey === "weight" || columnKey === "waist" ?
        <span>{items ? (calData / items).toFixed(1) : 0}</span>
        :
        <span>{items ? (calData / items).toFixed(0) : 0}</span>
    );
  }

  async componentWillMount() {
    let {userData, userProfile, weeklySubmission} = this.state;

    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
      this.setState({userData, formatDailyData: []})

      if (localStorage[`${userData._id}_mailStatus`]) {
        weeklySubmission = JSON.parse(localStorage[`${userData._id}_mailStatus`])
        this.setState({weeklySubmission})
      }
    }
    if (localStorage.userProfile) {
      userProfile = JSON.parse(localStorage.userProfile)
      this.setState({userProfile})
    }

    await this.getUserFixedData(userData);
    await this.getUserDailyData(userData);
  }

  async getUserDailyData(user) {
    this.setState({dailyDataLoading: true})

    await axios.get(`${BASE_URL}/api/fitness-data/${user._id}?date=${moment().format('YYYY-MM-DD')}T00:00:00.000Z`)
      .then(res => {
        if (res.data && res.data) {
          this.formatDailyDataToWeekly(res.data)
          this.setState({dailyFitnessData: res.data});
        }
        this.setState({dailyDataLoading: false});
      })
      .catch(err => {
        this.setState({dailyDataLoading: false,});
      });

  };

  async getUserFixedData(user) {
    this.setState({fixedDataLoading: true})

    await axios.get(`${BASE_URL}/api/weekly-estimate/${user._id}`)
      .then(res => {
        this.saveFixedValues(res.data, this.state.currentInputDate);
        this.setState({rawEstimatedData: res.data, fixedDataLoading: false});
      })
      .catch(err => {
        this.setState({fixedDataLoading: false});
      });

  };

  async saveFixedValues(data, currentInputDate) {
    let {trainerValues} = this.state;
    let currentDate = moment(currentInputDate).format('YYYY-MM-DD');

    if (data && data.estimatedData && data.estimatedData.length) {
      let index = data.estimatedData.findIndex(e => {
        let starting = moment.utc(e.startDate).format('YYYY-MM-DD');
        let ending = moment.utc(e.endDate).format('YYYY-MM-DD');
        return currentDate >= starting && currentDate < ending;
      })
      if (index !== -1) {
        let filteredData = [];
        filteredData.push(data.estimatedData[index]);
        let endD = moment.utc(moment(data.estimatedData[index].endDate).subtract(1, "days")).format('YYYY-MM-DD');

        /* if (currentDate >= startD && currentDate < moment.utc(firstAddedDate).format('YYYY-MM-DD')) {
          trainerValues = data.estimatedData[index].weeklyData[0];
        } else {
          trainerValues = data.estimatedData[index].weeklyData[1];
        } */
        let weekData = data.estimatedData[index].weeklyData;
        let zeroIndex = await this.calculateCalories(weekData[0].carbs, weekData[0].protein, weekData[0].Fat)
        let oneIndex = await this.calculateCalories(weekData[1].carbs, weekData[1].protein, weekData[1].Fat)

        if (weekData[0].days !== 7) {
          if (Number(oneIndex) > Number(zeroIndex)) {
            trainerValues[0] = weekData[0];
            trainerValues[1] = weekData[1];
          } else {
            trainerValues[0] = weekData[1];
            trainerValues[1] = weekData[0];
          }
        } else {
          trainerValues[0] = weekData[0];
          trainerValues[1] = weekData[1];
        }

        this.handleNotificationData(currentDate, endD);

        this.setState({fixedValues: filteredData, trainerValues})
      }
    }
  }

  handleNotificationData(currentDate, endD) {
    let {currentDayOfWeek} = this.state;
    for (let i = 1; i <= 7; i++) {

      if (currentDate === endD) {
        currentDayOfWeek = 7;
      } else {
        let addedDay = moment.utc(currentDate).add(i, 'days');
        if (moment(addedDay).format("YYYY-MM-DD") === endD) {
          currentDayOfWeek = 7 - i;
        }
      }
    }
    this.setState({currentDayOfWeek})
  }

  async submitWeeklyData() {
    const {userData, userProfile, fixedValues, formatDailyData} = this.state;
    let {weeklySubmission} = this.state;
    this.setState({emailLoading: true});

    let userWeeklyData = [];
    if (fixedValues.length) {
      let dataInd = formatDailyData.findIndex(e => e.weekNumber === fixedValues[0].weekNo);
      if (dataInd !== -1) {
        userWeeklyData = formatDailyData[dataInd].dailyData;
      }
    }

    userWeeklyData.forEach(element => {
      element.date = moment.utc(element.date).format('DD-MMM-YYYY')
    })

    const emailData = {
      userData: userData,
      weekNo: fixedValues.length ? fixedValues[0].weekNo : 0,
      weeklyData: userWeeklyData,
      sendTo: userProfile.coach
    };

    const filter = {id: {$eq: `${userData._id}`}};
    const channels = await client.queryChannels(filter);
    const reply = await channels[0].sendMessage({
      text: `My data for week ${emailData.weekNo} is complete`
    });

    let index = weeklySubmission.findIndex(e => e.weekNo == (emailData.weekNo).toString());
    if (index !== -1) {
      weeklySubmission[index].count = 2;
    } else {
      weeklySubmission.push({"weekNo": emailData.weekNo, "count": 1});
    }
    this.setState({emailLoading: false, weeklySubmission});
    localStorage.setItem(`${userData._id}_mailStatus`, JSON.stringify(weeklySubmission));

    // send email to coach with its weekly data
    /*axios.post(`${BASE_URL}/api/send-email/`, emailData)
      .then(res => {
        let index = weeklySubmission.findIndex(e => e.weekNo == (emailData.weekNo).toString());
        if (index !== -1) {
          weeklySubmission[index].count = 2;
        } else {
          weeklySubmission.push({"weekNo": emailData.weekNo, "count": 1});
        }
        this.setState({emailLoading: false, weeklySubmission});
        localStorage.setItem(`${userData._id}_mailStatus`, JSON.stringify(weeklySubmission));
        message.info('Email sent successfully');
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        this.setState({emailLoading: false});
        message.error('some error occured');
      });*/
  }

  changeFieldValue(key, value) {
    let {userInputValues} = this.state;
    const newValue = (key === "fiber" || key === "water") ? parseInt(value) : value
    if(key === 'exercise'|| key==='comments')
      userInputValues[key] = newValue;
    else {
      userInputValues[key] = newValue > 0 ? newValue : '';
    }
    this.setState({userInputValues});
  }

  formatDailyDataToWeekly(data) {
    let {userProfile, formatDailyData, rawEstimatedData} = this.state;
    if (userProfile && userProfile.planLength) {

      let formatDataToWeekly = [];

      for (let i = 0; i < userProfile.planLength; i++) {
        formatDataToWeekly.push({weekNumber: i + 1, dailyData: []});
      }
      if (data.dailyData) {
        formatDataToWeekly.forEach((element, index) => {
          let weekStart = moment.utc(userProfile.startDate).add((element.weekNumber - 1) * 7, 'days');
          let weekEnd = moment.utc(userProfile.startDate).add((element.weekNumber * 7) - 1, 'days');
          let startD = moment.utc(weekStart).format('YYYY-MM-DD');
          let endD = moment.utc(weekEnd).format('YYYY-MM-DD');


          if (rawEstimatedData.estimatedData && rawEstimatedData.estimatedData.length) {
            let weekInd = rawEstimatedData.estimatedData.findIndex(e => e.weekNo === (index + 1));
            if (weekInd !== -1) {
              element.weeklyComment = rawEstimatedData.estimatedData[weekInd].weeklyComment;
            }
          }

          data.dailyData.forEach(item => {
            let itemDate = moment.utc(item.date).format('YYYY-MM-DD');
            if (itemDate >= startD && itemDate <= endD) {
              element.dailyData.push(item);
            }
          });
        });
      }

      let removeEmpty = [];
      formatDataToWeekly.forEach(element => {
        if (element.dailyData.length) {
          removeEmpty.push(element);
        }
      });
      this.setState({formatDailyData: removeEmpty})
      this.showPrefilledValues(data);
    }
  }

  showPrefilledValues(data) {
    let {currentInputDate, userInputValues} = this.state;

    if (data.dailyData && data.dailyData.length) {
      let index = data.dailyData.findIndex(e => {
        let findDate = moment.utc(e.date).format('YYYY-MM-DD');
        let currentDate = moment(currentInputDate).format('YYYY-MM-DD');
        return findDate === currentDate;
      })

      if (index !== -1) {
        userInputValues.carbs = data.dailyData[index].carbohydrate;
        userInputValues.Fat = data.dailyData[index].fat;
        userInputValues.protein = data.dailyData[index].protein;
        userInputValues.fiber = data.dailyData[index].fiber;
        userInputValues.water = data.dailyData[index].water;
        userInputValues.waist = data.dailyData[index].waist;
        userInputValues.weight = data.dailyData[index].weight;
        userInputValues.exercise = data.dailyData[index].exercise;
        userInputValues.comments = data.dailyData[index].comments;
      } else {
        userInputValues = {
          carbs: "",
          Fat: "",
          protein: "",
          fiber: "",
          water: "",
          waist: "",
          weight: "",
          exercise: "",
          comments: ""
        }
      }
      this.setState({userInputValues, weightUnit: "pounds", heightUnit: "inch",})
    }
  }
  
  syncToMacros = ()=> {
    this.setState({ syncingWithMacro: true })
    axios.post(`${BASE_URL}/api/users/sync-with-macro`,{
        user_id: this.state.userData._id,
        date: moment().format('MM-DD-YYYY')
      })
      .then(res => {
        if(res.data.success ){
          message.success('Successfully synced to MyMacros+');
          this.getUserDailyData(this.state.userData);
          this.setState({ syncingWithMacro: false })
        }
        else {
          if(this.state.userProfile && this.state.userProfile.macroStatus === 'active') {
            message.error('No active Plan');
          }
          else {
            message.error('Failed to connect with MyMacros+');
          }
          this.setState({ syncingWithMacro: false })
        }
      })
      .catch(err => {
        this.setState({ syncingWithMacro: false, });
      });
    
  };
  
  syncToMacrosWeekly = ()=> {
    this.setState({ syncingWithMacro: true })
    axios.post(`${BASE_URL}/api/users/sync-macro-week`,{
        user_id: this.state.userData._id,
        date: moment().format('MM-DD-YYYY')
      })
      .then(res => {
        if(res.data.success ){
          message.success('Successfully synced to MyMacros+');
          this.getUserDailyData(this.state.userData);
          this.setState({ syncingWithMacro: false })
        }
        else {
          if(this.state.userProfile && this.state.userProfile.macroStatus === 'active') {
            message.error('No active Plan');
          }
          else {
            message.error('Failed to connect with MyMacros+');
          }
          this.setState({ syncingWithMacro: false })
        }
      })
      .catch(err => {
        this.setState({ syncingWithMacro: false, });
      });
    
  };

  async saveDailyFitnessData() {

    const {userData, userProfile, currentInputDate, heightUnit, weightUnit} = this.state;
    let {dailyFitnessData, userInputValues} = this.state;

    if (userProfile && userProfile.startDate) {

      this.setState({saveDailyDataLoading: true})

      let fitnessData = {
        userId: userData._id,
        dailyData: [{
          date: moment(currentInputDate).format('YYYY-MM-DD'),
          carbohydrate: userInputValues.carbs ? Number(userInputValues.carbs).toFixed(0) : '',
          fat: userInputValues.Fat ? Number(userInputValues.Fat).toFixed(0) : '',
          protein: userInputValues.protein ? Number(userInputValues.protein).toFixed(0) : '',
          totalKcal: (this.calculateCalories(userInputValues.carbs, userInputValues.protein, userInputValues.Fat)),
          fiber: userInputValues.fiber ? Number(userInputValues.fiber).toFixed(0) : '',
          water: userInputValues.water ? Number(userInputValues.water).toFixed(0) : '',
          waist: heightUnit === 'inch' ? userInputValues.waist : (userInputValues.waist * 0.39370).toFixed(2),
          weight: weightUnit === 'pounds' ? userInputValues.weight : (userInputValues.weight * 2.2046).toFixed(2),
          exercise: userInputValues.exercise,
          comments: userInputValues.comments
        }]
      };
      if (dailyFitnessData._id) {

        let updatedData = {};
        let index = -1;

        if (dailyFitnessData.dailyData && dailyFitnessData.dailyData.length) {
          index = dailyFitnessData.dailyData.findIndex(e => {
            let findDate = moment.utc(e.date).format('YYYY-MM-DD');
            let currentDate = moment(currentInputDate).format('YYYY-MM-DD');
            return findDate === currentDate;
          })
        }
        if (index !== -1) {
          dailyFitnessData.dailyData[index] = fitnessData.dailyData[0];
          updatedData = {dailyData: dailyFitnessData.dailyData}
        } else {
          dailyFitnessData.dailyData.push(fitnessData.dailyData[0]);
          updatedData = {dailyData: dailyFitnessData.dailyData}
        }

        await axios.put(`${BASE_URL}/api/fitness-data/${dailyFitnessData._id}`, updatedData)
          .then(res => {
            userInputValues = {
              carbs: "",
              Fat: "",
              protein: "",
              fiber: "",
              water: "",
              waist: "",
              weight: "",
              exercise: "",
              comments: ""
            }
            this.formatDailyDataToWeekly(res.data);
            this.setState({saveDailyDataLoading: false, dailyFitnessData: res.data});
            message.info('updated data successfully');
          })
          .catch(err => {
            this.setState({saveDailyDataLoading: false,});
            message.error('some error occured');
          });
      } else {
        await axios.post(`${BASE_URL}/api/fitness-data/`, fitnessData)
          .then(res => {
            userInputValues = {
              carbs: "",
              Fat: "",
              protein: "",
              fiber: "",
              water: "",
              waist: "",
              weight: "",
              exercise: "",
              comments: ""
            }
            this.formatDailyDataToWeekly(res.data);
            this.setState({saveDailyDataLoading: false, userInputValues, dailyFitnessData: res.data});
            message.info('submit data successfully');
          })
          .catch(err => {
            this.setState({saveDailyDataLoading: false,});
            message.error('some error occured');
          });
      }
    }
  }

  onChangeCurrentDate = (date, dateString) => {
    const {rawEstimatedData} = this.state;
    // this.saveFixedValues(rawEstimatedData, date);
    this.setState({...initialState});
    this.setState({currentInputDate: date})
    this.componentWillMount();
  }

  savePreviousDataIndex(dailyFitnessData, date) {

    if (dailyFitnessData.dailyData && dailyFitnessData.dailyData.length) {
      let index = dailyFitnessData.dailyData.findIndex(e => {
        let findDate = moment.utc(e.date).format('YYYY-MM-DD');
        let currentDate = moment.utc(date).format('YYYY-MM-DD');
        return findDate === currentDate;
      })
      if (index !== -1) {
        this.setState({previousDataIndex: index});
      }
    }
  }

  calculateCalories(carbs, protein, fats,) {
    let sum = (carbs * 4) + (protein * 4) + (fats * 9);
    return sum ? Number(sum).toFixed(0) : 0;
  }

  onChangeWeightUnit = e => {
    let {userInputValues, weightUnit} = this.state;

    if (e.target.value === 'kg' && weightUnit === 'pounds') {
      userInputValues.weight = (userInputValues.weight / 2.2046).toFixed(2);
    } else if (e.target.value === 'pounds' && weightUnit === 'kg') {
      userInputValues.weight = (userInputValues.weight * 2.2046).toFixed(2);
    }
    this.setState({weightUnit: e.target.value, userInputValues});
  };

  onChangeHeightUnit = e => {
    let {userInputValues, heightUnit} = this.state;

    if (e.target.value === 'inch' && heightUnit === 'cm') {
      userInputValues.waist = (userInputValues.waist * 0.39370).toFixed(2);
    } else if (e.target.value === 'cm' && heightUnit === 'inch') {
      userInputValues.waist = (userInputValues.waist / 0.39370).toFixed(2);
    }
    this.setState({heightUnit: e.target.value, userInputValues});
  };

  showInputTypeBadge(fixedValues) {
    if (fixedValues.length && fixedValues[0].dataInputType) {
      if (fixedValues[0].dataInputType === "ranges") {
        return "Ranges"
      } else if (fixedValues[0].weeklyData && (fixedValues[0].weeklyData[0].days !== 0 && fixedValues[0].weeklyData[1].days !== 0)) {
        return "Kcal Cycling"
      } else {
        return ""
      }
    }
  }

  render() {
    const {
      currentInputDate, fixedDataLoading, fixedValues, trainerValues,
      userInputValues, saveDailyDataLoading, dailyFitnessData, formatDailyData,syncingWithMacro,
      dailyDataLoading, currentDayOfWeek, emailLoading, weeklySubmission
    } = this.state;

    if (dailyFitnessData.dailyData) {
      dailyFitnessData.dailyData.sort((a, b) => new Date(b.date) - new Date(a.date))
    }
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"/>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>

          <Row style={{marginBottom: 20}}>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Weekly Fixed Data</h3>
                    </div>
                    <div className="col" style={{textAlign: "end"}}>
                      <Badge size="default" count={this.showInputTypeBadge(fixedValues)}
                             style={{backgroundColor: '#d8b66d'}}/>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  columns={this.fixedDataColumns}
                  dataSource={fixedValues}
                  loading={fixedDataLoading}
                  rowKey={record => record.weekNo}
                />
              </Card>
            </Col>
          </Row>

          {currentDayOfWeek ?
            <Row className={"customAlert mb-2 " + (currentDayOfWeek === 7 ? "red" : "black")}>
              <Col>
                <span style={{color: currentDayOfWeek === 7 ? "black" : "white"}}>
                  Day {currentDayOfWeek}, Week {fixedValues.length ? fixedValues[0].weekNo : ""}</span>
              </Col>
              {fixedValues.length ?
                <Col className="text-right">
                  {currentDayOfWeek === 7 && weeklySubmission.findIndex(e => e.weekNo == (fixedValues[0].weekNo).toString()) === -1 ?
                   <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Button
                      type="primary" size="small"
                      style={{alignItems: 'center'}}
                      loading={emailLoading}
                      onClick={() => this.submitWeeklyData()}>
                      Check In</Button>
                     {this.state.userProfile && this.state.userProfile.macroStatus === 'active' &&
                       <Button
                         type="primary"
                         size="small"
                         className="macros-sync"
                         style={{alignItems: 'center'}}
                         loading={emailLoading}
                         onClick={() => this.syncToMacrosWeekly()}>
                         Sync MyMacros</Button>
                     }
                   </div>
                    :
                    weeklySubmission.findIndex(e => e.weekNo == (fixedValues[0].weekNo).toString()) !== -1 && weeklySubmission.find(e => e.weekNo == (fixedValues[0].weekNo).toString()).count === 1 ?
                      <Button
                        type="primary"
                        size="small"
                        style={{alignItems: 'center'}}
                        loading={emailLoading}
                        onClick={() => this.submitWeeklyData()}>
                        Resubmit</Button>
                      : null
                  }
                </Col>
                : null
              }
            </Row>
            : null}

          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Daily Data</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      General Entries
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                        </Col>
                        <Col>
                          <FormGroup>
                            <DatePicker
                              style={{border: 0, borderColor: '#fff'}}
                              className="form-control form-control-alternative"
                              size="large"
                              format={'MMMM DD, YYYY'}
                              value={currentInputDate}
                              onChange={this.onChangeCurrentDate}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {trainerValues[0].days !== 7 ?
                        <Row className="mb-2">
                          <Col lg="2"></Col>
                          <Col className="text-center">
                            <Badge size="default" count={"Low"} style={{backgroundColor: '#d8b66d'}}/>
                          </Col>
                          <Col></Col>
                          <Col className="text-center">
                            <Badge size="default" count={"High"} style={{backgroundColor: '#d8b66d'}}/>
                          </Col>
                        </Row>
                        : null
                      }
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Carbohydrate
                          </label>
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative coach-values"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={trainerValues[0].carbs}
                          />
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            value={userInputValues.carbs}
                            onChange={e => this.changeFieldValue('carbs', Number(e.target.value))}
                          />
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={trainerValues[0].days === 7 ? Number(trainerValues[0].carbs - userInputValues.carbs).toFixed(0) : trainerValues[1].carbs}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >Fat</label>
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative coach-values"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={trainerValues[0].Fat}
                          />
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            value={userInputValues.Fat}
                            onChange={e => this.changeFieldValue('Fat', Number(e.target.value))}
                          />
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={trainerValues[0].days === 7 ? Number(trainerValues[0].Fat - userInputValues.Fat).toFixed(0) : trainerValues[1].Fat}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >Protein</label>
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative coach-values"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={trainerValues[0].protein}
                          />
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            value={userInputValues.protein}
                            onChange={e => this.changeFieldValue('protein', Number(e.target.value))}
                          />
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={trainerValues[0].days === 7 ? Number(trainerValues[0].protein - userInputValues.protein).toFixed(0) : trainerValues[1].protein}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >Total Kcal</label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative coach-values"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={this.calculateCalories(trainerValues[0].carbs, trainerValues[0].protein, trainerValues[0].Fat)}
                          />
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={this.calculateCalories(userInputValues.carbs, userInputValues.protein, userInputValues.Fat)}
                          />
                        </Col>
                        <Col>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            onWheel={ event => event.currentTarget.blur() }
                            disabled
                            value={trainerValues[0].days === 7 ?
                              (this.calculateCalories(trainerValues[0].carbs, trainerValues[0].protein, trainerValues[0].Fat) - this.calculateCalories(userInputValues.carbs, userInputValues.protein, userInputValues.Fat))
                              : this.calculateCalories(trainerValues[1].carbs, trainerValues[1].protein, trainerValues[1].Fat)
                            }
                          />
                        </Col>
                      </Row>

                    </div>
                    <hr className="my-4"/>
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Weekly Entries
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Fiber (gram)
                          </label>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="number"
                              size="large"
                              value={userInputValues.fiber}
                              onWheel={ event => event.currentTarget.blur() }
                              onChange={e => this.changeFieldValue('fiber', e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Water Consumed (ounces)
                          </label>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="number"
                              size="large"
                              onWheel={ event => event.currentTarget.blur() }
                              value={userInputValues.water}
                              onChange={e => this.changeFieldValue('water', e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Waist Measurement
                          </label>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="number"
                              onWheel={ event => event.currentTarget.blur() }
                              size="large"
                              value={userInputValues.waist}
                              onChange={e => this.changeFieldValue('waist', e.target.value)}
                            />
                            <div>
                              <Radio.Group
                                buttonStyle="solid"
                                size="small"
                                onChange={this.onChangeHeightUnit}
                                value={this.state.heightUnit}
                              >
                                <Radio.Button value="inch">inch</Radio.Button>
                                <Radio.Button value="cm">cm</Radio.Button>
                              </Radio.Group>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Body Weight
                          </label>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="number"
                              onWheel={ event => event.currentTarget.blur() }
                              size="large"
                              value={userInputValues.weight}
                              onChange={e => this.changeFieldValue('weight', e.target.value)}
                            />
                            <div>
                              <Radio.Group
                                buttonStyle="solid"
                                size="small"
                                onChange={this.onChangeWeightUnit}
                                value={this.state.weightUnit}
                              >
                                <Radio.Button value="pounds">Pounds</Radio.Button>
                                <Radio.Button value="kg">Kg</Radio.Button>
                              </Radio.Group>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                    </div>
                    <hr className="my-4"/>
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">Additional Entries</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Exercise Completed
                            </label>
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              size="large"
                              value={userInputValues.exercise}
                              onChange={e => this.changeFieldValue('exercise', e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Additional Comments
                            </label>
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              size="large"
                              value={userInputValues.comments}
                              onChange={e => this.changeFieldValue('comments', e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                    </div>
                  </Form>
                </CardBody>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center" style={{textAlign: 'center'}}>
                    <Col className="flex align-items-center justify-content-center">
                      <Button
                        type="primary" size="large"
                        style={{alignItems: 'center', width: 200, marginLeft: 15, marginRight:15,  marginTop: 10, marginBottom: 10}}
                        loading={saveDailyDataLoading}
                        onClick={() => this.saveDailyFitnessData()}
                      >
                        Save Data</Button>
                      {this.state.userProfile && this.state.userProfile.macroStatus === 'active' && <Button
                        style={{alignItems: 'center', marginLeft: 15, marginRight:15,  width: 200, backgroundColor:'#DC5F00', color:'#fff', marginTop: 10, marginBottom: 10}}
                        size="large"
                        loading={syncingWithMacro || dailyDataLoading}
                        onClick={() => this.syncToMacros()}
                      >
                        Sync from MyMacros+</Button>}
                      {this.state.missingVal ?
                        <span style={{color: 'red', position: 'absolute', bottom: -20,}}>some fields are missing</span>
                        : null}
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          {/* Table */}
          <Row style={{marginTop: 20}}>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Recent data</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={dailyDataLoading}
                  columns={dailyDataColumns}
                  dataSource={dailyFitnessData.dailyData ? dailyFitnessData.dailyData : []}
                  rowKey={record => record.date}
                  pagination={{pageSize: 7}}
                />
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop: 20}}>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Average Data</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={dailyDataLoading}
                  columns={this.avegareColumns}
                  dataSource={formatDailyData}
                  rowKey={record => record.weekNumber}
                />
              </Card>
            </Col>
          </Row>

        </Container>
      </>
    );
  }
}

export default FitnessData;
