
import Register from "./views/auth/Register.js";
import Login from "./views/auth/Login.js";
import Verify from "./views/auth/Verify.js";
import ResetPassword from "./views/auth/ResetPassword";
import ForgotPassword from "./views/auth/ForgotPassword";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/verify",
    name: "Verify",
    icon: "ni ni-key-25 text-info",
    component: Verify,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    icon: "ni ni-circle-08 text-pink",
    component: ForgotPassword,
    layout: "/auth"
  },
  {
    path: "/reset-password/:token/:email",
    name: "ResetPassword",
    icon: "ni ni-circle-08 text-pink",
    component: ResetPassword,
    layout: "/auth"
  }
];
export default routes;
