// Authentication
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USER_OTP = "SET_USER_OTP";

// // Projects
// export const CREATE_PROJECT = "CREATE_PROJECT";
// export const UPDATE_PROJECT = "UPDATE_PROJECT";
// export const DELETE_PROJECT = "DELETE_PROJECT";
// export const GET_PROJECT = "GET_PROJECT";
// export const PROJECT_LOADING = "PROJECT_LOADING";
// export const GET_PROJECTS = "GET_PROJECTS";
// export const PROJECTS_LOADING = "PROJECTS_LOADING";

// // Tasks
// export const CREATE_TASK = "CREATE_TASK";
// export const UPDATE_TASK = "UPDATE_TASK";
// export const DELETE_TASK = "DELETE_TASK";
// export const GET_TASKS = "GET_TASKS";
// export const TASKS_LOADING = "TASKS_LOADING";
