import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";

import routes from "../authRoutes";
import { Modal, Alert, Button } from 'antd';
class Auth extends React.Component {
  // componentDidMount() {
  //   document.body.classList.add("bg-default");
  // }
  // componentWillUnmount() {
  //   document.body.classList.remove("bg-default");
  // }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        {/* <Modal
          title="Server Down"
          visible={true}
          closable={false}
          footer={null}
          centered={true}
        >
          <p>We are currently making updates. Please check back later!!</p>
        </Modal> */}
        {/*<Alert
          message="we apologize for the inconvenience, we are almost complete. Pls check back tomorrow"
          type="info"
          showIcon
          banner
          closable
        />*/}
        <div className="main-content" style={{ background: "#000" }}>
          {/* <AuthNavbar /> */}
          <div className="header bg-gradient-info py-4 py-lg-6">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <img src={require('../assets/img/brand/PLT-Logo.png')} alt="Logo"
                      height="100" width="100" style={{ marginBottom: '3rem' }} />
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  style={{ fill: '#000' }}
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
