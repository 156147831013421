import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import Sidebar from "../components/Sidebar/Sidebar.js";
import UserDetails from "../views/trainerViews/UserDetails";
import UserDashboard from "../views/userViews/UserDashboard";

import { userRoutes, adminRoutes, trainerRoutes } from "../adminRoutes";
import { Modal } from 'antd';
class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path, routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i];
      }
    }
    return "Brand";
  };
  render() {
    let routes = userRoutes;
    let userData = undefined;
    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
      let accessLevel = userRoutes;
      switch (userData.role) {
        case "admin": {
          accessLevel = adminRoutes;
          break;
        }
        case "trainer": {
          accessLevel = trainerRoutes;
          break;
        }
        default: {
          accessLevel = userRoutes;
          break;
        }
      }
      routes = accessLevel;
    }

    return (
      <>
        {/* <Modal
          title="Server Down"
          visible={true}
          closable={false}
          footer={null}
          centered={true}
        >
          <p>We are currently making updates. Please check back later!!</p>
        </Modal> */}
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            // innerLink: "/admin/index",
            imgSrc: require("assets/img/brand/logo-3.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname, routes)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          {userData.role === 'trainer' ?
            <>
              <Switch>
                <Route
                  path={'/admin/user-details'}
                  component={UserDetails}
                />
              </Switch>
              <Switch>
                <Route
                  path={'/admin/user-dashboard'}
                  component={UserDashboard}
                />
              </Switch>
            </>
            : null}
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
