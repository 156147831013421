import React from "react";

import {Card, CardBody, CardTitle, Container, Row, Col} from "reactstrap";
import moment from "moment";

class UserDashBoardHeader extends React.Component {
  constructor() {
    super();
  }

  render() {
    const {userProfile, minWeight, maxWeight, currentWeight, lastActive, weightChange, totalDiff} = this.props;
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Inches Lost
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar"/>
                          </div>
                        </Col>
                      </Row>
                      <span className="h2 font-weight-bold mb-0">
                        {totalDiff}
                        <span className="h4 mb-0"> Inches</span>
                      </span>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 48%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Weight Loss
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie"/>
                          </div>
                        </Col>
                      </Row>
                      <span className="h2 font-weight-bold mb-0">
                        {`${(weightChange > 0 ? '-' : '') + weightChange.toFixed(2)}`}
                        <span className="h4 mb-0"> Pounds</span>
                      </span>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last week</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            last entered data
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent"/>
                          </div>
                        </Col>
                      </Row>
                      <span className="h4 mb-0">
                        {lastActive ? `${moment.utc(lastActive).format('ddd') + " " + moment.utc(lastActive).format('MMMM DD, YYYY')}` : ""}
                      </span>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0 mt-lg-4">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Current Weight
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users"/>
                          </div>
                        </Col>
                      </Row>
                      <span className="h2 font-weight-bold mb-0">
                        {currentWeight ? currentWeight : userProfile.weight ? userProfile.weight : currentWeight}
                        <span className="h4 mb-0"> Pounds</span>
                      </span>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Since yesterday</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0 mt-lg-4">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            All-time High
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users"/>
                          </div>
                        </Col>
                      </Row>
                      <span className="h2 font-weight-bold mb-0">
                        {maxWeight ? maxWeight.value : ''}
                        <span className="h4 mb-0"> Pounds</span>
                      </span>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0 mt-lg-4">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            All-time Low
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users"/>
                          </div>
                        </Col>
                      </Row>
                      <span className="h2 font-weight-bold mb-0">
                        {minWeight ? minWeight.value : ''}
                        <span className="h4 mb-0"> Pounds</span>
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default UserDashBoardHeader;
