import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  FormGroup,
  Col
} from "reactstrap";
import { DatePicker, Table, Button, Popover, Input } from 'antd';
import "./styles.css";
import moment from "moment";
import axios from "axios";
import {BASE_URL} from "../../constants";

const { Search } = Input;

const initialState = {
  userData: {},
  userProfile: {},
  currentInputDate: moment(new Date(), 'YYYY-MM-DD'),
  dailyDataLoading: false,
  fixedDataLoading: false,
  saveDailyDataLoading: false,
  syncingWithMacro: false,
  dailyFitnessData: [],
  rawEstimatedData: {},
  fixedValues: [],
  trainerValues: [{carbs: 0, Fat: 0, days: 0, protein: 0}, {carbs: 0, Fat: 0, days: 0, protein: 0}],
  userInputValues: {
    carbs: "",
    Fat: "",
    protein: "",
    fiber: "",
    water: "",
    waist: "",
    weight: "",
    exercise: "",
    comments: ""
  },
  formatDailyData: [],
  currentDayOfWeek: 0,
  emailLoading: false,
  weightUnit: "pounds",
  heightUnit: "inch",
  weeklySubmission: [],
  currentData: [],
  searchValue:''
};

const dailyDataColumns = [
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: 'User Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render: text => <span>{moment.utc(text).format('ddd')}, {moment.utc(text).format('MMMM DD, YYYY')}</span>,
  },
  {
    title: 'Carbs',
    dataIndex: 'macroData.nutrition.carbs',
    key: 'carbohydrate',
  },
  {
    title: 'Fat',
    dataIndex: 'macroData.nutrition.totalFat',
    key: 'fat',
  },
  {
    title: 'Protein',
    key: 'protein',
    dataIndex: 'macroData.nutrition.protein',
  },
  {
    title: 'Total Kcal',
    dataIndex: 'totalKcal',
    key: 'kcal',
  },
  {
    title: 'Fiber',
    dataIndex: 'macroData.nutrition.fiber',
    key: 'fiber',
  },
  {
    title: 'Weight',
    dataIndex: 'macroData.nutrition.weight.weight',
    key: 'weight',
  },
];


class FitnessData extends React.Component {

  constructor() {
    super();
    this.state = {...initialState};
  }
  componentWillMount() {
    this.getSyncLogs()
  }

  getSyncLogs = async (date)=> {
    this.setState({dailyDataLoading: true})
    const fetchDate = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    axios.get(`${BASE_URL}/api/fitness-data/macro-logs/${fetchDate}`)
      .then(res => {
        if (res.data && res.data) {
          let currentData = [...res.data]
          this.setState({dailyFitnessData: res.data, currentData});
          if(this.state.searchValue){
            currentData = []
            res.data.forEach(selectedUser => {
              if(selectedUser.email.toLowerCase().includes(this.state.searchValue.toLowerCase())){
                currentData.push(selectedUser)
              }
            })
            this.setState({currentData});
          }
        }
        this.setState({dailyDataLoading: false});
      })
      .catch(err => {
        this.setState({dailyDataLoading: false,});
      });

  };
  
  
  searchUsersByEmail = (value) => {
    const currentData = []
    const {dailyFitnessData} = this.state;
    dailyFitnessData.forEach(selectedUser => {
      if(selectedUser.email.toLowerCase().includes(value.toLowerCase())){
        currentData.push(selectedUser)
      }
    })
    this.setState({currentData, searchValue: value})
  }
  
  onChangeCurrentDate = (date, dateString) => {
    
    this.setState({...initialState});
    this.setState({currentInputDate: date})
    this.getSyncLogs(date);
  }

  render() {
    const { currentInputDate,  dailyFitnessData, dailyDataLoading, currentData } = this.state;
    
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"/>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>

          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Sync Logs</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4" offset="3">
                        <FormGroup>
                          <DatePicker
                            style={{border: 0, borderColor: '#fff'}}
                            className="form-control form-control-alternative"
                            size="large"
                            format={'MMMM DD, YYYY'}
                            value={currentInputDate}
                            onChange={this.onChangeCurrentDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <Search
                          value={this.state.searchValue}
                          placeholder="Search By Email/Name"
                          allowClear
                          size="large"
                          style={{marginTop: 5}}
                          className="form-control form-control-alternative"
                          onChange={(e)=>{this.searchUsersByEmail(e.target.value)}}
                        />
                      </Col>
                    </Row>
  
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Table */}
          <Row style={{marginTop: 20}}>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Recent data</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table
                  loading={dailyDataLoading}
                  columns={dailyDataColumns}
                  dataSource={currentData ? currentData : []}
                  rowKey={record => record.date}
                  pagination={{pageSize: 7}}
                />
              </Card>
            </Col>
          </Row>

        </Container>
      </>
    );
  }
}

export default FitnessData;
