import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../actions/authActions";
import "./styles.css";
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import TopAlert from "../../components/Common/Alert.js"
import {Button} from 'antd';
import {StreamChat} from "stream-chat";
import queryString from 'query-string'
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: "",
      password: "",
      errors: {},
      unActivated: false,
      loadingButton: false
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({[e.target.id]: e.target.value});
  };

  onSubmit = async e => {
    e.preventDefault();
    const match = this.props.match
    if (!match || !match.params || !match.params.token) {
      toast.error('Invalid token')
      return
    }
    const newPassword = {
      confirm: this.state.confirm,
      password: this.state.password,
      email: match.params.email
    };
    if (!newPassword.password || newPassword.password.length < 8) {
      toast.error('Too Short')
      return
    } else if (newPassword.password !== newPassword.confirm) {
      toast.error('Password not matched')
      return
    }


    this.setState({loadingButton: true})
    try {
      const resp = await axios.post(`${BASE_URL}/api/users/verify-password-token`, match.params).catch((error) => {
        toast.error(error.response.data.error)
      })
      this.setState({loadingButton: false})
      if (resp && resp.status === 200) {
        const resp = await axios.post(`${BASE_URL}/api/users/reset-password`, newPassword).catch((error) => {
          toast.error(error.response.data.error)
        })
        toast.success('Password successfully changed')
        this.props.history.push("/auth/login");
      }
    } catch (e) {
      toast.error(e)
      this.setState({loadingButton: false})
    }


    // this.props.loginUser(newUser);

  };

  render() {
    const {state} = this.props.location;
    const {errors, loadingButton} = this.state;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-2">
                {/* <small>Sign in with</small> */}
                <img style={{width: '50%'}} alt="..." src={require("../../assets/img/brand/client-hub.png")}/>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Create New Password</small>
              </div>
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      placeholder="Password"
                      type={!this.state.showPassword ? "password" : 'text'}
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i
                          className={this.state.showPassword ? "fa fa-eye-slash" : 'fa fa-eye'}
                          onClick={() => this.setState({showPassword: !this.state.showPassword})}/>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="auth-error">{errors.email}</div>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.confirm}
                      error={errors.confirm}
                      id="confirm"
                      placeholder="Confirm Password"
                      type={!this.state.confirmShowPassword ? "password" : 'text'}
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i
                          className={this.state.confirmShowPassword ? "fa fa-eye-slash" : 'fa fa-eye'}
                          onClick={() => this.setState({confirmShowPassword: !this.state.confirmShowPassword})}/>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="auth-error">{errors.password}</div>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                </div>
                <div className="text-center">
                  <Button
                    style={{width: 160}}
                    loading={loadingButton}
                    type="submit"
                    className="my-2" type="primary" size="large"
                    onClick={this.onSubmit}>
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <ToastContainer position="top-center"/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (values, history) => dispatch(actions.loginUser(values, history))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Login));
