import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col
} from "reactstrap";
import UserHeader from "../../components/Headers/UserHeader.js";
import { Select, Input, Button, Radio, message, Modal } from 'antd';
import "./styles.css";
import moment from "moment";
import axios from "axios";
import { DatePicker } from 'react-rainbow-components';
import { BASE_URL } from "../../constants";
import ConnectModal from "../../components/Macros/ConnectModal";


const { Option } = Select;
const { confirm } = Modal;

class Profile extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      heightVal: "",
      WeightVal: "",
      targetWeightVal: "",
      dob: moment(),
      userAge: 0,
      selectedPlan: "",
      startDate: moment(new Date(), 'YYYY-MM-DD'),
      endDate: "",
      missingVal: false,
      lesserWeight: false,
      saveLoading: false,
      userData: {},
      updateProfileId: "",
      coachEmail: "",
      weightUnit: "pounds",
      heightUnit: "inch",
      currentWeight: 0,
      macrosConnectModal: false,
      macroStatus: "",
      macroEmail: "",
    }
  }
  
  async componentWillMount() {
    let { userData } = this.state;
    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
      this.setState({ userData })
    }
    await this.getUserProfile(userData._id);
    await this.getUserDailyData(userData);
  }
  
  async getUserProfile(userId) {
    this.setState({ loadingUsers: true, toggleAddModal: false })
    await axios.get(`${BASE_URL}/api/user-profile/${userId}`)
      .then(res => {
        if (res.data.userId === userId) {
          localStorage.setItem("userProfile", JSON.stringify(res.data));
          this.setState({
            heightVal: res.data.height ? res.data.height : 0,
            WeightVal: res.data.weight ? res.data.weight : 0,
            targetWeightVal: res.data.targetWeight ? res.data.targetWeight : 0,
            coachEmail: res.data.coach,
            updateProfileId: res.data._id,
            currentWeight: res.data.weight ? res.data.weight : 0,
            macroEmail: res.data.macroEmail ? res.data.macroEmail : "",
            macroStatus: res.data.macroStatus ? res.data.macroStatus : "not-connected",
            loadingUsers: false,
          })
          if (res.data.dob) this.onChangeDate(moment(res.data.dob, 'YYYY-MM-DD'), moment(res.data.dob, 'YYYY-MM-DD'));
          if (res.data.startDate) this.onChangeStartDate(moment(res.data.startDate, 'YYYY-MM-DD'), moment(res.data.startDate, 'YYYY-MM-DD'));
          if (res.data.actualPlanLength) this.onSelectDropdown(`${res.data.actualPlanLength}`);
        }
      })
      .catch(err => {
        this.setState({ loadingUsers: false, macroStatus: 'not-connected' });
      });
    
  };
  
  async getUserDailyData(user) {
    let { currentWeight } = this.state;
    this.setState({ dailyDataLoading: true })
    
    await axios.get(`${BASE_URL}/api/fitness-data/${user._id}`)
      .then(res => {
        if (res.data && res.data) {
          // this.formatDailyDataToWeekly(res.data)
          res.data.dailyData.sort((a, b) => new Date(a.date) - new Date(b.date))
          if (res.data && res.data.dailyData) {
            for (let i = res.data.dailyData.length; i > 0; i--) {
              if (res.data.dailyData[(i - 1)].weight) {
                currentWeight = res.data.dailyData[(i - 1)].weight;
                break;
              }
            }
          }
          this.setState({ dailyFitnessData: res.data, currentWeight });
        }
        this.setState({ dailyDataLoading: false });
      })
      .catch(err => {
        this.setState({ dailyDataLoading: false, });
      });
  };
  
  connectToMacros = (values) => {
    this.setState({ loadingUsers: true, toggleAddModal: false })
    axios.post(`${BASE_URL}/api/users/connectToMacros`, {
        fetch_actions: "{goal, macro, weight, meal}",
        user_email: values.email,
        password: values.password,
        local_email: this.state.userData.email,
        user_id: this.state.userData._id
      })
      .then(res => {
        if (res.data.status === 1) {
          message.success('Successfully connected');
          //this.getUserProfile(this.state.userData._id);
          this.setState({ macrosConnectModal: false, macroEmail: values.email, macroStatus: 'active'})
        } else if (res.data.status === 20 || res.data.status === 21 || res.data.status === 22) {
          message.error('invalid email or password');
        }
        this.setState({ loadingUsers: false })
        console.log('res', res)
      })
      .catch(err => {
        this.setState({ loadingUsers: false, });
      });
    
  };
  
  onChangeDate = (date) => {
    let userAge = moment().diff(moment(date), 'years');
    this.setState({ dob: date, userAge });
  }
  
  onChangeStartDate = (date) => {
    this.setState({ startDate: date });
    if (this.state.selectedPlan) {
      this.calculateEndDate(date, this.state.selectedPlan)
    }
  }
  
  calculateEndDate = (date, time) => {
    const { selectedPlan, startDate } = this.state;
    let calDate = moment.utc(date).add(parseInt(time) * 7, 'days').format('MMMM DD, YYYY')
    this.setState({ endDate: calDate });
  }
  
  onSelectDropdown = (value) => {
    this.setState({ selectedPlan: value });
    if (this.state.startDate) {
      this.calculateEndDate(this.state.startDate, value)
    }
  }
  
  saveProfileData() {
    const { dob, heightVal, heightUnit, WeightVal, targetWeightVal, weightUnit, selectedPlan, startDate, userData, coachEmail, updateProfileId } = this.state;
    if (dob && heightVal && WeightVal && targetWeightVal) {
      let weightPounds = weightUnit === 'pounds' ? WeightVal : (WeightVal * 2.2046).toFixed(2);
      let targetWeightPounds = weightUnit === 'pounds' ? targetWeightVal : (targetWeightVal * 2.2046).toFixed(2);
      if (weightPounds < 80 || targetWeightPounds < 80) {
        this.setState({ lesserWeight: true });
        return;
      } else if (weightPounds - targetWeightPounds > 30) {
        this.setState({ lesserWeight: true, requiredWeight: weightPounds });
        return;
      }
      
      this.setState({ missingVal: false, lesserWeight: false, saveLoading: true });
      const userProfile = {
        userId: userData._id,
        dob: moment(dob).format('YYYY-MM-DD'),
        height: heightUnit === 'inch' ? heightVal : (heightVal * 0.39370).toFixed(2),
        weight: weightUnit === 'pounds' ? WeightVal : (WeightVal * 2.2046).toFixed(2),
        targetWeight: weightUnit === 'pounds' ? targetWeightVal : (targetWeightVal * 2.2046).toFixed(2),
        // planLength: selectedPlan,
        // startDate: startDate,
        // coach: coachEmail
      };
      
      if (updateProfileId) {
        axios.put(`${BASE_URL}/api/user-profile/${updateProfileId}`, userProfile)
          .then(res => {
            localStorage.setItem("userProfile", JSON.stringify(res.data));
            this.setState({ saveLoading: false });
            message.info('updated data successfully');
          })
          .catch(err => {
            // dispatch({ type: GET_ERRORS, payload: err.response.data })
            this.setState({ saveLoading: false });
            message.error('some error occured');
          });
      } else {
        axios.post(`${BASE_URL}/api/user-profile/`, userProfile)
          .then(res => {
            localStorage.setItem("userProfile", JSON.stringify(res.data));
            this.setState({ saveLoading: false, updateProfileId: res.data._id });
            message.info('submit data successfully');
          })
          .catch(err => {
            // dispatch({ type: GET_ERRORS, payload: err.response.data })
            this.setState({ saveLoading: false });
            message.error('some error occured');
          });
      }
    } else {
      this.setState({ missingVal: true });
    }
  }
  
  onChangeWeightUnit = e => {
    let { WeightVal, targetWeightVal, weightUnit } = this.state;
    
    if (e.target.value === 'kg' && weightUnit === 'pounds') {
      WeightVal = (WeightVal / 2.2046).toFixed(2);
      targetWeightVal = (targetWeightVal / 2.2046).toFixed(2);
    } else if (e.target.value === 'pounds' && weightUnit === 'kg') {
      WeightVal = (WeightVal * 2.2046).toFixed(2);
      targetWeightVal = (targetWeightVal * 2.2046).toFixed(2);
    }
    this.setState({ weightUnit: e.target.value, WeightVal, targetWeightVal });
  };
  
  onChangeHeightUnit = e => {
    let { heightVal, heightUnit } = this.state;
    
    if (e.target.value === 'inch' && heightUnit === 'cm') {
      heightVal = (heightVal * 0.39370).toFixed(2);
    } else if (e.target.value === 'cm' && heightUnit === 'inch') {
      heightVal = (heightVal / 0.39370).toFixed(2);
    }
    this.setState({ heightUnit: e.target.value, heightVal });
  };
  
  onConnectToMacros = () => {
    this.setState({ macrosConnectModal: false })
  };
  
  onDisconnectFromMacros = () => {
    confirm({
      title: 'Are you sure?',
      content: 'Are you sure you want to disconnect?. You can reconnect at anytime again.',
      onOk: () => {
        this.setState({ loadingUsers: true, toggleAddModal: false })
        axios.post(`${BASE_URL}/api/users/disconnectToMacros`, {
            user_id: this.state.userData._id
          })
          .then(res => {
            message.success('Successfully disconnected');
            this.getUserProfile(this.state.userData._id);
            this.setState({ loadingUsers: false })
          })
          .catch(err => {
            this.setState({ loadingUsers: false, });
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  
  onUpdateSyncMacros = (status) => {
    confirm({
      title: 'Are you sure',
      content: `Are you sure you want to ${status === 'active' ? 'Enable' : 'Disable'} sync?`,
      onOk: () => {
        this.setState({ loadingUsers: true, toggleAddModal: false })
        axios.post(`${BASE_URL}/api/users/updateMacrosSync`, {
            user_id: this.state.userData._id,
            status: status
          })
          .then(res => {
            message.success('Successfully updated sync');
            this.getUserProfile(this.state.userData._id);
            this.setState({ loadingUsers: false })
          })
          .catch(err => {
            this.setState({ loadingUsers: false, });
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  
  render() {
    const {
      userAge, endDate, selectedPlan, userData, saveLoading,
      dob, heightVal, WeightVal, startDate, coachEmail, currentWeight, targetWeightVal
    } = this.state;
    
    return (
      <>
        <UserHeader/>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    {/* <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              value={userData.name}
                              type="text"
                              size="large"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              value={userData.email}
                              type="email"
                              size="large"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{ display: 'block' }}
                            >
                              Date of birth
                            </label>
                            {/* <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="23-05-2019"
                              type="text"
                            /> */}
                            <DatePicker
                              hideLabel={true}
                              style={{ border: 0, borderColor: '#fff' }}
                              className="form-control form-control-alternative"
                              format={'DD-MM-YYYY'}
                              value={dob}
                              onChange={this.onChangeDate}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Age
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              value={userAge}
                              type="text"
                              size="large"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Height
                            </label>
                            <div>
                              <Input
                                className="form-control-alternative"
                                id="input-first-name"
                                type="text"
                                value={heightVal}
                                onChange={({ target: { value } }) => {
                                  this.setState({ heightVal: value })
                                }}
                              />
                              <div>
                                <Radio.Group
                                  buttonStyle="solid"
                                  size="small"
                                  onChange={this.onChangeHeightUnit}
                                  value={this.state.heightUnit}
                                >
                                  <Radio.Button value="inch">inch</Radio.Button>
                                  <Radio.Button value="cm">cm</Radio.Button>
                                </Radio.Group>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Starting Weight
                            </label>
                            <div>
                              <Input
                                className="form-control-alternative"
                                id="input-last-name"
                                type="text"
                                value={WeightVal}
                                onChange={({ target: { value } }) => {
                                  this.setState({ WeightVal: value })
                                }}
                              />
                              <div>
                                <Radio.Group
                                  buttonStyle="solid"
                                  size="small"
                                  onChange={this.onChangeWeightUnit}
                                  value={this.state.weightUnit}
                                >
                                  <Radio.Button value="pounds">Pounds</Radio.Button>
                                  <Radio.Button value="kg">Kg</Radio.Button>
                                </Radio.Group>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Target Weight (initial max 30)
                            </label>
                            <div>
                              <Input
                                className="form-control-alternative"
                                id="input-last-name"
                                type="text"
                                value={targetWeightVal}
                                onChange={({ target: { value } }) => {
                                  this.setState({ targetWeightVal: value })
                                }}
                              />
                              <div>
                                <Radio.Group
                                  buttonStyle="solid"
                                  size="small"
                                  onChange={this.onChangeWeightUnit}
                                  value={this.state.weightUnit}
                                >
                                  <Radio.Button value="pounds">Pounds</Radio.Button>
                                  <Radio.Button value="kg">Kg</Radio.Button>
                                </Radio.Group>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    
                    </div>
                    <hr className="my-4"/>
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Plan Details
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6">
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                                style={{ display: 'block' }}
                              >
                                Plan Length
                              </label>
                              {/* <Input
                              className="form-control-alternative"
                              id="input-address"
                              placeholder="13 weeks"
                              type="text"
                            /> */}
                              <Select
                                size="large"
                                placeholder="Select a person"
                                onChange={this.onSelectDropdown}
                                value={selectedPlan}
                                disabled
                              >
                                <Option value="4">1 month</Option>
                                <Option value="13">13 weeks</Option>
                                <Option value="26">6 months</Option>
                                <Option value="52">1 Year</Option>
                              </Select>
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                style={{ display: 'block' }}
                              >
                                Start Date
                              </label>
                              <Input
                                style={{ height: 42, borderColor: '#d9d9d9' }}
                                id="input-last-name"
                                value={moment(startDate).format('MMMM DD, YYYY')}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                End Date
                              </label>
                              <Input
                                style={{ height: 42, borderColor: '#d9d9d9' }}
                                id="input-last-name"
                                value={endDate}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Col>
                        <Col lg="6" className="align-items-center justify-content-center">
                          {this.state.macroStatus === 'not-connected' &&
                          <Col lg="12">
                            <FormGroup className="mt-4 text-center">
                              <Button
                                style={{ width: 300, backgroundColor: '#DC5F00', color: '#fff' }}
                                loading={saveLoading}
                                size="large"
                                onClick={() => this.setState({ macrosConnectModal: true })}
                              >
                                Connect to My Macros+
                              </Button>
                            </FormGroup>
                          </Col>}
                          {/*{this.state.macroStatus === 'active' &&
                          <Col lg="12">
                            <FormGroup className="mt-4 text-center">
                              <label></label>
                              <Button
                                style={{ width: 300, backgroundColor: '#557153', color: '#fff', marginBottom: 30 }}
                                loading={saveLoading}
                                size="large"
                                onClick={() => {
                                  this.onUpdateSyncMacros('inactive')
                                }}
                              >
                                Disable My Macros+ Sync
                              </Button>
                            </FormGroup>
                          </Col>
                          }*/}
                          {this.state.macroStatus === 'inactive' &&
                          <Col lg="12">
                            <FormGroup className="mt-4 text-center">
                              <Button
                                style={{ width: 300, backgroundColor: '#DC5F00', color: '#fff', marginBottom: 30 }}
                                loading={saveLoading}
                                size="large"
                                onClick={() => {
                                  this.onUpdateSyncMacros('active')
                                }}
                              >
                                Enable My Macros+ Sync
                              </Button>
                            </FormGroup>
                          </Col>
                          }
                          {this.state.macroStatus === 'active' && this.state.macroStatus === 'active' &&
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-macro-email"
                              >
                                Macro Email
                              </label>
                              <Input
                                style={{ height: 42, borderColor: '#d9d9d9' }}
                                id="input-macro-email"
                                value={this.state.macroEmail}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          }
                          {(this.state.macroStatus === 'active' || this.state.macroStatus === 'inactive') &&
                          <Col lg="12">
                            <FormGroup className={"mt-6 text-center"}>
                              <Button
                                style={{ width: 300, backgroundColor: '#DC3535', color: '#fff' }}
                                loading={saveLoading}
                                size="large"
                                onClick={this.onDisconnectFromMacros}
                              >
                                Disconnect My Macros+
                              </Button>
                            </FormGroup>
                          </Col>
                          }
                        </Col>
                      </Row>
                    
                    </div>
                    <hr className="my-4"/>
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">Fitness Details</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Current Weight (Pounds)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              value={currentWeight}
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Current Loss (Pounds)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              value={WeightVal > currentWeight ? (WeightVal - currentWeight).toFixed(2) : 0}
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Coach
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-address"
                              value={coachEmail}
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4"/>
                  
                  </Form>
                </CardBody>
                <CardHeader className="bg-white border-0 text-center">
                  {this.state.missingVal ?
                    <span style={{ color: 'red' }}>some fields are missing,</span>
                    : null}
                  {this.state.lesserWeight ?
                    <span
                      style={{ color: 'red' }}>{`Target weight cannot be lower than ${this.state.requiredWeight - 30}. Please change.`}</span>
                    : null}
                  <Row className="align-items-center" style={{ textAlign: 'center' }}>
                    <Col className="align-items-center">
                      <Button
                        loading={saveLoading}
                        type="primary" size="large"
                        style={{ alignItems: 'center' }}
                        onClick={() => this.saveProfileData()}
                      >
                        Save Profile</Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConnectModal
          loadingUsers={this.state.loadingUsers}
          macrosConnectModal={this.state.macrosConnectModal}
          onCancel={() => this.setState({ macrosConnectModal: false })}
          connectToMacros={this.connectToMacros}
        />
      </>
    );
  }
}

export default Profile;
