import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "../auth/styles.css";
import moment from "moment"
import {Button, DatePicker, Select} from 'antd';
// reactstrap components
import {Card, CardBody, Col, Form, FormGroup} from "reactstrap";

const {Option} = Select;

class AddDuration extends React.Component {
  constructor() {
    super();
    this.state = {
      errorResponse: "",
      selectedPlan: "",
      startDate: moment(new Date(), 'MMMM DD, YYYY'),
      showLoading: false
    };
  }

  componentWillMount() {
    const {userData} = this.props;
    if (userData.profile && userData.profile.startDate) {
      this.setState({startDate: moment(userData.profile.startDate, 'YYYY-MM-DD')})
    }
  }

  onSubmit() {
    const {selectedPlan, startDate} = this.state
    const {userData, trainers} = this.props;
    if (selectedPlan && startDate) {
      this.props.onSubmit(startDate, selectedPlan)
    } else if (startDate) {
      this.props.onSubmit(startDate, userData.profile.planLength)
    } else {
      this.setState({errorResponse: "Select program duration from dropdown list"});
    }

  };

  onSelectDropdown = (value) => {
    this.setState({selectedPlan: value});
  }

  onChangeStartDate = (date, dateString) => {
    const {userData} = this.props;
    this.setState({startDate: date});
  }

  render() {
    const {selectedPlan, startDate, showLoading} = this.state
    const {userData, trainers} = this.props;

    return (
      <>
        <Col lg="12" md="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-2 py-lg-2">
              <Form role="form">
                {this.props.canChange && <DatePicker
                  style={{border: 0, borderColor: '#fff'}}
                  className="form-control form-control-alternative"
                  size="large"
                  format={'MMMM DD, YYYY'}
                  value={startDate}
                  onChange={this.onChangeStartDate}
                />}
                <FormGroup>
                  <Select
                    size="large"
                    placeholder="select program duration"
                    onChange={this.onSelectDropdown}
                  >
                    <Option value="4">1 month</Option>
                    <Option value="13">13 weeks</Option>
                    <Option value="26">6 months</Option>
                    <Option value="52">1 Year</Option>
                  </Select>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="mt-4"
                    color="primary"
                    loading={showLoading}
                    onClick={() => {
                      this.onSubmit()
                    }}>
                    Update</Button>
                </div>
                <div className="text-center">
                  <span style={{color: 'red'}}>{this.state.errorResponse}</span>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(AddDuration));
