import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {Container} from "reactstrap";
import {connect} from "react-redux";
import axios from "axios";
import {BASE_URL} from "../../constants";
import {Button, Modal} from "antd";


function CustomMessages(props) {
  const [messages, setMessages] = useState([]);
  const [messageId, setMessageId] = useState('');
  const [oldMessage, setOldMessage] = useState('');
  const [toggleAddModal, setToggleAddModal] = useState(false);
  useEffect(() => {
    axios.get(`${BASE_URL}/api/custom-messages/`)
      .then(async res => {
        setMessages(res.data)
      })
      .catch(err => {
      })
  }, [])

  const handleTodoAdd = event => {
    event.preventDefault();
    const message = event.target.title.value;
    event.target.reset();
    axios.post(`${BASE_URL}/api/custom-messages/`, {
      message
    })
      .then(async res => {
        setMessages([res.data, ...messages])
      })
      .catch(err => {
      })
  }
  const handleEdit = (event) => {
    event.preventDefault();
    const message = event.target.title.value;
    //event.target.reset();
    axios.put(`${BASE_URL}/api/custom-messages/`, {
      message,
      id: messageId
    })
      .then(async res => {
        setMessages(messages.map((_message) => {
          if (_message._id === messageId) {
            _message.message = message
          }
          return _message
        }))
        setToggleAddModal(false)
      })
      .catch(err => {
        alert(err)
        setToggleAddModal(false)
      })
  }
  const deleteMessage = (id) => {
    axios.delete(`${BASE_URL}/api/custom-messages/${id}`)
      .then(async res => {
        setMessages(messages.filter((message) => message._id !== id))
      })
      .catch(err => {
      })
  }

  return (
    <>
      {/* <Header /> */}
      {toggleAddModal ?
        <Modal
          title={`Edit Message`}
          visible={toggleAddModal}
          closable={true}
          footer={null}
          // confirmLoading={confirmLoading}
          onCancel={() => setToggleAddModal(false)}
        >
          <form onSubmit={handleEdit} className="add-container" style={{flexDirection: 'column'}}>
            <textarea
              style={{width:'90%', height: 150}}
              defaultValue={oldMessage} type='text' placeholder='Enter message' name='title' id='title'/>
            <Button htmlType={'submit'} type="primary" size="medium" style={{marginTop: 30}}>Edit Message</Button>
          </form>
        </Modal>
        : null}
      <div className="header bg-gradient-info pb-4 pt-5">
        <Container fluid>
          <div className="header-body"/>
        </Container>
      </div>
      <div style={{padding: 30}}>
        <form onSubmit={handleTodoAdd} className="add-container">
          <textarea type='text' placeholder='Enter message' name='title' id='title'/>
          <Button htmlType={'submit'} type="primary" size="medium">Add Message</Button>
        </form>
        <ul className='mContainer'>
          {messages.map(message => (
            <li key={message._id}>
              <span className={'message'}>{message.message}</span>
              <div className='todo-btns'>
                <Button
                  type="primary"
                  size="medium"
                  style={{marginRight: 5}}
                  onClick={() => {
                    setMessageId(message._id)
                    setToggleAddModal(true)
                    setOldMessage(message.message)
                  }}>Edit</Button>
                <Button type="primary" size="medium" onClick={() => deleteMessage(message._id)}>Delete</Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user
});
export default connect(mapStateToProps, null,)(withRouter(CustomMessages));
