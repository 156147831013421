
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions/authActions";
import "./styles.css";
import axios from "axios";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { message, Button } from 'antd';
import {BASE_URL} from "../../constants";

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
      loadingButton: false
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // const { email, password } = this.state
    this.setState({ loadingButton: true })
    const userData = {
      email: this.state.email,
    }

    axios.post(`${BASE_URL}/api/users/forgot-password`, userData)
      .then(res => {
        if (res.data.status) {
          message.info('Email sent successfully');
          this.setState({ loadingButton: false })
          //this.props.history.push("/auth/login");
        }
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        this.setState({ errors: err.response.data, loadingButton: false });
      });
  };

  render() {
    const { errors, loadingButton } = this.state;
    return (
      <>
        {/* {state && state.fromRegister ?
          <TopAlert
            alertType="info"
            alertMessage="Successful Signup"
            alertDesc="your details are send to the admin for activation"
          />
          : null} */}
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-2">
                {/* <small>Sign in with</small> */}
                <img style={{ width: '50%' }} alt="..." src={require("../../assets/img/brand/client-hub.png")} />
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Enter your verified email and password would be send to your email</small>
              </div>
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onChange}
                      value={this.state.email}
                      error={errors.email}
                      id="email"
                      placeholder="Email" type="email" />
                  </InputGroup>
                  <div className="auth-error">{errors.email}</div>
                </FormGroup>
                <div className="text-center">
                  <Button loading={loadingButton} type="submit" className="my-4" type="primary" size="large"
                    onClick={this.onSubmit}>
                    Send Email
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-right" xs="12">
              <a
                className="text-light"
                href="/auth/login"
                style={{ fontSize: 18 }}
              >
                <small>Already have an account?</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (values, history) => dispatch(actions.loginUser(values, history))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ForgotPassword));
