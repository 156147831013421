import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

class UserHeader extends React.Component {
  render() {
    let userData = {};
    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
    }
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "400px",
            backgroundImage:
              "url(" + require("../../assets/img/brand/PLT-Logo.png") + ")",
            backgroundSize: "center",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: "center"
          }}
        >
          {/* Mask */}
          <span className="mask opacity-8" style={{ background: "#d8b66d" }} />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">Hello {userData ? userData.name : ""}</h1>
                <p className="text-white mt-0 mb-5">
                  This is your profile page. You can see the progress you've made.
                </p>
                {/* <Button
                  color="info"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Edit profile
                </Button> */}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
