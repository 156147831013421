import React from "react";
import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import {Button, message, Modal} from 'antd';
import "./styles.css";
import "./galleryStyles.scss";
import moment from "moment";
import axios from "axios";
import S3 from 'react-aws-s3';
import ImageGallery from './../../components/Gallery';
import {connect} from "react-redux";
import {updateProfile} from "../../actions/authActions";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {BASE_URL} from "../../constants"; // Import css

class Gallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imageData: props.gallery || [],
      saveLoading: false,
      galleryLoading: true,
      userData: {},
      updateProfileId: "",
      toggleImageModal: false,
      selectedFile: null,
      disableBtn: false,
      currentIndex: null
    }
    this.closeModal = this.closeModal.bind(this);
    this.findNext = this.findNext.bind(this);
    this.findPrev = this.findPrev.bind(this);
    this.renderImageContent = this.renderImageContent.bind(this);
  }

  // ======================================= gallery part functions ================================
  renderImageContent(src, index) {
    return (
      <div onClick={(e) => this.openModal(e, index)} key={index}>
        <img src={src} key={src}/>
      </div>
    )
  }

  openModal(e, index) {
    this.setState({currentIndex: index});
  }

  closeModal(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState({currentIndex: null});
  }

  findPrev(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1
    }));
  }

  findNext(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1
    }));
  }

  // ======================================= ---------------------- ================================
  componentDidMount() {
    this.loadData()
  }
  loadData = async ()=>{
    let {userData} = this.state;
    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
      this.setState({userData})
    }
    if (userData.role === 'user')
      await this.getUserProfile(userData._id);
    else
      this.setState({galleryLoading: false})
    this.setState({userData});
  }

  async getUserProfile(userId) {
    await axios.get(`${BASE_URL}/api/user-profile/${userId}`)
      .then(res => {
        if (res.data.userId === userId) {
          localStorage.setItem("userProfile", JSON.stringify(res.data));
          this.props.updateProfile()
          this.setState({
            imageData: res.data.gallery,
            galleryLoading: false,
          })
        }
      })
      .catch(err => {
        message.error("Error while fetching user Info")
        this.setState({
          galleryLoading: false,
        })
      });

  };

  showModal(role) {
    this.setState({toggleImageModal: true});
  };

  hideModal = () => {
    this.setState({toggleImageModal: false});
  };

  // On file select (from the pop up)
  onFileChange = event => {

    // Update the state
    this.setState({selectedFile: event.target.files[0]});

  };

  onChangeProfilePicture = (image) => {
    this.setState({saveLoading: true, disableBtn: true});
    axios.put(`${BASE_URL}/api/user-profile/${this.state.userData._id}`, {profilePicture: image})
      .then(res => {
        localStorage.setItem("userProfile", JSON.stringify(res.data));
        this.setState({saveLoading: false, disableBtn: false});
        message.success("Profile picture changed successfully");
        this.getUserProfile(this.state.userData._id);
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        message.error('Sorry! some error occured while uploading Image');
      });
  }

  onDeletePicture = (image) => {
    this.setState({saveLoading: true, disableBtn: true});
    axios.put(`${BASE_URL}/api/user-profile/${this.state.userData._id}`, {imagePath: image, type: 'delete'})
      .then(res => {
        localStorage.setItem("userProfile", JSON.stringify(res.data));
        this.setState({saveLoading: false, disableBtn: false});
        message.success("Image deleted successfully");
        this.getUserProfile(this.state.userData._id);
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        message.error('Sorry! some error occured while uploading Image');
      });
  }

  // On file upload (click the upload button)
  onFileUpload = async () => {
    if (this.state.selectedFile) {
      this.setState({saveLoading: true, disableBtn: true});
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      var typeofFile = this.state.selectedFile.type.split('/').pop().toLowerCase();
      if (typeofFile != "jpeg" && typeofFile != "jpg" && typeofFile != "png" && typeofFile != "bmp" && typeofFile != "gif") {
        alert('Please select a valid image file');
        this.setState({saveLoading: false, disableBtn: false});
        return false;
      }

      // Request made to the s3bcuket api
      const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        dirName: process.env.REACT_APP_DIR_NAME + "/" + this.state.userData._id,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      };
      const imgData = {
        date: moment.now(),
        imagePath: 'https://fitness-portal.s3.us-east-2.amazonaws.com/userGallery/' + this.state.userData._id + '/' + this.state.selectedFile.name,
      }
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(this.state.selectedFile, this.state.selectedFile.name).then(res => {
        if (res.status == 204 || res.status == 200) {
          axios.put(`${BASE_URL}/api/user-profile/${this.state.userData._id}`, imgData)
            .then(res => {
              localStorage.setItem("userProfile", JSON.stringify(res.data));
              this.setState({saveLoading: false, disableBtn: false});
              this.hideModal();
              message.success("Image uploaded successfully");
              this.getUserProfile(this.state.userData._id);
            })
            .catch(err => {
              // dispatch({ type: GET_ERRORS, payload: err.response.data })
              this.setState({saveLoading: false, disableBtn: false});
              this.hideModal();
              message.error('Sorry! some error occured while uploading Image');
            });
        } else {
          this.setState({saveLoading: false, disableBtn: false});
          this.hideModal();
          message.error("Sorry! something went wrong")
        }
      })
        .catch(err => {
          this.setState({saveLoading: false, disableBtn: false});
          this.hideModal();
          message.error("Sorry! something went wrong")
        });
    } else {
      message.error('Kindly Choose File before Pressing the Upload button');
    }
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>

          <p>File Name: {this.state.selectedFile.name}</p>

          <p>File Type: {this.state.selectedFile.type}</p>

          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate ? this.state.selectedFile.lastModifiedDate.toDateString() : ''}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br/>
          <h4>Choose File before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  render() {
    const {imageData, toggleImageModal, userData} = this.state;
    const isUser = userData.role === 'user'
    let showCarousel = false;
    if (imageData && imageData.length > 0) {
      showCarousel = true;
    }
    const images = imageData.map((image) => {
      const rec = {
        src: image,
        thumbnail: image
      }
      if (image.startsWith('http')) {
        const url = new URL(image);
        const date = url.searchParams.get("date");
        if (date)
          rec['tags'] = [{value: moment(date, "x").format('dddd, DD-MM-YYYY'), title: date}]
      }
      if (isUser) {

      }
      return rec
    })
    return (
      <div style={{minHeight: '85vh'}}>
        {/* <Header /> */}
        {isUser && <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"/>
          </Container>
        </div>}
        {/* Page content */}
        <Container className={isUser ? "mt--9" : "mt-5"}>
          <Row style={{marginBottom: 20}}>
            <Col className="mb-5 mb-xl-0 pl-0 pr-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">User Images</h3>
                    </div>
                    {isUser && <div className="col text-right">
                      <Button type="primary" size="small" onClick={() => this.showModal('image')}>
                        Upload Image</Button>
                    </div>}
                  </Row>
                </CardHeader>
                <Row>
                  <Col className="mb-5 mb-xl-0 pb-2" md="12" xl="12">
                    <div className="App">
                      <div style={{textAlign: "center"}}>
                        <div style={{
                          padding: "0 20px"
                        }}>
                          {this.state.galleryLoading ?
                            <img src="https://i.stack.imgur.com/h6viz.gif"/> :
                            showCarousel ?
                              <div className="gallery-container">
                                <ImageGallery
                                  enableImageSelection={false}
                                  style={{width: 500}}
                                  images={images}
                                  onChangeProfilePicture={(image) => this.onChangeProfilePicture(image)}
                                  onDeletePicture={(image) => this.onDeletePicture(image)}
                                  tagStyle={{
                                    display: 'inline',
                                    padding: '0.2em 0.6em 0.3em',
                                    fontSize: '60%',
                                    fontWeight: 600,
                                    lineHeight: 1,
                                    color: 'yellow',
                                    background: 'rgba(0, 0, 0, 0.65)',
                                    textAlign: 'center',
                                    whiteSpace: 'pre-wrap',
                                    verticalAlign: 'baseline',
                                    borderRadius: '0.25em'
                                  }}
                                />
                              </div> : <div class="ant-empty ant-empty-normal">
                                <div class="ant-empty-image">
                                  <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                    <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                                      <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                      <g fill-rule="nonzero" stroke="#D9D9D9">
                                        <path
                                          d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                        <path
                                          d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                          fill="#FAFAFA"></path>
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                                <p class="ant-empty-description">No Data</p></div>
                          }
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {toggleImageModal ?
                  <Modal
                    title={`Add Image`}
                    visible={toggleImageModal}
                    closable={true}
                    footer={null}
                    // confirmLoading={confirmLoading}
                    onCancel={this.hideModal}
                  >
                    <>
                      <div>
                        <input type="file" onChange={this.onFileChange} accept="image/*"/>
                        <Button disabled={this.state.disableBtn} type="primary" size="small"
                                onClick={this.onFileUpload}>
                          Upload!
                        </Button>
                      </div>
                      {this.fileData()}
                    </>
                  </Modal>
                  : null}

              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.saveLoading ? <div className="loaderrr">
          <img src="https://i.stack.imgur.com/h6viz.gif"/>
        </div> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: () => dispatch(updateProfile())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
