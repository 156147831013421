import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {Container} from "reactstrap";
import {Channel, ChannelHeader, ChannelList, Chat, MessageInput, MessageList} from "stream-chat-react";
import {StreamChat} from "stream-chat";
import {connect} from "react-redux";
import {Button, Modal, message} from "antd";
import axios from "axios";
import ExportChannel from "../adminViews/ExportChannel";
import {BASE_URL, GETSTREAM_KEY} from "../../constants";

const client = StreamChat.getInstance(GETSTREAM_KEY);


function TrainerUsers(props) {
  const [toggleExportModal, setToggleExportModal] = useState(false)
  const [ready, setReady] = useState(false)
  const ref = useRef();
  useEffect(() => {
    connectUserChat()
    // searchMessages()
  }, [])
  const connectUserChat = async () => {
    const user = await client.connectUser(
      {
        id: props.user.token.id,
        name: props.user.token.name,
      },
      props.user.user.chatToken,
    );
  }
  const filters = {members: {$in: [props.user.token.id]}, frozen: {$eq: false}}
  useEffect( ()=>{
    cleanChannels()
  },[])

  const cleanChannels = async ()=>{
    const channels = await client.queryChannels(filters);
    await Promise.all(channels.map(async (channel)=>{
      if(channel.id.startsWith('!members-')){
        const response = await channel.update({frozen: true});
      }
    }))
    setReady(true)
  }
  const isAdmin = props.user.user.user.role === 'admin';

  const exportMessages = () => {
    axios.post(`${BASE_URL}/api/users/exportdata`, {})
      .then(async res => {
        this.props.closeModal();
      })
      .catch(err => {
        this.setState({errorResponse: "Error assigning coach. Try again"});
      });
  }
  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const getChannelStatus = async (task_id) => {
    try {
      await sleep(2 * 1000)
      const res = await axios.post(`${BASE_URL}/api/users/task_status`, {task_id})
      if (res.data.status === 'completed') {
        return res.data.result.url
      } else
        return null
    } catch (e) {
    }
  }
  const downloadFile = (url) => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'export.json');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  const downloadExportData = async (taskId) => {
    message.loading({
      content: 'Exporting channel...',
      duration: 0,
      key: `channel-export`,
    })
    let url = null
    do {
      url = await getChannelStatus(taskId)
    } while (!url)
    message.destroy('channel-export');
    message.success('Exported Channel', 5)
    downloadFile(url)
  }

  return (
    <>
      {/* <Header /> */}
      <div className="header bg-gradient-info pb-4 pt-5">
        <Container fluid>
          <div className="header-body"/>
        </Container>
      </div>
      <div>
        {ready && <Chat
          client={client}>
          <ChannelList
            showChannelSearch={props.user.user.user.role === 'admin'}
            onChannelVisible={(ch) => {
            }}
            additionalChannelSearchProps={{
              searchForChannels: true
            }}
            sendChannelsToList
            filters={props.user.user.user.role === 'admin' ? {frozen: {$eq: false}} :
              props.user.user.user.role === 'user' ? {cid: {$eq: `messaging:${props.user.token.id}`}} : filters}/>
          <Channel
            ref={ref}
            style={{display: 'flex', flexDirection: 'row'}}>
            <ChannelHeader>
            </ChannelHeader>
            {isAdmin && <div style={{marginTop: -50, textAlign: 'right', marginRight: 20}}>
              <Button
                onClick={() => {
                  setToggleExportModal(true)
                }}
              >{'Export Chat'}</Button>
            </div>}
            <MessageList
              messageActions={isAdmin ? [] : ['pin', 'quote', 'react', 'reply']}
            />
            {!isAdmin &&
            <MessageInput/>
            }
          </Channel>
        </Chat>}
      </div>
      {toggleExportModal ?
        <Modal
          title="Export Channel"
          visible={toggleExportModal}
          closable={true}
          footer={null}
          // confirmLoading={confirmLoading}
          onCancel={() => setToggleExportModal(false)}
        >
          <ExportChannel
            closeModal={(task_id) => {
              setToggleExportModal(false)
              downloadExportData(task_id)
            }}
          />
        </Modal>
        : null}
    </>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user
});
export default connect(mapStateToProps, null,)(withRouter(TrainerUsers));
